import { Component, Input } from '@angular/core';
import { RobotIssue } from '../robot-issue.types';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { DefaultPipe } from '../../pipes/default.pipe';
import { LengthPipe } from '../../pipes/length.pipe';

@Component({
  selector: 'app-robot-issue-panel',
  styleUrls: ['./robot-issues-panel.component.sass'],
  templateUrl: './robot-issues-panel.component.html',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DefaultPipe,
    LengthPipe,
  ],
})
export class RobotIssuesPanelComponent {
  @Input() issues!: RobotIssue[];
  displayedColumns = ['id', 'severity', 'name'];
}
