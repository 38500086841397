@if (selectedItem) {
  <div class="selected-item-column">
    @if (previewImage != undefined) {
      <img [src]="previewImage" style="width: 100%" />
    }
    @if (previewVideo != undefined) {
      <video
        controls
        [src]="previewVideo"
        type="video/mp4"
        class="video-preview"
      >
        Your browser does not support the video tag.
      </video>
    }
    <app-metadata class="metadata" [item]="metadataItem" />
    @if (snippetFrames.length > 0) {
      <div class="side-bar-img-grid">
        @for (item of snippetFrames; track item; let i = $index) {
          <app-grid-img-item
            [class.selected-img-item]="item.id === metadataItem?.id"
            [item]="item"
            (click)="selectItem.emit(item)"
          />
        }
      </div>
    }
  </div>
}
