import { Subscription, Subject } from 'rxjs';
import { Component, Input, AfterViewInit } from '@angular/core';
import * as nipple from 'nipplejs';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';
import { visiblePageTimer } from '../../../../utils/page-visibility';

@Component({
  selector: 'app-joystick',
  templateUrl: './joystick.component.html',
  styleUrls: ['./joystick.component.sass'],
  standalone: true,
})
export class JoystickComponent implements AfterViewInit {
  @Input()
  set robotCommunication(robotCommunication: RobotCommunication | undefined) {
    this.updateRobotCommunication(robotCommunication);
    if (robotCommunication) {
      this.pollingIntervalSubscription = visiblePageTimer(0, 25).subscribe(
        () => {
          this.PollJoystick();
        },
      );
    } else if (this.pollingIntervalSubscription) {
      this.pollingIntervalSubscription.unsubscribe();
      this.pollingIntervalSubscription = undefined;
    }
  }

  get robotCommunication(): RobotCommunication | undefined {
    return this._robotCommunication;
  }

  private _robotCommunication?: RobotCommunication;
  private readonly unsubscribeRobot$ = new Subject<void>();

  private pollingIntervalSubscription?: Subscription;

  private readonly maxSpeed = 1.0;
  private readonly maxTurnRate = 1.0;

  private currentSpeed = 0.0;
  private currentTurnRate = 0.0;

  private joystickFieldSize = 100;

  ngAfterViewInit() {
    const element = document.getElementById('zone_joystick');
    if (element === null) {
      console.error('No joystick element');
      return;
    }

    const options = {
      zone: element,
      size: 2 * this.joystickFieldSize,
      position: { left: '50%', top: '50%' },
      color: 'red',
    };

    const manager = nipple.create(options);

    manager.on('added', (evt: any, nipple: any) => {
      this.currentSpeed = 0;
      this.currentTurnRate = 0;
      nipple
        .on('move', (moveEvt: any, data: nipple.JoystickOutputData) => {
          const scalingFactor =
            (1.0 / (this.joystickFieldSize * this.joystickFieldSize)) *
            (data.distance * data.distance);
          this.currentSpeed =
            Math.sin(data.angle.radian) * this.maxSpeed * scalingFactor;
          this.currentTurnRate =
            -Math.cos(data.angle.radian) * this.maxTurnRate * scalingFactor;
        })
        .on('end', (endEvt: any, data: nipple.JoystickOutputData) => {
          this.currentSpeed = 0;
          this.currentTurnRate = 0;
          this.PollJoystick();
        });
    });
    manager.on('removed', (evt: any, nipple: any) => {
      nipple.off('start move end dir plain');
      this.currentSpeed = 0;
      this.currentTurnRate = 0;
      this.PollJoystick();
    });
  }

  private PollJoystick() {
    if (!this.robotCommunication) {
      return;
    }
    this.robotCommunication.controlManually({
      speed: this.currentSpeed,
      turnRate: this.currentTurnRate,
    });
  }

  private updateRobotCommunication(robotCommunication?: RobotCommunication) {
    if (robotCommunication === this.robotCommunication) {
      return;
    }
    this.unsubscribeRobot$.next(undefined);
    this._robotCommunication = robotCommunication;
  }
}
