<div class="img-grid-scroll-container">
  <div class="img-grid">
    @for (item of items; track item; let i = $index) {
      <app-grid-img-item
        (click)="itemClick.emit(item)"
        (pickClicked)="pickItem.emit(item)"
        [isSelected]="item.id === selectedItemId"
        [item]="item"
      />
    }
  </div>
  @if (canFetchMore) {
    <div class="next-page-button" (click)="nextPage.emit()">Get more</div>
  }
</div>
