import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ShortcutDialogComponent } from './shortcut-dialog.component';
import { AuthService } from '../auth.service';
import { MatToolbar } from '@angular/material/toolbar';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ClockingComponent } from '../clocking/clocking.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
  standalone: true,
  imports: [
    MatToolbar,
    MatIconButton,
    MatIcon,
    MatMenu,
    MatMenuTrigger,
    ClockingComponent,
    AsyncPipe,
  ],
})
export class ToolbarComponent {
  constructor(
    public auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  openHomePage() {
    this.router.navigate(['']);
  }

  openProfile() {
    this.router.navigate(['/login']);
  }

  openShortcuts() {
    this.dialog.open(ShortcutDialogComponent);
  }
}
