import {
  Component,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { BackendService } from '../backend.service';

@Component({
  selector: 'map',
  template: '',
  styleUrls: ['./map.component.sass'],
  standalone: true,
})
export class MapComponent implements AfterViewInit {
  constructor(
    private elRef: ElementRef,
    private backendService: BackendService,
  ) {}

  map!: google.maps.Map;

  @Input() mapOptions?: google.maps.MapOptions;

  // Fired when google.maps.Map was created.
  @Output() mapReady: EventEmitter<google.maps.Map> =
    new EventEmitter<google.maps.Map>();

  ngAfterViewInit() {
    if (!this.mapOptions) {
      throw new Error('mapOptions is a required property');
    }
    if (!('center' in this.mapOptions) || !('zoom' in this.mapOptions)) {
      throw new Error(
        'mapOptions property needs to set at least "center" and "zoom"',
      );
    }
    this.map = new google.maps.Map(this.elRef.nativeElement, this.mapOptions);
    // Needs setTimeout, because Angular change detection chokes if things are changed
    // inside the AfterViewInit lifecycle hook.
    setTimeout(() => {
      this.backendService
        .post('/event-logging/map-request', {
          path: window.location.pathname,
        })
        .toPromise()
        .catch((e) => {
          console.error('Map usage report failed', e);
        });
      this.mapReady.emit(this.map);
    });
  }
}
