import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  toGoogleLatLng,
  boundingPolygonFromBounds,
} from '../../utils/geo-tools';
import { visiblePageTimer } from '../../utils/page-visibility';
import { BackendService } from '../core/backend.service';
import { MapService } from '../core/map.service';
import { ColoredPolyline, Geometry } from './mapping.component';
import {
  ElementType,
  GeoPoint,
  LineStringGeometry,
  MapElement,
  MapElementTransientData,
  OperationRegion,
  OperationRegionProperties,
  UpsertMapElementTransientData,
} from '@cartken/map-types';
import { LatLngBounds } from 'spherical-geometry-js';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';

const PAGE_UPDATE_INTERVAL_MILLIS = 5000;
const SNACK_BAR_INFO_DURATION_MILLIS = 3000;

function getOperationId(mapElement: OperationRegion): string {
  return (
    (mapElement.properties as OperationRegionProperties)?.operationId ?? ''
  );
}

function compareByOperationId(a: OperationRegion, b: OperationRegion): number {
  return getOperationId(a).localeCompare(getOperationId(b));
}

@Component({
  selector: 'mapping-automation',
  templateUrl: './mapping-automation.component.html',
  styleUrls: ['./mapping-automation.component.sass'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatButton,
    MatSlider,
    MatSliderThumb,
  ],
})
export class MappingAutomationComponent implements OnInit, OnDestroy {
  operationRegions: OperationRegion[] = [];
  selectedOperationId?: string = undefined;
  selectedOperation?: OperationRegion = undefined;
  mapElements: MapElement[] = [];
  transientData = new Map<number, MapElementTransientData>();

  numDesiredAcquisitionsOnUpsert: number = 1;

  @Output()
  onShowGeometry = new EventEmitter<Geometry>();

  private destroy$ = new Subject();

  constructor(
    private mapService: MapService,
    private backendService: BackendService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(undefined);
  }

  ngOnInit(): void {
    visiblePageTimer(0, PAGE_UPDATE_INTERVAL_MILLIS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.reloadData().catch((e) => console.warn(e));
      });
  }

  async loadRegionData(): Promise<void> {
    this.operationRegions = await this.mapService.loadOperationRegions();
    this.operationRegions.sort(compareByOperationId);
  }

  async loadMapData(): Promise<void> {
    const bounds = this.computeBounds();
    if (bounds === undefined) {
      this.mapElements = [];
      return;
    }
    this.mapElements = (
      await this.mapService.loadMapElements(
        boundingPolygonFromBounds(
          new LatLngBounds(bounds.getSouthWest(), bounds.getNorthEast()),
        ),
      )
    ).filter((element) => !element.deleted);
  }

  getRobotEdgeIds(): number[] {
    return this.mapElements
      .filter((element) => element.elementType == ElementType.ROBOT_EDGE)
      .map((element) => element.id);
  }

  async loadTransientData(): Promise<void> {
    const ids = this.getRobotEdgeIds().join();
    if (!ids) {
      this.transientData.clear();
      return;
    }
    const transientData = await firstValueFrom(
      this.backendService.get<MapElementTransientData[]>(
        `/map/transient?ids=${ids}`,
      ),
    );
    this.transientData = new Map<number, MapElementTransientData>(
      transientData.map((data) => [data.id, data]),
    );
  }

  async reloadData(): Promise<void> {
    await this.loadRegionData();
    await this.loadTransientData();
    this.updateGeometry();
  }

  computeBounds(): google.maps.LatLngBounds | undefined {
    if (this.selectedOperation === undefined) {
      return;
    }
    const { geometry } = this.selectedOperation;
    const bounds = new google.maps.LatLngBounds();
    geometry.coordinates.forEach((polygon: GeoPoint[]) => {
      polygon.forEach((point: GeoPoint) => {
        const latLon = toGoogleLatLng(point);
        if (latLon !== undefined) {
          bounds.extend(latLon);
        }
      });
    });
    return bounds;
  }

  getColorForElementWithId(id: number): string {
    const data = this.transientData.get(id)?.mappingDataAcquisitionInfo;
    if (data?.numRunningAcquisitions ?? 0 > 0) {
      return '#3da';
    }
    if (
      (data?.numDesiredAcquisitions ?? 0 > 0) ||
      (data?.acquiringRobotIds ?? []).length > 0
    ) {
      return '#e42';
    }
    return 'darkgray';
  }

  computeMapVisualization(): ColoredPolyline[] {
    return this.mapElements
      .filter((element) => element.elementType == ElementType.ROBOT_EDGE)
      .map((element) => ({
        polyline: element.geometry,
        color: this.getColorForElementWithId(element.id),
      }));
  }

  updateGeometry(): void {
    const bounds = this.computeBounds();
    if (bounds === undefined) {
      return;
    }
    const polylines = this.computeMapVisualization();
    this.onShowGeometry.emit({
      polylines: polylines,
      bounds: bounds,
    });
  }

  async setOperationRegion(): Promise<void> {
    for (const operation of this.operationRegions) {
      if (operation.properties.operationId == this.selectedOperationId) {
        this.selectedOperation = operation;
        this.mapElements = [];
        await this.loadMapData();
        await this.loadTransientData();
        this.updateGeometry();
        return;
      }
    }
  }

  async upsertAcquisitions(): Promise<void> {
    const ids = this.getRobotEdgeIds();
    if (!ids) {
      return;
    }
    await this.backendService
      .post<UpsertMapElementTransientData>(
        '/map/transient/upsert',
        {
          upsertedIds: ids,
          setNumDesiredAcquisitions: this.numDesiredAcquisitionsOnUpsert,
        },
        (httpError) => {
          this.snackBar.open('Failed to set desired acquisitions', '', {
            verticalPosition: 'top',
            duration: SNACK_BAR_INFO_DURATION_MILLIS,
          });
          return EMPTY;
        },
      )
      .toPromise();
  }
}
