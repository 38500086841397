<div class="compartment-container">
  <h1>Compartments</h1>
  <div class="compartments-with-spinner">
    <div class="compartments">
      @for (compartment of compartments$ | async; track compartment.id) {
        <div class="compartment">
          {{ compartment.id }}

          <mat-icon matTooltip="{{ compartment.state }}">{{
            compartmentStateToIconName(compartment)
          }}</mat-icon>

          @if (!this.waitingForUpdate) {
            <mat-slide-toggle
              [ngModel]="isLocked(compartment)"
              (change)="setRobotCompartmentRequest(compartment, $event.checked)"
            />
          }
        </div>
      }
    </div>

    @if (this.waitingForUpdate) {
      <mat-spinner [diameter]="70" />
    }
  </div>
</div>
