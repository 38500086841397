@if (dataViewerService.items$ | async; as items) {
  <app-data-grid
    style="display: contents"
    [items]="items"
    [selectedItemId]="selectedItemId$ | async"
    (itemClick)="selectItem($event)"
    (pickItem)="pickItem($event)"
    (nextPage)="nextPage()"
    [canFetchMore]="dataViewerService.canFetchMore$ | async"
  />
}
