@if (trackingCode$ | async) {
  <div onselectstart="return false" id="mainBody" class="centered-element">
    @if (showMap) {
      <div class="map">
        <map
          class="inner-map"
          [mapOptions]="mapOptions"
          (mapReady)="onMap($event)"
        ></map>
        @if ((maxDropoffRefinementDistance$ | async) !== 0) {
          <button
            mat-mini-fab
            (click)="onRefinementClick()"
            class="refine-map-button"
            color="basic"
            matTooltip="Refine meeting point"
          >
            <mat-icon>edit_location_alt</mat-icon>
          </button>
        }
      </div>
    }

    @if (showOpenLidVideo) {
      <open-close-animation-dialog
        #videoContainer
        class="map"
        fallbackMsg="Please open the robot lid with you hand"
        [videoPath]="openAnimationSrc"
        [initialFrameTime]="2.4"
      />
    }

    @if (showCloseLidVideo) {
      <open-close-animation-dialog
        fallbackMsg="Please close the robot lid with you hand"
        [videoPath]="closeAnimationSrc"
        [initialFrameTime]="1.35"
        #videoContainer
        class="map"
      />
    }

    <div class="handover-tacker-container">
      <div class="handover-tacker">
        <div class="handover-info">
          @for (handoversInfo of handoversInfos$ | async; track $index) {
            @switch (handoversInfo.type) {
              @case ('pickup') {
                <mat-icon
                  [class]="'round-icon'"
                  [class.current-handover-icon]="handoversInfo.isCurrent"
                  [class.other-handover-icon]="!handoversInfo.isCurrent"
                >
                  shopping_basket
                </mat-icon>
              }

              @case ('dropoff') {
                <mat-icon
                  [class]="'round-icon'"
                  [class.current-handover-icon]="handoversInfo.isCurrent"
                  [class.other-handover-icon]="!handoversInfo.isCurrent"
                >
                  flag
                </mat-icon>
              }
            }

            <div
              class="handover-title"
              [class.current-handover-text]="handoversInfo.isCurrent"
            >
              {{ handoversInfo.name }}

              @if (handoversInfo.type === 'dropoff') {
                @if (
                  handoversInfo.remainingSecondsToDropoff;
                  as remainingSecondsToDropoff
                ) {
                  <div class="eta">
                    <mat-icon style="padding: 5px">
                      share_arrival_time
                    </mat-icon>
                    @if (remainingSecondsToDropoff < 60) {
                      <div style="white-space: nowrap">
                        {{
                          (remainingSecondsToDropoff / 15 +
                            (1 - ((remainingSecondsToDropoff / 15) % 1))) *
                            15 | number: '1.0-0'
                        }}
                        <span style="font-size: 12px">seconds</span>
                      </div>
                    } @else {
                      <div style="white-space: nowrap">
                        {{ remainingSecondsToDropoff / 60.0 | number: '1.0-0' }}
                        <span style="font-size: 12px">minutes</span>
                      </div>
                    }
                  </div>
                }
              }
            </div>

            <div class="vertical-line-area">
              <div class="vertical-line"></div>
            </div>

            <div class="order-state-area">
              @if (
                handoversInfo.orderStateDescription;
                as orderStateDescription
              ) {
                <div class="order-state">
                  <div>
                    {{ orderStateDescription }}
                  </div>
                </div>
              }
            </div>
          }
        </div>
        @if (showUnlockSlider$ | async) {
          <div class="unlock-menu">
            <div style="width: 100%">
              <app-slide-to-action
                handleTitle="unlock"
                mainTitle="Slide to unlock"
                [cooldownTimeout]="sliderCooldownTimeout"
                (onCompleted)="onUnlockClick()"
              />
            </div>
          </div>
        }
        @if (supportPhoneNumber$ | async; as supportPhoneNumber) {
          <a
            mat-raised-button
            color="primary"
            href="tel:{{ supportPhoneNumber }}"
          >
            <div class="call-button-content">
              <mat-icon>call</mat-icon>
              Call support
            </div>
          </a>
        }
      </div>
    </div>
  </div>
}

@if (isOffline) {
  <div class="offline-warning-container">
    <div class="offline-warning">
      <mat-icon>cloud_off</mat-icon>
      <div>You are offline</div>
    </div>
  </div>
}

@if (orderNotFound) {
  <div class="offline-warning-container">
    <div class="offline-warning">
      <mat-icon>question_mark</mat-icon>
      <div>Order not found</div>
    </div>
  </div>
}
