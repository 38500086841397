<robot-control
  [robotCommunication]="robotCommunication"
  [highResolution]="active"
  [activeControl]="active"
  [isKeyboardEnabled]="true"
/>
@if (active) {
  <div
    class="all-controls"
    [class.instructor-mode-background]="
      robotCommunication.instructorModeActive$ | async
    "
  >
    <app-robot-control-panel
      class="robot-control-panel"
      [robotCommunication]="robotCommunication"
      [active]="active"
    />
    <div class="extra-controls">
      <a mat-raised-button href="/supervise-robots"> Robot Supervision </a>
    </div>
  </div>
}

@if (active && (robotCommunication.robotState$ | async); as robotState) {
  @if (robotState.issues) {
    <app-robot-issue-panel [issues]="robotState.issues" />
  }
}
