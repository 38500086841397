<div
  class="data-viewer-page-inner-container"
  [class.disable-gird]="dataViewerService.selectedItemId$ | async"
>
  <div class="data-viewer-column">
    <div class="data-viewer-menu">
      <mat-form-field>
        <mat-select
          [value]="dataViewerService.selectedDataViewerTab$ | async"
          (selectionChange)="
            dataViewerService.selectDataViewerTab($event.value)
          "
        >
          <mat-option class="tab-option" value="frames"> Frames </mat-option>
          <mat-option class="tab-option" value="snippets">
            Snippets
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-filter-selector class="data-viewer-filter" />
    </div>
    <div class="action-area">
      <div class="pick-options">
        @if ((dataViewerService.selectedDataViewerTab$ | async) === 'frames') {
          <app-pick-label
            label="pick all"
            [isPicked]="true"
            (clicked)="dataViewerService.pickAllItems()"
          />
          <app-pick-label
            label="unpick all"
            [isPicked]="false"
            (clicked)="dataViewerService.unpickAllItems()"
          />
          <button
            mat-flat-button
            color="accent"
            (click)="openMassActionFramesDialog()"
            [disabled]="!(dataViewerService.isAnyItemsPicked$ | async)"
          >
            Action
          </button>
        }
      </div>

      <app-sort-control
        [sortOptions]="sortingOptions"
        (onSortOptionUpdate)="updateSortingOrder($event)"
      />
    </div>

    <mat-divider />
    <app-data-viewer-grid class="grid-container" />
  </div>
  @if (dataViewerService.selectedItemId$ | async) {
    <app-data-viewer-selection class="data-viewer-column" />
  }
</div>
