<div class="global-overlay-container">
  <div class="error-message-list">
    @for (errorMsg of errorService.errorMsgs$ | async; track errorMsg) {
      <div class="error-message-item">
        <div>{{ errorMsg }}</div>
        <button mat-button color="accent" (click)="dismiss(errorMsg)">
          Close
        </button>
      </div>
    }
  </div>
</div>
