import { Component } from '@angular/core';
import { ClockingService } from './clocking.service';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { HoursSincePipe } from '../pipes/hours-since.pipe';

@Component({
  selector: 'app-clocking',
  templateUrl: './clocking.component.html',
  styleUrl: './clocking.component.sass',
  standalone: true,
  imports: [MatFabButton, MatTooltip, MatIcon, AsyncPipe, HoursSincePipe],
})
export class ClockingComponent {
  constructor(public clockingService: ClockingService) {}
}
