import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '@/app/core/backend.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { dateToLocalISOStringWithSeconds } from '@/utils/dateToLocalISOString';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { BlackboxRecord, BlackboxRecords } from './blackbox-records';
import { vParsePretty } from '@/utils/valibot-parse-pretty';
import { PrintDatetime } from '@/app/core/print-datetime/print-datetime.component';
import { locateDateInTimezone } from '@/utils/locate-date-in-timezone';

// Only at least 6 minute old records can be requested
// This is due to how the blackbox works, it records 5 minutes
// at a time and only after these 5 minutes they are available.
const MIN_REQUESTED_RECORD_AGE_MINUTES = 6 * 60 * 1000;
const HALF_HOUR_MS = 30 * 60 * 1000;

@Component({
  selector: 'robot-blackbox',
  templateUrl: './robot-blackbox.component.html',
  styleUrls: ['./robot-blackbox.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    FormsModule,
    MatButton,
    MatIconButton,
    MatIcon,
    PrintDatetime,
  ],
})
export class RobotBlackboxComponent implements OnInit, OnDestroy {
  private robotId: string | null = null;
  robotSerialNumber: number | null = null;

  blackboxRequestStartTime = dateToLocalISOStringWithSeconds(
    new Date(Date.now() - HALF_HOUR_MS),
  );
  blackboxRequestDurationMinutes: number | undefined = 2;

  private destroy$ = new ReplaySubject<void>(1);
  records: BlackboxRecord[] = [];

  selectedTimeZone: string | undefined = undefined;
  readonly supportedTimeZones = [
    'America/New_York',
    'America/Los_Angeles',
    'Asia/Tokyo',
    'Europe/Berlin',
    'UTC',
  ];

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private robotsService: RobotsBackendService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.robotId = params.get('robot-id');
      if (this.robotId != null) {
        this.populateRobotSerialNumber(this.robotId);
      }
      this.onUpdate();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onUpdate() {
    if (this.robotId === null) {
      return;
    }
    const path = `/robot-blackbox?robotId=${this.robotId}`;
    this.backendService
      .get(path)
      .pipe(
        takeUntil(this.destroy$),
        map((x) => vParsePretty(BlackboxRecords, x)),
      )
      .subscribe((records) => {
        this.records = records.records;
      });
  }

  updateDuration(durationMinutes: string) {
    const parsed = Number.parseFloat(durationMinutes);
    if (Number.isNaN(parsed)) {
      this.blackboxRequestDurationMinutes = undefined;
      return;
    } else {
      this.blackboxRequestDurationMinutes = parsed;
    }
  }

  requestRecording() {
    const startTime = locateDateInTimezone(
      new Date(this.blackboxRequestStartTime),
      this.selectedTimeZone,
    );
    const durationMin = this.blackboxRequestDurationMinutes;
    if (
      startTime === undefined ||
      durationMin === undefined ||
      durationMin <= 0
    ) {
      this.snackBar.open(
        "Can't send request, please fill out all fields.",
        'Ok',
      );
      return;
    }

    const endTime = new Date(
      startTime.getTime() + durationMin * 60 * 1000 /* Minutes to ms */,
    );

    const duration_to_request_end = Date.now() - endTime.getTime();
    if (duration_to_request_end < MIN_REQUESTED_RECORD_AGE_MINUTES) {
      this.snackBar.open(
        `Please try again in ${Math.ceil(
          (MIN_REQUESTED_RECORD_AGE_MINUTES - duration_to_request_end) /
            (60 * 1000),
        )} minutes. Records can only be accessed after a certain time.`,
        'Ok',
      );
      return;
    }

    const path = '/robot-blackbox';
    this.backendService
      .post(path, {
        robotId: this.robotId,
        startTime: startTime,
        endTime: endTime,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackBar.open('Request sent, might take a bit.', 'Ok');
        this.onUpdate();
      });
  }

  private populateRobotSerialNumber(robotId: string) {
    this.robotsService
      .getRobot(robotId)
      .then((robot) => (this.robotSerialNumber = robot.serialNumber))
      .catch((e) =>
        console.error(`Failed to fetch robot '${robotId}'. Reason:`, e),
      );
  }
}
