<mat-form-field appearance="fill" class="access-group-container">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList>
    @for (attribute of selectedAttributes; track attribute) {
      <mat-chip-row (removed)="removeAttribute(attribute)">
        {{ attribute }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    }
    <input
      placeholder="New..."
      #attributeInput
      [formControl]="formCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addAttribute($event.value)"
    />
    @if (formCtrl.hasError('whitespace') && !formCtrl.hasError('required')) {
      <mat-error> Whitespace is not allowed </mat-error>
    }
  </mat-chip-grid>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="addAttribute($event.option.viewValue)"
  >
    @for (attribute of filteredAttributes | async; track attribute) {
      <mat-option [value]="attribute">
        {{ attribute }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
