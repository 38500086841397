<div class="video-layout-container">
  <div class="column">
    <div class="robot-name" [class.robot-name-active-text-size]="active">
      <!-- '!=' is intentional here since serialNumber and shortName have different types -->
      {{ robotState?.serialNumber }}
      {{
        robotState?.shortName &&
        robotState?.shortName != robotState?.serialNumber
          ? '(' + robotState?.shortName + ')'
          : ''
      }}
    </div>
    <div class="robot-data-overview">
      <div class="robot-status">
        @for (statusString of statusStrings; track statusString) {
          <div>
            {{ statusString }}
          </div>
        }
      </div>
      <div class="batery-and-speed">
        <app-battery-status
          [batteryPercentage]="batteryPercentage"
          [charging]="!!isCharging"
        />
        <div
          class="speed"
          [class.autonomous-speed]="robotState?.controlType === 'Autonomy'"
        >
          <mat-icon>speed</mat-icon>
          {{ robotState?.velocity | number: '1.0-1' }} m/s
        </div>
      </div>
    </div>
  </div>

  <div class="column">
    <div class="column-two-top">
      <div style="position: absolute">
        @if ((robotStateReport?.length ?? 0) > 0) {
          <div
            class="robot-state-report"
            [class.attention-status-active-text-size]="active"
          >
            {{ robotStateReport }}
          </div>
        }

        @if (operationEmergencyStopActive) {
          <div
            class="emergency-stop-active"
            [class.attention-status-active-text-size]="active"
          >
            {{
              'Emergency Stop Activated: Autonomous mode is disabled. Please park safely and promptly. '
            }}
          </div>
        }

        @if ((supervisionState?.length ?? 0) > 0) {
          <div
            class="robot-state-report"
            [class.attention-status-active-text-size]="active"
          >
            {{ supervisionState }}
          </div>
        }

        @if ((userClaimReport?.length ?? 0) > 0) {
          <div
            class="robot-state-report"
            [class.attention-status-active-text-size]="active"
          >
            {{ userClaimReport }}
          </div>
        }

        @if (robotState?.awxUpdateActive) {
          <div
            class="robot-state-report"
            [class.attention-status-active-text-size]="active"
          >
            {{
              'Robot is currently being updated. Please contact Cartken before putting it into operation.'
            }}
          </div>
        }

        @for (
          attentionStatus of attentionStatusListTexts;
          track attentionStatus.text
        ) {
          <div
            class="attention-status"
            [class.attention-status-active-text-size]="active"
            [class.red-text]="attentionStatus.type === 'error'"
            [class.yellow-text]="attentionStatus.type === 'warning'"
          >
            <span
              class="attention-status-text"
              (click)="onStatusClick.emit(attentionStatus.originalMessage)"
              >{{ attentionStatus.text }}</span
            >
            <span class="mute-text"
              ><mat-icon>snooze</mat-icon> Click to snooze</span
            >
          </div>
        }
      </div>
    </div>

    <div class="column-two-bottom">
      @if (isOrderDetected) {
        <img
          class="order-detected-icon"
          height="50"
          src="assets/scale_white_24dp.svg"
        />
      }
    </div>
  </div>
</div>
