import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Order, OrderStatus, RejectionReason } from '../../core/order/order';
import { RobotDto } from '../../core/robots-service/backend/robot.dto';
import _ from 'underscore';
import moment from 'moment';
import { groupBy } from '../../../utils/group-by';
import { millisBetween } from '../../../utils/millis-between';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { MatButton } from '@angular/material/button';

interface OrdersPerRobot {
  robotName: string;
  numSuccessfulOrders: number;
}

interface OrdersPerHour {
  hour: string;
  numSuccessfulOrders: number;
}

interface OrdersPerName {
  name: string;
  numSuccessfulOrders: number;
}

interface RejectionByReason {
  reason: RejectionReason;
  numOrders: number;
}

export interface OrderStats {
  numSuccessfulOrders?: number;
  numFailedOrders?: number;
  numRejectedOrders?: number;
  numRejectedOrdersByReason?: RejectionByReason[];
  numCanceledOrders?: number;
  averageOrderCompletion?: number;
  ordersPerHour?: OrdersPerHour[];
  ordersPerRobot?: OrdersPerRobot[];
  ordersPerPickupDisplayName?: OrdersPerName[];
  ordersPerDropoffDisplayName?: OrdersPerName[];
  ordersPerPickupLocationId?: OrdersPerName[];
  ordersPerDropoffLocationId?: OrdersPerName[];
  firstOrder?: string;
  lastOrder?: string;
  processed: boolean;
}

export interface StatsData {
  orders: Order[];
  timeZone: string;
  robots: RobotDto[];
}

@Component({
  selector: 'order-stats-dialog',
  templateUrl: 'order-stats-dialog.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    DatePipe,
  ],
})
export class OrderStatsDialog {
  orderStats: OrderStats = { processed: false };
  constructor(@Inject(MAT_DIALOG_DATA) public data: StatsData) {
    if (!data.orders.length) {
      return;
    }
    data.orders.sort((a, b) => {
      // override uncheck array index access, since statusLog should contain at least Creation entry
      const aFirstStatusLogTimestamp = a.statusLog[0]!.timestamp;
      const bFirstStatusLogTimestamp = b.statusLog[0]!.timestamp;
      return (
        new Date(aFirstStatusLogTimestamp).getTime() -
        new Date(bFirstStatusLogTimestamp).getTime()
      );
    });
    const successfulOrders = data.orders.filter(
      (o: Order) => o.status === OrderStatus.FULFILLED,
    );
    this.orderStats.numSuccessfulOrders = successfulOrders.length;

    this.orderStats.numFailedOrders = data.orders.filter(
      (o: Order) => o.status === OrderStatus.FAILED,
    ).length;

    const rejectedOrders = data.orders.filter(
      (o: Order) => o.status === OrderStatus.REJECTED,
    );
    this.orderStats.numRejectedOrders = rejectedOrders.length;
    const rejectedOrdersByReason = groupBy(
      rejectedOrders,
      (order) => order.rejectionReason ?? RejectionReason.OTHER,
    );
    this.orderStats.numRejectedOrdersByReason = Array.from(
      rejectedOrdersByReason,
    ).map(([reason, orders]) => ({ reason, numOrders: orders.length }));

    this.orderStats.numCanceledOrders = data.orders.filter(
      (o: Order) => o.status === OrderStatus.CANCELED,
    ).length;
    const orderCompletionTimes = successfulOrders.map((o: Order) => {
      const firstStatusLogTimestamp = o.statusLog[0]?.timestamp;
      const lastStatusLogTimestamp = o.statusLog.slice(-1)[0]?.timestamp;
      return firstStatusLogTimestamp !== undefined &&
        lastStatusLogTimestamp !== undefined
        ? millisBetween(
            new Date(firstStatusLogTimestamp),
            new Date(lastStatusLogTimestamp),
          )
        : 0;
    });

    this.orderStats.averageOrderCompletion =
      orderCompletionTimes.reduce((sum, current) => sum + current, 0) /
      orderCompletionTimes.length;
    this.orderStats.ordersPerHour = this.processOrdersPerHour(
      data.orders,
      data.timeZone,
    ).sort((a, b) => a.hour.localeCompare(b.hour, 'en', { numeric: true }));

    this.orderStats.ordersPerPickupDisplayName =
      this.processOrdersPerPickupDisplayName(data.orders).sort(
        (a, b) => b.numSuccessfulOrders - a.numSuccessfulOrders,
      );
    this.orderStats.ordersPerDropoffDisplayName =
      this.processOrdersPerDropoffDisplayName(data.orders).sort(
        (a, b) => b.numSuccessfulOrders - a.numSuccessfulOrders,
      );

    this.orderStats.ordersPerPickupLocationId =
      this.processOrdersPerPickupLocationId(data.orders).sort(
        (a, b) => b.numSuccessfulOrders - a.numSuccessfulOrders,
      );
    this.orderStats.ordersPerDropoffLocationId =
      this.processOrdersPerDropoffLocationId(data.orders).sort(
        (a, b) => b.numSuccessfulOrders - a.numSuccessfulOrders,
      );
    this.orderStats.ordersPerRobot = this.processOrdersPerRobot(
      data.orders,
      data.robots,
    ).sort((a, b) => b.numSuccessfulOrders - a.numSuccessfulOrders);

    const firstOrderFirstLogTimestamp =
      data?.orders?.[0]?.statusLog?.[0]?.timestamp;
    this.orderStats.firstOrder =
      firstOrderFirstLogTimestamp &&
      moment(firstOrderFirstLogTimestamp).tz(data.timeZone).format('llll');
    const lastOrderFirstLogTimestamp =
      data?.orders?.[data.orders.length - 1]?.statusLog?.[0]?.timestamp;
    this.orderStats.lastOrder =
      lastOrderFirstLogTimestamp &&
      moment(lastOrderFirstLogTimestamp).tz(data.timeZone).format('llll');
    this.orderStats.processed = true;
  }

  private processOrdersPerPickupLocationId(orders: Order[]): OrdersPerName[] {
    const ordersPerHandoverDict = _.groupBy(
      orders.filter((o) => o.handovers[0]?.locationId),
      (order) => {
        return order.handovers[0]?.locationId!;
      },
    );
    return Object.entries(ordersPerHandoverDict).map(([key, value]) => {
      return { name: key, numSuccessfulOrders: value.length };
    });
  }

  private processOrdersPerDropoffLocationId(orders: Order[]): OrdersPerName[] {
    const ordersPerHandoverDict = _.groupBy(
      orders.filter((o) => o.handovers[1]?.locationId),
      (order) => {
        return order.handovers[1]?.locationId!;
      },
    );
    return Object.entries(ordersPerHandoverDict).map(([key, value]) => {
      return { name: key, numSuccessfulOrders: value.length };
    });
  }
  private processOrdersPerPickupDisplayName(orders: Order[]): OrdersPerName[] {
    const ordersPerHandoverDict = _.groupBy(
      orders.filter((o) => o.handovers[0]?.displayName),
      (order) => {
        return order.handovers[0]?.displayName!;
      },
    );
    return Object.entries(ordersPerHandoverDict).map(([key, value]) => {
      return { name: key, numSuccessfulOrders: value.length };
    });
  }

  private processOrdersPerDropoffDisplayName(orders: Order[]): OrdersPerName[] {
    const ordersPerHandoverDict = _.groupBy(
      orders.filter((o) => o.handovers[1]?.displayName),
      (order) => {
        return order.handovers[1]?.displayName!;
      },
    );
    return Object.entries(ordersPerHandoverDict).map(([key, value]) => {
      return { name: key, numSuccessfulOrders: value.length };
    });
  }

  private processOrdersPerRobot(
    orders: Order[],
    robots: RobotDto[],
  ): OrdersPerRobot[] {
    const ordersPerRobotDict = _.groupBy(orders, (order) => {
      return order.assignedRobotId!;
    });
    return Object.entries(ordersPerRobotDict).map(([key, value]) => {
      let name = key;
      const robot = robots.find((r) => r.id === key);
      if (robot?.shortName) {
        name = robot.shortName;
      }
      return { robotName: name, numSuccessfulOrders: value.length };
    });
  }

  private processOrdersPerHour(
    orders: Order[],
    timeZone: string,
  ): OrdersPerHour[] {
    const ordersPerHourDict = _.groupBy(orders, (order) => {
      return moment
        .tz(order.statusLog[0]?.timestamp, timeZone)
        .startOf('hour')
        .format('HH');
    });
    return Object.entries(ordersPerHourDict).map(([key, value]) => {
      return { hour: key, numSuccessfulOrders: value.length };
    });
  }
}
