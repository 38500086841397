import { Component } from '@angular/core';
import {
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Role } from '../../core/user';
import { BackendService } from '../../../app/core/backend.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { AttributeListInputComponent } from '../../core/attribute-list-input/attribute-list-input.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

export interface CreateUserData {
  username: string;
  password: string;
  displayName: string;
  accessGroups: string[];
  roles: string[];
}

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.sass'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    AttributeListInputComponent,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CreateUserDialogComponent {
  user: CreateUserData;
  availableRoles: string[];
  allOperationAccessGroups: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<CreateUserDialogComponent>,
    private backendService: BackendService,
  ) {
    this.availableRoles = Object.values(Role);
    this.user = {
      username: '',
      password: '',
      displayName: '',
      roles: [],
      accessGroups: [],
    };

    this.backendService
      .get<string[]>('/operations/access-groups')
      .subscribe((accessGroups: string[]) => {
        this.allOperationAccessGroups = accessGroups;
      });
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
