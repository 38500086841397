import { Component, Input } from '@angular/core';

import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-pick-icon',
  templateUrl: './pick-icon.component.html',
  styleUrls: ['./pick-icon.component.sass'],
  standalone: true,
  imports: [MatIcon],
})
export class PickIconComponent {
  @Input()
  isPicked: boolean = false;

  constructor() {}
}
