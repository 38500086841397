import {
  LocalizationMapTileLevelLayerProps,
  TesselatedHeightMap,
} from './localization-map-tile-level-layer';
import { getLatLngAltFromEnu } from './tileset-utils';

type MakeHeightMeshProps = Pick<
  LocalizationMapTileLevelLayerProps,
  | 'data'
  | 'tileOrigin'
  | 'minDisplayAltitude'
  | 'maxDisplayAltitude'
  | 'cellSize'
>;

function cropHeightMap(heightMapProps: MakeHeightMeshProps): Float32Array {
  if (!heightMapProps.data) {
    return new Float32Array();
  }
  const transformedHeightMap = new Float32Array(
    heightMapProps.data.data.length,
  );
  const tileOriginAltitude = heightMapProps.tileOrigin[2] ?? 0;
  const minAltitude =
    (heightMapProps.minDisplayAltitude ?? -Infinity) - tileOriginAltitude;
  const maxAltitude =
    (heightMapProps.maxDisplayAltitude ?? Infinity) - tileOriginAltitude;

  for (let i = 0; i < transformedHeightMap.length; ++i) {
    const height = heightMapProps.data.data[i];
    if (!isFinite(height) || height < minAltitude || height > maxAltitude) {
      transformedHeightMap[i] = NaN;
      continue;
    }
    transformedHeightMap[i] = height;
  }
  return transformedHeightMap;
}

export function makeHeightMesh(
  heightMapProps: MakeHeightMeshProps,
): TesselatedHeightMap {
  if (!heightMapProps.data) {
    return { attributes: {} };
  }
  const transformedHeightMap = cropHeightMap(heightMapProps);
  const cellSize = heightMapProps.cellSize ?? 1;
  const width = heightMapProps.data.width;
  const height = heightMapProps.data.height;

  const [north, east] = getLatLngAltFromEnu(heightMapProps.tileOrigin, [
    width * cellSize,
    height * cellSize,
    0,
  ]);

  const cellSizeX = (north - heightMapProps.tileOrigin[0]) / width;
  const cellSizeY = (east - heightMapProps.tileOrigin[1]) / height;
  const mesh = generateHeightCellCornerVertices(
    transformedHeightMap,
    width,
    height,
    cellSizeX,
    cellSizeY,
  );
  const meshIndices = generateIndices(transformedHeightMap, width, height);

  const meshAttributes = {
    POSITION: { value: mesh.vertices, size: 3 },
    TEXCOORD_0: { value: mesh.texCoords, size: 2 },
    // NORMAL: {}, - optional, but creates the high poly look with lighting
  };

  return {
    mode: 4, // TRIANGLES
    indices: { value: Uint32Array.from(meshIndices), size: 3 },
    attributes: meshAttributes,
  };
}

function generateHeightCellCornerVertices(
  heightMap: Float32Array,
  width: number,
  height: number,
  cellSizeX: number,
  cellSizeY: number,
): { vertices: Float32Array; texCoords: Float32Array } {
  const dx = 1;
  const dy = width;
  const cornerWidth = width + 1;
  const cornerHeight = height + 1;
  const numVertices = cornerWidth * cornerHeight;
  const vertices = new Float32Array(numVertices * 3);
  const texCoords = new Float32Array(numVertices * 2);

  // The index i iterates over the linear (total) array position.
  for (let i = 0, y = 0; y < cornerHeight; y++) {
    for (let x = 0; x < cornerWidth; x++, i++) {
      let cumulativeHeight = 0;
      let heightCount = 0;
      const idx = y * width + x;

      if (x > 0 && y > 0 && isFinite(heightMap[idx - dx - dy])) {
        cumulativeHeight += heightMap[idx - dx - dy];
        ++heightCount;
      }
      if (x > 0 && y < height && isFinite(heightMap[idx - dx])) {
        cumulativeHeight += heightMap[idx - dx];
        ++heightCount;
      }
      if (x < width && y > 0 && isFinite(heightMap[idx - dy])) {
        cumulativeHeight += heightMap[idx - dy];
        ++heightCount;
      }
      if (x < width && y < height && isFinite(heightMap[idx])) {
        cumulativeHeight += heightMap[idx];
        ++heightCount;
      }
      const k = i * 3;
      vertices[k + 0] = x * cellSizeX;
      vertices[k + 1] = y * cellSizeY;
      vertices[k + 2] = cumulativeHeight / heightCount;
      texCoords[i * 2 + 0] = x / width;
      texCoords[i * 2 + 1] = y / height;
    }
  }
  return { vertices, texCoords };
}
/*
function generateHeightCellCornerVertices2(
  heightMap: Float32Array,
  width: number,
  height: number,
  cellSize: number,
): { vertices: Float32Array; texCoords: Float32Array } {
  const dx = 1;
  const dy = width;
  const cornerWidth = width + 1;
  const cornerHeight = height + 1;
  const numCorners = cornerWidth * cornerHeight;
  const vertices = new Float32Array(numCorners * 3);
  const texCoords = new Float32Array(numCorners * 2);

  const cumulativeHeight = new Float32Array(numCorners);
  const numHeights = new Uint8Array(numCorners);

  for (let y = 0; y < height; y++) {
    const rowOffset = y * width;
    const cornerRowOffset = y * cornerWidth;
    let lastVal = heightMap[rowOffset];
    let lastCount = 1;
    if (!isFinite(lastVal)) {
      lastVal = 0;
      lastCount = 0;
    }
    cumulativeHeight[cornerRowOffset] = lastVal;
    numHeights[cornerRowOffset] = lastCount;
    for (let x = 1; x < width; x++) {
      const val = heightMap[rowOffset + x];
      const cornerIdx = cornerRowOffset + x;
      if (isFinite(val)) {
        cumulativeHeight[cornerIdx] = lastVal + val;
        numHeights[cornerIdx] = lastCount + 1;
        lastVal = val;
        lastCount = 1;
      } else {
        cumulativeHeight[cornerIdx] = lastVal;
        numHeights[cornerIdx] = lastCount;
        lastVal = 0;
        lastCount = 0;
      }
    }
    cumulativeHeight[cornerRowOffset + width] = lastVal;
    numHeights[cornerRowOffset + width] = lastCount;
  }

  for (let x = 0; x < cornerWidth; x++) {
    const rowOffset = y * width;
    const cornerRowOffset = y * cornerWidth;
    let lastVal = heightMap[rowOffset];
    let lastCount = 1;
    if (!isFinite(lastVal)) {
      lastVal = 0;
      lastCount = 0;
    }
    cumulativeHeight[cornerRowOffset] = lastVal;
    numHeights[cornerRowOffset] = lastCount;
    for (let x = 1; x < width; x++, i2++) {
      const val = heightMap[rowOffset + x];
      const cornerIdx = cornerRowOffset + x;
      if (isFinite(val)) {
        cumulativeHeight[cornerIdx] = lastVal + val;
        numHeights[cornerIdx] = lastCount + 1;
        lastVal = val;
        lastCount = 1;
      } else {
        cumulativeHeight[cornerIdx] = lastVal;
        numHeights[cornerIdx] = lastCount;
        lastVal = 0;
        lastCount = 0;
      }
    }
    cumulativeHeight[cornerRowOffset + width] = lastVal;
    numHeights[cornerRowOffset + width] = lastCount;
  }

  return { vertices, texCoords };
}*/

function generateIndices(
  heightMap: Float32Array,
  width: number,
  height: number,
): number[] {
  const dx = 1;
  const dy = width + 1;
  const triangleIndices: number[] = [];
  for (let y = 0; y < height; ++y) {
    for (let x = 0; x < width; ++x) {
      if (!isFinite(heightMap[y * width + x])) {
        continue;
      }
      const i = y * dy + x;
      const i1 = i;
      const i2 = i + dx;
      const i3 = i + dx + dy;
      const i4 = i + dy;
      triangleIndices.push(i2, i1, i4, i4, i3, i2); // Add two triangles for the height cell.
    }
  }
  return triangleIndices;
}
