import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { OperationType } from '../operation';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

export interface CreateOperationDialogInput {
  availableRegions: string[];
}
export interface CreateOperationData {
  id: string;
  accessGroups: string[];
  operationType: string;
}

@Component({
  selector: 'app-create-operation-dialog',
  templateUrl: './create-operation-dialog.component.html',
  styleUrls: ['./create-operation-dialog.component.sass'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CreateOperationDialogComponent {
  operation: CreateOperationData;
  availableOperationTypes: string[];
  constructor(
    public dialogRef: MatDialogRef<CreateOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInput: CreateOperationDialogInput,
  ) {
    this.availableOperationTypes = Object.values(OperationType);

    this.operation = {
      id: '',
      accessGroups: [],
      operationType:
        this.availableOperationTypes[0] ?? OperationType.OrderOperation,
    };
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
