<div class="page-outer-container">
  <app-toolbar style="height: fit-content">
    <button mat-menu-item [routerLink]="'/'">Home</button>
  </app-toolbar>
  <div class="page-inner-container">
    <app-left-sidebar
      (selectCollection)="setCollection($event)"
      class="column"
    />
    @if (selectedCollection) {
      <app-collection [collection]="selectedCollection" class="column" />
    } @else {
      <app-data-viewer class="column" />
    }
  </div>
</div>
