import { IsoDateTime } from '@cartken/map-types';
import * as v from 'valibot';

export type BlackboxRecord = v.InferOutput<typeof BlackboxRecord>;
export const BlackboxRecord = v.object({
  recordId: v.string(),
  link: v.optional(v.string()),
  status: v.string(),
  statusDescription: v.optional(v.string()),
  startTime: IsoDateTime,
  endTime: IsoDateTime,
  requestReason: v.optional(v.string()),
});

export const BlackboxRecords = v.object({
  records: v.array(BlackboxRecord),
});
