import { Component } from '@angular/core';
import { ErrorService } from './error.service';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-error-system-overlay',
  templateUrl: './error-system-overlay.component.html',
  styleUrls: ['./error-system-overlay.component.sass'],
  standalone: true,
  imports: [MatButton, AsyncPipe],
})
export class ErrorSystemOverlayComponent {
  constructor(readonly errorService: ErrorService) {}

  dismiss(errorMsg: string) {
    this.errorService.dismissError(errorMsg);
  }
}
