import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-handover-location-title-splitter',
  templateUrl: './handover-location-title-splitter.component.html',
  styleUrls: ['./handover-location-title-splitter.component.sass'],
  standalone: true,
})
export class HandoverLocationTitleSplitterComponent {
  @Input()
  title!: string;

  constructor() {}
}
