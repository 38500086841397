<div class="mat-elevation-z8 ops-overview">
  <div class="overview-title">
    <mat-icon color="primary">location_on</mat-icon>

    <button mat-button [routerLink]="'/operations/' + operationId">
      {{ displayName }} | {{ operationId }}
    </button>
  </div>
  <div class="emergency-stop">
    <div
      [ngClass]="{
        'emergency-stop-active-text': emergencyStopActive,
        'emergency-text': !emergencyStopActive,
      }"
    >
      Emergency<br />
      Stop
      @if (emergencyStopActive) {
        <span>Active</span>
      }
    </div>
    <div
      [ngClass]="{
        'emergency-stop-active': emergencyStopActive,
        'emergency-button': !emergencyStopActive,
      }"
    >
      <button mat-icon-button (click)="onEmergencyButtonClicked()">
        <mat-icon>warning</mat-icon>
      </button>
    </div>
  </div>
  <hr class="splitter-line" />
  <app-operation-robots-stats [robots]="robots$ | async">
  </app-operation-robots-stats>

  <app-operation-orders-stats
    [orders]="orders$ | async"
    [finishedOrders]="finishedOrders$ | async"
    [rejectedOrdersWarningThreshold]="rejectedOrdersWarningThreshold"
  />
  <app-waiting-queue-management
    [robots]="robots$ | async"
    [storageMapElement]="storageMapElement$ | async"
    [activeWaitingQueueNames]="activeWaitingQueueNames$ | async"
    [availableWaitingQueues]="availableWaitingQueues$ | async"
  />
</div>
