<h1 mat-dialog-title>Visibility</h1>
<div mat-dialog-content>
  @for (
    visibilityEntry of visibility.visibilityEntries;
    track visibilityEntry
  ) {
    <mat-slide-toggle
      class="visibility-row"
      [(ngModel)]="visibilityEntry.visible"
      >{{ visibilityEntry.displayName }}
    </mat-slide-toggle>
  }
  <mat-divider style="margin-top: 5px; margin-bottom: 5px" />
  @for (slippyTilesName of slippyTilesNames; track slippyTilesName) {
    <div>
      <mat-slider min="0" max="1" step="0.1">
        <input
          matSliderThumb
          [value]="visibility.slippyTilesOpacities[slippyTilesName]"
          (valueChange)="
            visibility.slippyTilesOpacities[slippyTilesName] = $event
          "
        />
      </mat-slider>
      <span>{{ slippyTilesName }} Layer Opacity</span>
    </div>
  }
  <mat-divider style="margin-top: 5px; margin-bottom: 5px" />
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="visibility">Apply</button>
</div>
