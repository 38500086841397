import * as v from 'valibot';
import {
  IdInteger,
  IsoDateTime,
  Latitude,
  Longitude,
  NonEmptyString,
  PositiveInteger,
  PositiveNumber,
  uniqueArray,
} from '../common';

export type RoadEdgeProperties = v.InferOutput<typeof RoadEdgeProperties>;
export const RoadEdgeProperties = v.object({
  startNodeId: IdInteger,
  endNodeId: IdInteger,
  length: PositiveNumber,
  /** Estimated duration in seconds. */
  estimatedDuration: v.optional(PositiveNumber),
});

export enum CrossingType {
  RESIDENTIAL_ROAD = 'ResidentialRoad', // Cars drive slowly and on a single lane.
  SINGLE_LANE_ROAD = 'SingleLaneRoad', // Cars drive faster than on residential roads.
  MULTI_LANE_ROAD = 'MultiLaneRoad', // Cars drive fast and on multiple lanes.
  DRIVEWAY = 'Driveway',
  BIKE_PATH = 'BikePath',
  CURB = 'Curb',
}

export const CrossingProperties = v.object({
  crosses: v.enum(CrossingType),
  trafficLightIds: v.optional(uniqueArray(IdInteger), []),
  buttonPressRequired: v.optional(v.boolean(), false),
  estimatedCrossTrafficVelocity: v.optional(PositiveNumber),
});

const EdgeProperties = v.object({
  startNodeId: IdInteger,
  endNodeId: IdInteger,
  length: PositiveNumber,
});

const RobotEdgeBaseProperties = v.object({
  ...EdgeProperties.entries,
  maxSpeed: v.optional(PositiveNumber),
  requiresHazardLights: v.optional(v.boolean()),
  allowUnsupervisedAutonomy: v.optional(v.boolean()),
});

export const RobotEdgeProperties = v.object({
  ...RobotEdgeBaseProperties.entries,
  oneway: v.optional(v.boolean()),
  startWidth: v.nullish(PositiveNumber),
  endWidth: v.nullish(PositiveNumber),
  slowDownFactor: v.nullish(PositiveNumber),
  crossing: v.optional(CrossingProperties),
  mutexIds: v.optional(uniqueArray(IdInteger)),
  blockedAt: v.optional(IsoDateTime),
  blockedUntil: v.optional(IsoDateTime),
  infrastructureId: v.optional(IdInteger),
  infrastructureLocationName: v.optional(NonEmptyString),
});
export type RobotEdgeProperties = v.InferOutput<typeof RobotEdgeProperties>;

export const RobotQueueEdgeProperties = v.object({
  ...RobotEdgeBaseProperties.entries,
  queueSlotPriorities: v.nullish(v.array(PositiveNumber), []),
  /** Unique for all queue edges */
  names: v.optional(uniqueArray(NonEmptyString), []),
  /** Unique within the array */
  displayNames: v.nullish(uniqueArray(NonEmptyString), []),
});
export type RobotQueueEdgeProperties = v.InferOutput<
  typeof RobotQueueEdgeProperties
>;

export const MovablePlatformEdgeProperties = v.object({
  ...EdgeProperties.entries,
  oneWay: v.optional(v.boolean(), false),
});
export type MovablePlatformEdgeProperties = v.InferOutput<
  typeof MovablePlatformEdgeProperties
>;

export const AprilTagProperties = v.object({
  id: PositiveInteger,
});
export type AprilTagProperties = v.InferOutput<typeof AprilTagProperties>;

export const SlippyTilesProperties = v.object({
  tilesBaseUrl: v.pipe(NonEmptyString, v.url()),
  name: NonEmptyString,
  minZoom: v.optional(PositiveNumber),
  maxZoom: v.optional(PositiveNumber),
});
export type SlippyTilesProperties = v.InferOutput<typeof SlippyTilesProperties>;

export const LocalizationMapTilesProperties = v.object({
  name: NonEmptyString,
  tilesBaseUrl: v.pipe(NonEmptyString, v.url()),
  /** In meters. */
  tilesSize: PositiveNumber,
  layerNames: uniqueArray(NonEmptyString),
  tilesOriginLatitude: Latitude,
  tilesOriginLongitude: Longitude,
  tilesOriginAltitude: v.optional(v.number()),
  minAltitude: v.optional(v.number()),
  maxAltitude: v.optional(v.number()),
});
export type LocalizationMapTilesProperties = v.InferOutput<
  typeof LocalizationMapTilesProperties
>;

export const OperationRegionProperties = v.object({
  operationId: v.string(),
});
export type OperationRegionProperties = v.InferOutput<
  typeof OperationRegionProperties
>;

export const HandoverLocationProperties = v.object({
  streetNumber: v.optional(v.string()),
  streetName: v.optional(v.string()),
  subpremise: v.optional(v.string()),
});
export type HandoverLocationProperties = v.InferOutput<
  typeof HandoverLocationProperties
>;

export enum InfrastructureType {
  MITSUBISHI_ELEVATOR_GROUP = 'mitsubishi-elevator-group',
  MITSUBISHI_ELEVATOR = 'mitsubishi-elevator',
  MOCK_ELEVATOR_GROUP = 'mock-elevator-group',
  MOCK_ELEVATOR = 'mock-elevator',
  NOTIFICATION = 'notification',
  COMPARTMENT_INSPECTION = 'compartment-inspection',
}

export const InfrastructureProperties = v.object({
  externalInfrastructureId: v.nullish(v.string()),
  infrastructureType: v.enum(InfrastructureType),
  parentInfrastructureId: v.optional(IdInteger),
  customFields: v.nullish(
    v.record(v.string(), v.string()),
    {} as Record<string, string>,
  ),
});
export type InfrastructureProperties = v.InferOutput<
  typeof InfrastructureProperties
>;

export const PoleProperties = v.object({
  radius: PositiveNumber,
});
