import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BackendService } from '../core/backend.service';
import { visiblePageTimer } from '../../utils/page-visibility';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  merge,
  switchMap,
  takeUntil,
} from 'rxjs';
import { RobotDto } from '../core/robots-service/backend/robot.dto';
import { Operation } from '../operations/operation';
import { CrossingMonitoringLocalStateService } from './crossing-monitoring-local-state.service';
import { millisBetween } from '../../utils/millis-between';
import { groupBy } from '../../utils/group-by';
import { DomSanitizer } from '@angular/platform-browser';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MapComponent } from '../core/map/map.component';
import { MatMiniFabButton, MatAnchor } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

const ROBOT_POLLING_INTERVAL_MILLIS = 1000;

interface RobotsPerCrossing {
  crossingId: number;
  robots: RobotDto[];
  operationId: string;
  maxStandstillDuration?: number;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-robot-operator-overview',
  templateUrl: './crossing-monitoring.component.html',
  styleUrls: ['./crossing-monitoring.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    MapComponent,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    MatAnchor,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class CrossingMonitoringComponent implements OnInit, OnDestroy {
  private crossingMonitoringLocalStateService = inject(
    CrossingMonitoringLocalStateService,
  );

  private _destroy$ = new Subject<void>();
  private _refetch$ = new Subject<void>();

  selectedOperationIds =
    this.crossingMonitoringLocalStateService.getSelectedOperationIds();

  operationIds!: Observable<string[]>;
  robotsGroupedByCrossing: RobotsPerCrossing[] = [];

  standingDuration: number | null =
    this.crossingMonitoringLocalStateService.getStandingDurationThreshold();

  focusOnCrossingId: number | undefined = undefined;
  robotMarkerMap = new Map<string, google.maps.Marker>();

  private googleMap!: google.maps.Map;

  readonly googleMapOptions = {
    center: { lat: 0, lng: 0 },
    zoom: 1,
    mapTypeId: google.maps.MapTypeId.SATELLITE,
    tilt: 0,
    clickableIcons: false,
    disableDoubleClickZoom: true,
  };

  constructor(
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next();

    this.robotMarkerMap.forEach((marker, robotId) => {
      marker.setMap(null);
      this.robotMarkerMap.delete(robotId);
    });
  }

  ngOnInit(): void {
    merge(visiblePageTimer(0, ROBOT_POLLING_INTERVAL_MILLIS), this._refetch$)
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() => this.getCrossingRobots()),
        map((robots: RobotDto[]) => {
          const groups = groupBy(
            robots,
            (robot) => robot.routeMatch?.currentOrUpcomingCrossingId ?? -1,
          );

          return Array.from(groups.entries()).map(([crossingId, robots]) => {
            return <RobotsPerCrossing>{
              crossingId,
              robots,
              operationId: robots[0]?.assignedOperationId ?? '',
            };
          });
        }),
      )
      .subscribe((robotsGroupedByCrossing) => {
        if (this.focusOnCrossingId) {
          const focusGroup = robotsGroupedByCrossing.find(
            (group) => group.crossingId === this.focusOnCrossingId,
          );
          if (!focusGroup) {
            this.emitFocusChange(undefined);
          } else {
            this.updateCrossingGroupOnMap(focusGroup);
          }
        }
        this.robotsGroupedByCrossing = robotsGroupedByCrossing
          .filter((group) => group.crossingId > -5)
          .map((group) => {
            group.maxStandstillDuration = Math.max(
              ...group.robots.map((r) => this.getStandStillDuration(r)),
            );

            group.robots = group.robots.sort(
              (a, b) =>
                (this.getDistanceToCrossing(a) ?? 1000) -
                (this.getDistanceToCrossing(b) ?? 1000),
            );
            return group;
          })
          .sort(
            (a, b) =>
              (b.maxStandstillDuration ?? 0) - (a.maxStandstillDuration ?? 0),
          );

        this.cdr.detectChanges();
      });

    this.operationIds = this.backendService
      .get<Operation[]>(`/operations/`)
      .pipe(
        takeUntil(this._destroy$),
        map((operations) => operations.map((operation) => operation.id)),
      );
  }

  onGoogleMap(googleMap: google.maps.Map) {
    this.googleMap = googleMap;
    if (!this.focusOnCrossingId) {
      return;
    }
    const groupToFocus = this.robotsGroupedByCrossing.find(
      (group) => group.crossingId === this.focusOnCrossingId,
    );
    if (!groupToFocus) {
      return;
    }
    this.updateCrossingGroupOnMap(groupToFocus);
    const bounds = new google.maps.LatLngBounds();
    for (const robot of groupToFocus.robots) {
      const latlngRobot = {
        lat: robot.location.latitude,
        lng: robot.location.longitude,
      };
      bounds.extend(latlngRobot);
    }

    this.googleMap.fitBounds(bounds);
    const zoom = this.googleMap.getZoom() ?? 15;
    this.googleMap.setZoom(zoom > 21 ? 21 : zoom); // restrict zoom level
  }

  private updateCrossingGroupOnMap(group: RobotsPerCrossing) {
    const robotIcon = {
      url: 'assets/robot.png', // url
      scaledSize: new google.maps.Size(40, 40), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
      labelOrigin: new google.maps.Point(50, 15),
    };
    this.robotMarkerMap.forEach((marker, robotId) => {
      if (!group.robots.some((robot) => robot.id === robotId)) {
        marker.setMap(null);
        this.robotMarkerMap.delete(robotId);
      }
    });

    for (const robot of group.robots) {
      if (!this.robotMarkerMap.has(robot.id)) {
        const robotMapMarker = new google.maps.Marker({
          map: this.googleMap,
          label: {
            text: robot.shortName ?? '',
            fontSize: '20px',
            fontWeight: 'bold',
          },
        });
        robotMapMarker.setVisible(true);
        robotMapMarker.setIcon(robotIcon);
        robotMapMarker.addListener('click', () => {
          window.open(`/robots/supervise/${robot.id}`, '_blank');
        });
        this.robotMarkerMap.set(robot.id, robotMapMarker);
      }
      const latlngRobot = {
        lat: robot.location.latitude,
        lng: robot.location.longitude,
      };

      this.robotMarkerMap
        .get(robot.id)
        ?.setPosition(new google.maps.LatLng(latlngRobot));
    }
  }

  private async getCrossingRobotsForSelectedOperations(): Promise<RobotDto[]> {
    const robotsPerOperation = await Promise.all(
      this.selectedOperationIds.map((operationId) =>
        firstValueFrom(
          this.backendService.get<RobotDto[]>(
            `/robots?crossing=true&assigned-operation-id=${operationId}`,
          ),
        ),
      ),
    );
    return robotsPerOperation.flat();
  }

  private async getCrossingRobots(): Promise<RobotDto[]> {
    if (this.selectedOperationIds.length === 0) {
      return await firstValueFrom(
        this.backendService.get<RobotDto[]>('/robots?crossing=true'),
      );
    }
    return await this.getCrossingRobotsForSelectedOperations();
  }

  refetch() {
    this._refetch$.next();
    this.crossingMonitoringLocalStateService.setSelectedOperationIds(
      this.selectedOperationIds,
    );
    this.crossingMonitoringLocalStateService.setStandingDurationThreshold(
      this.standingDuration,
    );
  }

  getStandStillDuration(robot: RobotDto) {
    return robot.stopState?.stoppedSince !== undefined
      ? millisBetween(new Date(robot.stopState?.stoppedSince), new Date()) /
          1000
      : 0;
  }

  getDistanceToCrossing(robot: RobotDto): number | undefined {
    return robot.routeMatch?.distanceToCrossing;
  }

  getRobotIdList(robots: RobotDto[]) {
    return robots.map((robot) => robot.id).join(',');
  }

  getOperatorDisplayName(robot: RobotDto) {
    if (!robot.controllingRobotOperatorId) {
      return '';
    }
    return (
      robot.connectedRobotOperators?.find(
        (op) => op.id === robot.controllingRobotOperatorId,
      )?.displayName ?? ''
    );
  }

  getDistanceToCrossingText(robot: RobotDto) {
    const distance = robot.routeMatch?.distanceToCrossing;
    if (!distance) {
      return '';
    }
    if (distance < 0) {
      return `Distance to crossing ${distance.toFixed(1)} m`;
    }

    return `Distance into crossing ${Math.abs(distance).toFixed(1)} m`;
  }

  transform(image: Buffer) {
    return this.sanitizer.bypassSecurityTrustUrl(
      'data:image/jpeg;base64,' + image.toString('base64'),
    );
  }

  emitFocusChange(groupToFocus: RobotsPerCrossing | undefined) {
    if (this.focusOnCrossingId || !groupToFocus) {
      this.focusOnCrossingId = undefined;
      this.robotMarkerMap.forEach((marker, robotId) => {
        marker.setMap(null);
        this.robotMarkerMap.delete(robotId);
      });
      return;
    }
    this.focusOnCrossingId = groupToFocus.crossingId;
  }
}
