<div mat-dialog-content>
  <table>
    @for (
      shortcutDescription of shortcutsTableData;
      track shortcutDescription;
      let i = $index
    ) {
      <tr [class.odd-row]="i % 2 === 0">
        <th class="shortcut">
          @for (shortcut of shortcutDescription.shortcuts; track shortcut) {
            <div class="shortcut-item">
              {{ shortcut }}
            </div>
            <div class="shortcut-delimeter">or</div>
          }
        </th>
        <th class="description">{{ shortcutDescription.description }}</th>
      </tr>
    }
  </table>
</div>
