import { Observable, Subject } from 'rxjs';
import { BaseMap, MapStyle } from './base-map';
import mapboxgl from 'mapbox-gl';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox';
import { DeckProps, MapView } from '@deck.gl/core';
import { LatLngBounds, LatLngLike, convertLatLng } from 'spherical-geometry-js';
import { environment } from '../../../environments/environment';

export class MapboxBaseMap implements BaseMap {
  private readonly mapboxMap: mapboxgl.Map;
  private readonly mapboxOverlay: MapboxOverlay;
  private terrainEnabled = false;
  private _boundsChanged$ = new Subject<LatLngBounds>();

  constructor(mapContainerElement: HTMLElement) {
    this.mapboxMap = new mapboxgl.Map({
      container: mapContainerElement,
      antialias: true,
      doubleClickZoom: false,
      center: [0, 10],
      zoom: 1.5,
      maxZoom: 23,
      projection: { name: 'mercator' },
      logoPosition: 'bottom-right',
      accessToken: environment.mapboxApiKey,
    });
    this.setMapStyle('light');
    this.mapboxOverlay = new MapboxOverlay({
      interleaved: true,
    });
    this.mapboxMap.addControl(this.mapboxOverlay);
    this.mapboxMap.on('moveend', () => {
      this._boundsChanged$.next(this.getBounds());
    });
    this.mapboxMap.on('style.load', () => {
      this.mapboxMap.addSource('mapbox-terrain', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14,
      });
    });
  }

  enableTerrain(enabled: boolean) {
    this.terrainEnabled = enabled;
    this.mapboxMap.setTerrain(enabled ? { source: 'mapbox-terrain' } : null);
  }

  setMapStyle(mapStyle: MapStyle) {
    switch (mapStyle) {
      case 'light':
        this.mapboxMap.setStyle('mapbox://styles/mapbox/light-v11');
        break;
      case 'roadmap':
        this.mapboxMap.setStyle('mapbox://styles/mapbox/streets-v12');
        break;
      case 'satellite':
        this.mapboxMap.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
        break;
    }
    if (this.terrainEnabled) {
      this.mapboxMap.on('style.load', () => this.enableTerrain(true));
    }
  }

  getElevation(latLng: LatLngLike): number {
    const l = convertLatLng(latLng);
    return this.mapboxMap.queryTerrainElevation([l[0], l[1]]) ?? 0;
  }

  boundsObservable(): Observable<LatLngBounds> {
    return this._boundsChanged$.asObservable();
  }

  getBounds(): LatLngBounds {
    const bounds = this.mapboxMap.getBounds();
    return new LatLngBounds(bounds?.getSouthWest(), bounds?.getNorthEast());
  }

  getZoom(): number {
    return this.mapboxMap.getZoom();
  }

  fitBounds(bounds: LatLngBounds): void {
    this.mapboxMap.fitBounds([
      { lat: bounds.getSouthWest().lat(), lng: bounds.getSouthWest().lng() },
      { lat: bounds.getNorthEast().lat(), lng: bounds.getNorthEast().lng() },
    ]);
  }

  enableDragPanning(enabled: boolean): void {
    if (enabled) {
      this.mapboxMap.dragPan.enable();
      this.mapboxMap.dragRotate.enable();
    } else {
      this.mapboxMap.dragPan.disable();
      this.mapboxMap.dragRotate.disable();
    }
  }

  enableMouseWheelZooming(enabled: boolean): void {
    if (enabled) {
      this.mapboxMap.scrollZoom.enable();
    } else {
      this.mapboxMap.scrollZoom.disable();
    }
  }

  setProps(props: DeckProps<MapView>): void {
    this.mapboxOverlay.setProps(props as MapboxOverlayProps);
  }
}
