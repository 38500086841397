<div class="metadata-grid">
  <table class="borderless-table">
    @for (field of data; track field) {
      <tr>
        <td>{{ field.label }}</td>
        <td>{{ field.value }}</td>
      </tr>
    }
  </table>
</div>
