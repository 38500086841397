<div class="dialog-container">
  <mat-form-field appearance="fill" class="button-container">
    <mat-label>Description</mat-label>
    <input
      matInput
      placeholder="e.g. Sidewalk construction"
      [(ngModel)]="description"
    />
  </mat-form-field>

  @if (blockedUntil) {
    <span> Blocked until {{ blockedUntil | date: 'medium' }}</span>
  }
  <div class="blocked-until-input">
    <div>Block for:</div>
    <mat-form-field appearance="fill">
      <mat-label>Months</mat-label>
      <input
        matInput
        type="number"
        placeholder="1"
        min="0"
        max="2000"
        step="1"
        [(ngModel)]="blockedUntilMonths"
        (change)="updateBlockedUntil()"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Weeks</mat-label>
      <input
        matInput
        type="number"
        placeholder="1"
        min="0"
        max="2000"
        step="1"
        [(ngModel)]="blockedUntilWeeks"
        (change)="updateBlockedUntil()"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Days</mat-label>
      <input
        matInput
        type="number"
        placeholder="1"
        min="0"
        max="2000"
        step="1"
        [(ngModel)]="blockedUntilDays"
        (change)="updateBlockedUntil()"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Hours</mat-label>
      <input
        matInput
        type="number"
        placeholder="1"
        min="0"
        max="2000"
        step="1"
        [(ngModel)]="blockedUntilHours"
        (change)="updateBlockedUntil()"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Minutes</mat-label>
      <input
        matInput
        type="number"
        placeholder="1"
        min="0"
        max="2000"
        step="1"
        [(ngModel)]="blockedUntilMinutes"
        (change)="updateBlockedUntil()"
      />
    </mat-form-field>
  </div>
  <div #mapContainer id="mapContainer"></div>

  @if (!waitingForUpdateToFinish) {
    <div class="button-container">
      <button mat-raised-button class="cancel" (click)="onCancelClick()">
        <mat-icon>close</mat-icon> Cancel
      </button>

      <button mat-raised-button class="reset" (click)="onResetClick()">
        <mat-icon>restart_alt</mat-icon> Reset
      </button>
      <button
        mat-raised-button
        class="Update"
        [disabled]="!description"
        (click)="onUpdateClick()"
      >
        <mat-icon>done</mat-icon> Update
      </button>
    </div>
  } @else {
    <div class="button-container">Updating map <mat-spinner /></div>
  }
</div>
