@if (issues.length > 0) {
  <mat-icon class="issues-icon">priority_high</mat-icon>
  <div class="issues-list">
    {{ '' // TODO: Get the Organization ID from the Backend }}
    @for (issue of issues; track issue) {
      <a
        href="https://app.clickup.com/t/9004035169/{{
          issue.customId | default: issue.id
        }}"
        target="_blank"
        class="issue"
      >
        <b>[{{ issue.customId | default: issue.id }}]</b>
        {{ issue.name }}
      </a>
    }
  </div>
}
