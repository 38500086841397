<div class="video-layout-container">
  <canvas class="video-container" #canvas oncontextmenu="return false;">
  </canvas>
  <audio></audio>
  <div class="text-layout">
    <robot-text-layout
      [active]="active"
      [robotStateReport]="robotStateReport"
      [userClaimReport]="userClaimReport"
      [attentionStatusList]="attentionStatusList"
      [statusStrings]="statusStrings"
      [robotState]="robotState"
      [connectionStats]="connectionStats"
      [robotAttentionReport]="robotAttentionReport"
      [isOrderDetected]="robotState?.orderDetected"
      (onStatusClick)="onStatusClick.emit($event)"
      [isCharging]="isCharging"
      [batteryPercentage]="batteryPercentage"
      [supervisionState]="supervisionState"
      [operationEmergencyStopActive]="operationEmergencyStopActive"
    />
  </div>
</div>
