<h2 mat-dialog-title>Order Statistics</h2>
<mat-dialog-content class="mat-typography" style="width: 80vh">
  @if (orderStats.processed) {
  <div>
    Orders Fulfilled {{ orderStats.numSuccessfulOrders }} <br />
    Orders Canceled {{ orderStats.numCanceledOrders }} <br />
    Orders Failed {{ orderStats.numFailedOrders }} <br />
    Orders Rejected {{ orderStats.numRejectedOrders }} <br />
    Average Order Completion {{ orderStats.averageOrderCompletion | date :
    'mm:ss' }} <br />
    First Order {{ orderStats.firstOrder}} <br />
    Last Order {{ orderStats.lastOrder}} <br />
    <br />
    Rejections by reason: <br />
    <ul>
      @for (rejectionByReason of orderStats.numRejectedOrdersByReason; track
      rejectionByReason) {
      <li>{{ rejectionByReason.reason }}: {{ rejectionByReason.numOrders }}</li>
      }
    </ul>
    <br />
    Orders per Robot: <br />
    <ul>
      @for (ordersPerRobot of orderStats.ordersPerRobot; track ordersPerRobot) {
      <li>
        Robot {{ ordersPerRobot.robotName }} : {{
        ordersPerRobot.numSuccessfulOrders }}
      </li>
      }
    </ul>
    <br />

    Orders per Hour:
    <ul>
      @for (ordersPerHour of orderStats.ordersPerHour; track ordersPerHour) {
      <li>
        Hour Start {{ ordersPerHour.hour }} : {{
        ordersPerHour.numSuccessfulOrders }}
      </li>
      }
    </ul>
    <br />
    Orders per Pickup Display Name:
    <ul>
      @for (order of orderStats.ordersPerPickupDisplayName; track order) {
      <li>{{ order.name }} : {{ order.numSuccessfulOrders }}</li>
      }
    </ul>
    <br />
    Orders per Dropoff Display Name:
    <ul>
      @for (order of orderStats.ordersPerDropoffDisplayName; track order) {
      <li>{{ order.name }} : {{ order.numSuccessfulOrders }}</li>
      }
    </ul>

    <br />
    Orders per Pickup Location Id:
    <ul>
      @for (order of orderStats.ordersPerPickupLocationId; track order) {
      <li>{{ order.name }} : {{ order.numSuccessfulOrders }}</li>
      }
    </ul>
    <br />
    Orders per Dropoff Location Id:
    <ul>
      @for (order of orderStats.ordersPerDropoffLocationId; track order) {
      <li>{{ order.name }} : {{ order.numSuccessfulOrders }}</li>
      }
    </ul>
  </div>
  } @else { Loading Data... }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
</mat-dialog-actions>
