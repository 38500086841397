<placeholder
  class="robot-slot"
  (click)="toggleSlotState()"
  [iconName]="iconName"
  [text]="
    isEnabled
      ? 'Looking for a robot to supervise'
      : 'Supervision slot is disabled'
  "
  [class.robot-placeholder-is-disabled]="!isEnabled"
/>
