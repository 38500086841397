import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  firstValueFrom,
  identity,
  merge,
} from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { RobotCommunication } from '../core/robots-service/robot-communication';
import { RobotsService } from '../core/robots-service/robots.service';
import { EnableExtraSlotsService } from './enable-extra-slots.service';
import {
  RobotSlotsConfig,
  SupervisionSettingsService,
} from './supervision-settings/supervision-settings.service';
import {
  applyRobotSlotsConfig,
  disabledSlot,
  RobotSlotCell,
  RobotSlots,
} from './supervision-slot';
import { UserSessionService } from '../core/user-session/user-session.service';
import { UserSessionEventTrackingService } from '../core/user-session/user-session-event-tracking.service';
import { UserSessionSystemEventName } from '../core/user-session/user-session-system-events';
import {
  NewRobotConnectionRequest,
  updateRobotSlotState,
} from './supervision-slot-utils';
import { arrayDifference } from '../../utils/array-difference';
import { hasAtLeastOneElement } from '@/utils/typeGuards';

const FAILED_SUBSEQUENT_CONNECTION_ATTEMPTS_COUNT_THRESHOLD = 2;
const REQUEST_ROBOT_ACTION_TYPE_ID = 'robot-connection-failed';

@Injectable()
export class SupervisedRobotSlotsConfigService implements OnDestroy {
  private readonly userSessionService = inject(UserSessionService);
  private readonly _destroy = new Subject<void>();

  private _robotSlots$ = new BehaviorSubject<RobotSlots>([
    disabledSlot,
    disabledSlot,
    disabledSlot,
    disabledSlot,
  ]);

  robotSlots$: Observable<RobotSlots> = this._robotSlots$.asObservable();

  private desiredRobotIds$ = new BehaviorSubject<string[]>([]);
  private assignedRobots$ = this.userSessionService.assignedRobots$.pipe(
    filter(() => this.isPollingForRobots),
  );
  private undesiredRobotIds$ = this.userSessionService.assignedRobots$.pipe(
    filter(() => !this.isPollingForRobots),
  );
  private allRobotIds$ = merge(this.desiredRobotIds$, this.assignedRobots$);

  constructor(
    private readonly supervisionSettingsService: SupervisionSettingsService,
    private readonly robotService: RobotsService,
    private readonly enableExtraSlotsService: EnableExtraSlotsService,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    combineLatest([this.undesiredRobotIds$, this.desiredRobotIds$])
      .pipe(takeUntil(this._destroy))
      .subscribe(async ([undesiredRobotIds, desiredRobotIds]) => {
        const [robotIdsToUnassign] = arrayDifference(
          desiredRobotIds,
          undesiredRobotIds,
        );
        if (robotIdsToUnassign.length > 0) {
          this.userSessionService.unassignRobots(robotIdsToUnassign);
        }
      });

    const robotCommunications$ = this.allRobotIds$.pipe(
      switchMap(async (robotIds) => {
        if (hasAtLeastOneElement(robotIds)) {
          return await this.robotService.getRobotCommunications(robotIds);
        }
        return [];
      }),
      shareReplay(1),
    );

    combineLatest([
      this.supervisionSettingsService.robotSlots$,
      robotCommunications$,
    ]).subscribe(async ([robotSlotsConfig, robotCommunications]) => {
      await this.updateRobotSlots(robotSlotsConfig, robotCommunications);
    });

    this._robotSlots$.pipe(takeUntil(this._destroy)).subscribe((robotSlots) => {
      this.enableExtraSlotsService.updateRobotSlots(robotSlots);
    });
  }

  private async updateRobotSlots(
    robotSlotsConfig: RobotSlotsConfig,
    robotCommunications: RobotCommunication[],
  ) {
    const robotSlots = applyRobotSlotsConfig(
      this._robotSlots$.getValue(),
      robotSlotsConfig,
    );
    const robotUpdateState = updateRobotSlotState(
      robotSlots,
      robotCommunications,
    );

    const robotSlotsWithNewRobots = this.addNewRobots(
      robotCommunications,
      robotUpdateState.newRobotIds,
      robotSlots,
    );

    this.userSessionService.acknowledgeRobots(
      robotCommunications.map(({ robotId }) => robotId),
    );
    this.reportEstablishedConnection(robotSlotsWithNewRobots);

    this._robotSlots$.next(robotSlotsWithNewRobots);

    this.finalizeAllStaleRobots(robotSlotsWithNewRobots as RobotSlots);
    if (
      robotUpdateState.spilloverRobotIds.length > 0 ||
      robotUpdateState.unexpectedlyUnassignedRobotIds.length > 0
    ) {
      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.INCONSISTENT_ASSIGNMENT_STATE,
        {
          spilloverRobotIds: robotUpdateState.spilloverRobotIds,
          unexpectedlyUnassignedRobotIds:
            robotUpdateState.unexpectedlyUnassignedRobotIds,
        },
      );
    }
  }

  private reportEstablishedConnection(slots: RobotSlots) {
    for (const slot of slots) {
      if (slot.slotType === 'taken') {
        const robotCommunication = slot.robotCommunication;

        firstValueFrom(
          robotCommunication.connected$.pipe(filter(identity)),
        ).then(
          () => {
            this.userSessionService.robotConnectionEstablished(
              robotCommunication.robotId,
            );
            robotCommunication.removeRequestRobotAction(
              REQUEST_ROBOT_ACTION_TYPE_ID,
            );
          },
          (error) => {
            if (
              'message' in error &&
              error.message === 'no elements in sequence'
            ) {
              // expected error nothing to do
              return;
            }
            console.error(
              `Failed to establish connection with robot ${robotCommunication.robotId}`,
              error,
            );
          },
        );
      }
    }
  }

  private addNewRobots(
    robotCommunications: RobotCommunication[],
    robotConnectionRequest: NewRobotConnectionRequest[],
    robotSlots: RobotSlots,
  ): RobotSlots {
    const updatedRobotSlots = [...robotSlots];

    for (const {
      robotId,
      slotIndex: emptySlotIndex,
    } of robotConnectionRequest) {
      const robotCommunication = robotCommunications.find(
        (robotCommunication) => robotCommunication.robotId === robotId,
      );

      if (!robotCommunication) {
        break;
      }

      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.ROBOT_ASSIGNED,
        { robotId: robotCommunication.robotId },
      );
      this.subscribeRobotCommunicationFinalizationHandler(robotCommunication);
      const newRobotSlot: RobotSlotCell = {
        slotType: 'taken',
        robotCommunication: robotCommunication,
      };

      updatedRobotSlots[emptySlotIndex] = newRobotSlot;
    }
    return updatedRobotSlots as RobotSlots;
  }

  async ngOnDestroy(): Promise<void> {
    this._destroy.next(undefined);
    await this.stopSupervision();
  }

  private isPollingForRobots = false;

  startSupervision() {
    this.isPollingForRobots = true;
  }

  async stopSupervision(...keepRobotCommunications: RobotCommunication[]) {
    this.isPollingForRobots = false;

    const finalizedRobotIds = await this.robotService.finalizeRobots(
      ...keepRobotCommunications,
    );
    this.userSessionService.unassignRobots(finalizedRobotIds);
    this.desiredRobotIds$.next(
      keepRobotCommunications.map(({ robotId }) => robotId),
    );
  }

  subscribeRobotCommunicationFinalizationHandler(
    robotCommunication: RobotCommunication,
  ) {
    robotCommunication.finalized$.subscribe(async () => {
      this.userSessionEventTrackingService.trackSystemEvent(
        UserSessionSystemEventName.ROBOT_FINALIZED,
        { robotId: robotCommunication.robotId },
      );
      const currentRobotIds = await firstValueFrom(this.allRobotIds$);
      const robotIdsWithoutFinalized = currentRobotIds.filter(
        (robotId) => robotId !== robotCommunication.robotId,
      );

      this.desiredRobotIds$.next(robotIdsWithoutFinalized);
    });
    robotCommunication.failedSubsequentConnectionAttemptsCount$
      .pipe(takeUntil(this._destroy))
      .subscribe(async (failedSubsequentConnectionAttemptsCount) => {
        if (
          failedSubsequentConnectionAttemptsCount >=
          FAILED_SUBSEQUENT_CONNECTION_ATTEMPTS_COUNT_THRESHOLD
        ) {
          await this.completeReconnectAndRemount(robotCommunication);

          const [newRobotCommunication] =
            await this.robotService.getRobotCommunications([
              robotCommunication.robotId,
            ]);

          newRobotCommunication.requestRobotAction({
            actionIdType: REQUEST_ROBOT_ACTION_TYPE_ID,
            actionDescription:
              'Robot connection failed, attempts to reconnect.',
            actionButton: 'Skip robot',
            onClick: async () => {
              this.userSessionService.unassignRobots([
                robotCommunication.robotId,
              ]);
              newRobotCommunication.finalize();
            },
          });
        }
      });
  }

  private async completeReconnectAndRemount(
    robotCommunication: RobotCommunication,
  ) {
    // polling should be temporarily stopped
    // otherwise race with robot assignment could happen
    // and the slot would be taken by newly assigned robot
    this.isPollingForRobots = false;

    await robotCommunication.finalize();

    this.desiredRobotIds$.next([
      ...this.desiredRobotIds$.getValue(),
      robotCommunication.robotId,
    ]);
    this.isPollingForRobots = true;
  }

  private finalizeAllStaleRobots(robotSlots: RobotSlots) {
    const robotCommunications = robotSlots.flatMap((slot) =>
      slot.slotType === 'taken' ? [slot.robotCommunication] : [],
    );
    this.robotService.finalizeRobots(...robotCommunications);
  }
}
