@if (template$ | async; as template) {
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    (keydown)="$event.stopPropagation()"
  >
    <h1 class="robot-issue-header" mat-dialog-title>
      Submit an issue to Cartken
    </h1>
    <div class="robot-issue-form" mat-dialog-content>
      <mat-form-field appearance="fill" class="robot-issue-field">
        <mat-label>Problem</mat-label>
        <input matInput type="text" formControlName="name" required />
      </mat-form-field>

      @if (fields$ | async; as fields) {
        @for (field of fields; track field; let index = $index) {
          <mat-form-field class="robot-issue-field" appearance="fill">
            <mat-label>{{ field.name }}</mat-label>

            @switch (field.type) {
              @case ('short_text') {
                <input
                  matInput
                  (change)="
                    onCustomFieldChange(
                      index,
                      field.id,
                      $any($event.target).value
                    )
                  "
                  type="text"
                  required
                />
              }

              @case ('drop_down') {
                <mat-select
                  (selectionChange)="
                    onCustomFieldChange(index, field.id, $event.value)
                  "
                  required
                >
                  @for (option of field.options; track option) {
                    <mat-option [value]="option.id">
                      {{ option.name }}
                    </mat-option>
                  }
                </mat-select>
              }
            }
          </mat-form-field>
        }
      } @else {
        <section class="loader">
          <mat-spinner />
          <span>Loading...</span>
        </section>
      }

      <mat-form-field appearance="fill" class="robot-issue-field">
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </div>

    <div class="robot-issue-actions" mat-dialog-actions>
      @if (data.skippable) {
        <button mat-button mat-dialog-close>Skip</button>
      }
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        type="submit"
      >
        Submit
      </button>
    </div>
  </form>
} @else {
  <section class="loader">
    <mat-spinner />
    <span>Loading...</span>
  </section>
}
