@if (template$ | async; as template) {
  @if (data.type === 'cart') {
    <h1 mat-dialog-title>Create a Robot</h1>
  }
  @if (data.type === 'virtual') {
    <h1 mat-dialog-title>Create a Virtual Robot</h1>
  }

  <form class="robot-creation-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <main mat-dialog-content>
      <mat-form-field apperance="fill" class="robot-creation-field">
        <mat-label>Serial Number</mat-label>
        @if (data.type === 'cart') {
          <input
            matInput
            formControlName="serialNumber"
            type="number"
            [min]="template.serialNumber"
          />
        }
        @if (data.type === 'virtual') {
          <input
            matInput
            formControlName="serialNumber"
            type="number"
            [max]="template.serialNumber"
          />
        }
      </mat-form-field>

      <mat-form-field apperance="fill" class="robot-creation-field">
        <mat-label>Password</mat-label>
        <input matInput type="text" formControlName="password" />
      </mat-form-field>
    </main>

    <footer class="robot-creation-actions" mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="form.invalid"
        type="submit"
      >
        Create
      </button>
    </footer>
  </form>
} @else {
  <section class="loader">
    <mat-spinner />
    <span>Loading...</span>
  </section>
}
