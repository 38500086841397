<div class="order-details-container">
  <div class="header">
    <h2 class="title" mat-dialog-title>Order Details</h2>

    <div class="action-buttons">
      @if (assignedRobotId && (canEditOrderStatus$ | async)) {
        <button
          mat-fab
          matTooltip="Open robot elastic log"
          color="primary"
          (click)="openElasticRobotLog()"
        >
          <mat-icon>list</mat-icon>
        </button>

        <button
          mat-fab
          matTooltip="Open robot blackbox"
          color="primary"
          (click)="openBlackbox()"
        >
          <mat-icon>movie</mat-icon>
        </button>
      }

      @if (canEditOrderStatus$ | async) {
        <button
          mat-fab
          matTooltip="Edit Order State"
          color="primary"
          (click)="updateOrder()"
        >
          <mat-icon>edit_note</mat-icon>
        </button>
      }
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <pre> {{ prettyJson }} </pre>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
  </mat-dialog-actions>
</div>
