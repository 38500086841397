<div
  style="
    position: relative;
    left: 0;
    top: 0;
    min-height: 200px;
    background-color: #dddddd;
  "
  id="zone_joystick"
></div>
