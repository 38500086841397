import { Component, Input } from '@angular/core';
import { RobotCommunication } from '../../../core/robots-service/robot-communication';
import { RobotControlComponent } from '../robot-control/robot-control.component';
import { AsyncPipe } from '@angular/common';
import { RobotControlPanelComponent } from '../../../core/robot-control-panel/robot-control-panel.component';
import { MatAnchor } from '@angular/material/button';
import { RobotIssuesPanelComponent } from '../../../core/robot-issues/robot-issues-panel/robot-issues-panel.component';

export const ENABLE_AUTONOMY_FOR = 7; // seconds, might get manually configurable

@Component({
  selector: 'robot-operator-control',
  templateUrl: './robot-operator-control.component.html',
  styleUrls: ['./robot-operator-control.component.sass'],
  standalone: true,
  imports: [
    RobotControlComponent,
    RobotControlPanelComponent,
    MatAnchor,
    RobotIssuesPanelComponent,
    AsyncPipe,
  ],
})
export class RobotOperatorControlComponent {
  @Input()
  robotCommunication!: RobotCommunication;

  @Input()
  active = false;
}
