import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DataItem, ItemType } from '../data-viewer/data-viewer.service';
import { MlDataService, PAGE_SIZE } from '../ml-data.service';
import { CollectionDto } from '../ml-data-types';
import { DataGridComponent } from '../shared/data-grid.component';

@Component({
  selector: 'app-collection-grid',
  templateUrl: './collection-grid.component.html',
  styleUrls: ['./collection-grid.component.sass'],
  standalone: true,
  imports: [DataGridComponent],
})
export class CollectionGridComponent implements OnChanges {
  private nextPageIndex = 0;
  canFetchMore = true;

  @Input()
  items: DataItem[] = [];

  @Input()
  collection?: CollectionDto;

  @Input()
  selectedItemId?: number;

  @Output()
  itemClick = new EventEmitter<DataItem>();

  constructor(private readonly mlDataService: MlDataService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const collectionChange = changes['collection'];
    if (
      collectionChange &&
      (collectionChange.firstChange ||
        collectionChange.currentValue !== collectionChange.previousValue)
    ) {
      this.nextPageIndex = 0;
      this.canFetchMore = true;
      this.items = [];
      this.nextPage();
    }
  }

  async nextPage() {
    const items = await this.getItems(this.nextPageIndex);
    this.nextPageIndex++;
    this.items = this.items.concat(
      items.map((item) => ({ ...item, itemType: ItemType.FRAME })),
    );

    if (items.length < PAGE_SIZE) {
      this.canFetchMore = false;
    }
  }

  async getItems(pageNumber: number) {
    return this.collection?.id
      ? this.mlDataService.getCollectionsFrames(this.collection.id, pageNumber)
      : [];
  }
}
