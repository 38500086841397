<div class="grid-img-container">
  <img
    class="img-placeholder"
    [class.is-selected-img-item]="isSelected"
    [src]="url"
  />
  @if (itemType === 'frame' && isPicked !== undefined) {
    <app-pick-icon
      (click)="pickClicked.emit(); $event.stopPropagation()"
      [isPicked]="isPicked"
      class="grid-img-picked"
    />
  }
  @if (itemType === 'frame') {
    <div class="item-type-marker">F</div>
  }
  @if (itemType === 'snippet') {
    <div class="item-type-marker">S</div>
  }
</div>
