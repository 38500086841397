import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataItem, ItemType } from '../data-viewer/data-viewer.service';

import { PickIconComponent } from './pick-icon.component';

@Component({
  selector: 'app-grid-img-item',
  templateUrl: './grid-img-item.component.html',
  styleUrls: ['./grid-img-item.component.sass'],
  standalone: true,
  imports: [PickIconComponent],
})
export class GridImgItemComponent {
  url: string | undefined;

  @Input()
  set item(item: DataItem) {
    if ('url' in item) {
      this.url = item.url;
    } else {
      this.url = item.tileUrl;
    }
    this.isPicked = item.isPicked;
    this.itemType = item.itemType;
  }

  isPicked?: boolean;
  itemType?: ItemType;

  @Input()
  isSelected: boolean = false;

  @Output()
  pickClicked = new EventEmitter();
}
