import { Component, Input } from '@angular/core';
import { CollectionDto, FrameDto } from '../ml-data-types';
import { CollectionGridComponent } from './collection-grid.component';
import { CollectionSelectionComponent } from './collection-selection.component';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.sass'],
  standalone: true,
  imports: [CollectionGridComponent, CollectionSelectionComponent],
})
export class CollectionComponent {
  @Input()
  collection!: CollectionDto;

  selectedFrame?: FrameDto;

  selectFrame(frame: FrameDto): void {
    if (this.selectedFrame === frame) {
      this.selectedFrame = undefined;
      return;
    }
    this.selectedFrame = frame;
  }
}
