import { Component, Input, OnInit } from '@angular/core';
import { RobotDto } from '../../core/robots-service/backend/robot.dto';
import {
  MapElement,
  RobotQueueEdge,
  RobotQueueEdgeProperties,
} from '@cartken/map-types';
import { MatChipSet, MatChipOption } from '@angular/material/chips';

import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';

interface WaitingQueueSummary {
  name: string;
  id: number;
  slots: number;
  robotsArrived: string[];
  robotsComing: string[];
  active: boolean;
}

export enum Selections {
  None = '',
  Storage = 'storageSelection',
  AvailableQueues = 'availableQueuesSelection',
  EnabledQueues = 'enabledQueuesSelection',
  TotalSlots = 'totalSlotsSelection',
  OccupiedSlots = 'occupiedSlotsSelection',
}

@Component({
  selector: 'app-waiting-queue-management',
  templateUrl: './waiting-queue-management.component.html',
  styleUrls: ['./waiting-queue-management.component.sass'],
  standalone: true,
  imports: [
    MatChipSet,
    MatChipOption,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
  ],
})
export class WaitingQueueManagementComponent implements OnInit {
  waitingQueueSummaries: WaitingQueueSummary[] = [];

  queueDisplayAvailableColumns = [
    'queue-name',
    'queue-slots',
    'occupied-slots',
    'robots-coming',
    'robots-arrived',
  ];
  displayedWaitingQueueColumns: string[] = [];
  waitingQueueSummariesDataSource: WaitingQueueSummary[] = [];

  currentRobots: RobotDto[] = [];
  totalSlots = 0;
  numQueuesActive = 0;
  numQueuesDisabled = 0;
  slotsOccupied = 0;
  loadFactor = 0.0;
  robotsReturningToStorage: string[] = [];
  robotsIdleAtStorage: string[] = [];
  availableWaitingQueuesMapElements: RobotQueueEdge[] = [];
  activeWaitingQueues: string[] = [];
  activeStorageMapElement: RobotQueueEdge | undefined;

  selectedChip = Selections.None;

  @Input()
  set robots(robots: RobotDto[]) {
    this.currentRobots = robots.filter((r) => r.readyForOrders);
    this.updateSummary();
  }

  @Input()
  set storageMapElement(storageMapElement: RobotQueueEdge | undefined) {
    this.activeStorageMapElement = storageMapElement;
    this.updateSummary();
  }

  @Input()
  set availableWaitingQueues(availableWaitingQueues: RobotQueueEdge[]) {
    this.availableWaitingQueuesMapElements = availableWaitingQueues;
    this.updateSummary();
  }

  @Input()
  set activeWaitingQueueNames(activeWaitingQueueNames: string[]) {
    this.activeWaitingQueues = activeWaitingQueueNames;
    this.updateSummary();
  }

  constructor() {}

  ngOnInit(): void {}

  toggleSelection(selection: string) {
    const newlySelectedOption = selection as Selections;
    if (newlySelectedOption === this.selectedChip) {
      this.selectedChip = Selections.None;
    } else {
      this.selectedChip = newlySelectedOption;
    }
    this.updateSummaryToDisplay();
  }

  private updateRobots() {
    this.waitingQueueSummaries.forEach((s) => {
      (s.robotsComing = []), (s.robotsArrived = []);
    });
    this.robotsIdleAtStorage = [];
    this.robotsReturningToStorage = [];

    for (const robot of this.currentRobots) {
      const stop = robot.scheduledStops?.[0];
      if (!stop) {
        continue;
      }
      const waitingQueueForStop = this.waitingQueueSummaries.find(
        (summary) => summary.id === stop.robotQueueId,
      );
      const robotName = robot.shortName ?? 'Unknown';
      if (waitingQueueForStop) {
        if (robot.arrivedAtStop) {
          waitingQueueForStop.robotsArrived.push(robotName);
        } else {
          waitingQueueForStop.robotsComing.push(robotName);
        }
        continue;
      }
      if (!this.activeStorageMapElement) {
        continue;
      }
      if (this.activeStorageMapElement?.id === stop.robotQueueId) {
        if (robot.arrivedAtStop) {
          this.robotsIdleAtStorage.push(robotName);
        } else {
          this.robotsReturningToStorage.push(robotName);
        }
      }
    }
  }

  private updateSummary() {
    this.waitingQueueSummaries = this.availableWaitingQueuesMapElements.map(
      (waitingQueue) => {
        const properties = waitingQueue.properties;
        const waitingQueueName = properties?.names?.at(0) ?? '';
        return {
          name: properties?.names?.at(0) ?? '',
          id: waitingQueue.id,
          slots: properties?.queueSlotPriorities?.length ?? 0,
          robotsArrived: [],
          robotsComing: [],
          active: this.activeWaitingQueues.includes(waitingQueueName),
        } as WaitingQueueSummary;
      },
    );
    this.updateRobots();
    const activeQueues = this.waitingQueueSummaries.filter((s) => s.active);
    this.totalSlots = activeQueues.reduce(
      (acc, current) => acc + current.slots,
      0,
    );
    this.slotsOccupied = activeQueues.reduce(
      (acc, currrent) =>
        acc + currrent.robotsComing.length + currrent.robotsArrived.length,
      0,
    );
    this.loadFactor = this.slotsOccupied / this.totalSlots;
    this.numQueuesActive = activeQueues.length;
    this.numQueuesDisabled =
      this.waitingQueueSummaries.length - this.numQueuesActive;
    this.waitingQueueSummaries.sort((a, b) => b.slots - a.slots);
    this.updateSummaryToDisplay();
  }

  private updateSummaryToDisplay() {
    switch (this.selectedChip) {
      case Selections.AvailableQueues:
        this.waitingQueueSummariesDataSource =
          this.waitingQueueSummaries.filter((s) => !s.active);
        this.displayedWaitingQueueColumns = ['queue-name', 'queue-slots'];
        break;

      case Selections.EnabledQueues:
        this.waitingQueueSummariesDataSource =
          this.waitingQueueSummaries.filter((s) => s.active);

        this.displayedWaitingQueueColumns = ['queue-name', 'queue-slots'];
        break;

      case Selections.TotalSlots:
        this.waitingQueueSummariesDataSource =
          this.waitingQueueSummaries.filter((s) => s.active);
        this.displayedWaitingQueueColumns = [
          'queue-name',
          'queue-slots',
          'occupied-slots',
        ];
        break;

      case Selections.OccupiedSlots:
        this.waitingQueueSummariesDataSource =
          this.waitingQueueSummaries.filter(
            (s) =>
              s.active && s.robotsComing.length + s.robotsArrived.length > 0,
          );
        this.displayedWaitingQueueColumns = [
          'queue-name',
          'occupied-slots',
          'robots-coming',
          'robots-arrived',
        ];
        break;

      case Selections.Storage:
        if (!this.activeStorageMapElement) {
          break;
        }
        const name =
          this.storageMapElement?.properties?.names?.at(0) ?? 'Unknown';
        this.waitingQueueSummariesDataSource = [
          {
            name,
            robotsArrived: this.robotsIdleAtStorage,
            robotsComing: this.robotsReturningToStorage,
            slots: 0,
            id: this.storageMapElement?.id ?? 0,
            active: true,
          },
        ];
        this.displayedWaitingQueueColumns = [
          'queue-name',
          'robots-coming',
          'robots-arrived',
        ];
        break;
      default:
        this.waitingQueueSummariesDataSource = [];
        break;
    }
  }
}
