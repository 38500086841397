import { Component, Inject } from '@angular/core';
import { BackendService } from '../../../core/backend.service';
import { RobotDto } from '../../../core/robots-service/backend/robot.dto';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
} from '@angular/material/dialog';
import { QuickAddItem } from '../../../../app/core/quick-add-remove-dialog/quick-add-remove-dialog.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { QuickAddRemoveDialogComponent } from '../../../core/quick-add-remove-dialog/quick-add-remove-dialog.component';

const MINUTE_IN_MILLIS = 1000 * 60;

export function isOnline(nowMillis: number) {
  return (robot: RobotDto) => {
    const updateTimestamp = new Date(robot?.updatedAt ?? '1970-1-1').getTime();
    return (
      nowMillis - updateTimestamp < MINUTE_IN_MILLIS && !robot.isVirtualRobot
    );
  };
}

export function filterOnlineRobots(robots: RobotDto[]) {
  const now = Date.now();
  return robots.filter(isOnline(now));
}

export type RobotQuickAddDialogData = {
  selectedRobotIds: string[];
};

@Component({
  selector: 'app-robot-quick-add-dialog',
  templateUrl: './robot-quick-add-dialog.component.html',
  styleUrls: ['./robot-quick-add-dialog.component.sass'],
  standalone: true,
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatProgressSpinner,
    QuickAddRemoveDialogComponent,
    AsyncPipe,
  ],
})
export class RobotQuickAddDialogComponent {
  robots: Observable<QuickAddItem<string>[]> = of([]);
  isLoaded = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: RobotQuickAddDialogData,
    private backendService: BackendService,
    public dialogRef: MatDialogRef<void, string[]>,
  ) {
    const selectedRobots = new Set(data.selectedRobotIds);
    this.robots = this.backendService.get<RobotDto[]>(`/robots/`).pipe(
      map(filterOnlineRobots),
      tap(() => {
        this.isLoaded = true;
      }),
      map((robots) =>
        robots.map(
          (robot): QuickAddItem<string> => ({
            itemName: `Cart ${robot.serialNumber} | ${robot.shortName} - ${robot.assignedOperationId}`,
            isSelected: selectedRobots.has(robot.id),
            payload: robot.id,
          }),
        ),
      ),
    );
  }

  close() {
    this.dialogRef.close();
  }

  addSelectedOperations(robots: QuickAddItem<string>[]) {
    this.dialogRef.close(
      robots.filter((robot) => robot.isSelected).map((robot) => robot.payload),
    );
  }
}
