import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { BackendService } from '../../../core/backend.service';
import {
  AwxJobTemplate,
  InterfaceStrategy,
} from '../mass-action-dialog.component';
import { DatePipe } from '@angular/common';
import {
  MatCard,
  MatCardHeader,
  MatCardTitle,
  MatCardContent,
  MatCardActions,
} from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor } from '@angular/material/button';

export type RobotUpdateHistoryDialogData = {
  robotId: string;
};

export enum AwxJobStatus {
  PENDING = 'Pending',
  WAITING = 'Waiting',
  RUNNING = 'Running',
  FAILED = 'Failed',
  ERROR = 'Error',
  SUCCESSFUL = 'Successful',
  CANCELED = 'Canceled',
  LAUNCHED = 'Launched',
  NOT_LAUNCHED = 'NotLaunched',
  LAUNCH_FAILED = 'LaunchFailed',
}

interface AwxJobTrackerDto {
  robotId: string;
  interfaceStrategy?: InterfaceStrategy;
  awxJobTemplate?: AwxJobTemplate;
  versionTag?: string;
  awxJobId?: number;
  awxTarget?: string;
  createdAt?: Date;
  finishedAt?: Date;
  status?: AwxJobStatus;
  latestLaunchAt?: Date;
  icon?: string;
}

function iconForAwxJobStatus(
  awxJobStatus: AwxJobStatus,
): 'question_mark' | 'download_done' | 'error' | 'downloading' {
  switch (awxJobStatus) {
    case AwxJobStatus.SUCCESSFUL:
      return 'download_done';
    case AwxJobStatus.FAILED:
    case AwxJobStatus.CANCELED:
    case AwxJobStatus.LAUNCH_FAILED:
    case AwxJobStatus.ERROR:
      return 'error';

    case AwxJobStatus.PENDING:
    case AwxJobStatus.LAUNCHED:
    case AwxJobStatus.RUNNING:
      return 'downloading';
  }
  return 'question_mark';
}

@Component({
  selector: 'app-robot-update-history-dialog',
  templateUrl: './robot-update-history-dialog.component.html',
  styleUrls: ['./robot-update-history-dialog.component.sass'],
  standalone: true,
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardContent,
    MatIcon,
    MatCardActions,
    MatAnchor,
    DatePipe,
  ],
})
export class RobotUpdateHistoryDialogComponent {
  robotId: string;
  errorService: any;
  history: AwxJobTrackerDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: RobotUpdateHistoryDialogData,
    public dialogRef: MatDialogRef<void, void>,
    private readonly backendService: BackendService,
  ) {
    this.robotId = data.robotId;
    this.queryLastAwxUpdateJobs();
  }

  openAwxJobLink(awxJobTracker: AwxJobTrackerDto) {
    window.open(
      'https://infrastructure.cartken.com/#/jobs/playbook/' +
        (awxJobTracker.awxJobId ?? ''),
    );
  }

  private async queryLastAwxUpdateJobs(): Promise<void> {
    this.history = await lastValueFrom(
      this.backendService.get(`/awx/${this.robotId}/history`),
    );
    this.history.forEach(
      (jobTracker) =>
        (jobTracker.icon = iconForAwxJobStatus(
          jobTracker.status ?? AwxJobStatus.NOT_LAUNCHED,
        )),
    );
  }
}
