import { Component, Input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
  MatExpansionPanelDescription,
} from '@angular/material/expansion';

export interface MassActionProgressReport {
  successCount: number;
  failureCount: number;
  totalCount: number;
  errorMessages: string[];
}

@Component({
  selector: 'app-mass-action-progress-bar',
  templateUrl: './mass-action-progress-bar.component.html',
  styleUrls: ['./mass-action-progress-bar.component.sass'],
  standalone: true,
  imports: [
    MatProgressBar,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
  ],
})
export class MassActionProgressBarComponent {
  @Input() progressReport: MassActionProgressReport | undefined;
}
