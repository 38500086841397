<h1 mat-dialog-title>Load Changeset</h1>
<div mat-dialog-content>
  <mat-selection-list #changesets [multiple]="false">
    @for (changeset of data.changesets; track changeset.id) {
      <mat-list-option [value]="changeset.id" lines="3">
        <span matListItemTitle>{{ changeset.title }}</span>
        <span style="font-size: small">{{ changeset.description }}</span>
      </mat-list-option>
    }
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="undefined">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="changesets.selectedOptions.selected[0]?.value"
    [disabled]="!changesets.selectedOptions.hasValue()"
  >
    Ok
  </button>
</div>
