<div class="scroll-container-when-mobile">
  <mat-form-field appearance="fill" class="filter-dropdown">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter()"
      [(ngModel)]="textFilter"
      placeholder="Example: 10"
    />
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Charging</mat-label>
    <mat-select [(value)]="chargingFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      <mat-option value="charging">Charging</mat-option>
      <mat-option value="not-charging">Not Charging</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Availability</mat-label>
    <mat-select [(value)]="onlineFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      <mat-option value="online">Online</mat-option>
      <mat-option value="offline">Offline</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Ready</mat-label>
    <mat-select [(value)]="readyFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      <mat-option value="ready">Ready</mat-option>
      <mat-option value="not-ready">Not Ready</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Driving</mat-label>
    <mat-select [(value)]="drivingFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      <mat-option value="arrived">Arrived</mat-option>
      <mat-option value="driving">Driving</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Updating</mat-label>
    <mat-select [(value)]="updatingFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      <mat-option value="updating">Updating</mat-option>
      <mat-option value="not-updating">Not Updating</mat-option>
    </mat-select>
  </mat-form-field>

  <selection-dropbox
    label="Operation"
    class="wide-filter-dropdown"
    [options]="operationIds"
    [(selected)]="operationIdFilter"
    (selectedChange)="applyFilter()"
  />

  <selection-dropbox
    label="Access Group"
    class="wide-filter-dropdown"
    [options]="accessGroups"
    [(selected)]="accessGroupFilter"
    (selectedChange)="applyFilter()"
  />

  <mat-form-field appearance="fill" [ngClass]="'filter-dropdown'">
    <mat-label>Severity</mat-label>
    <mat-select [(value)]="severityFilter" (selectionChange)="applyFilter()">
      <mat-option [value]="undefined"> - </mat-option>
      @for (severity of severityOptions; track severity) {
        <mat-option [value]="severity">{{ severity }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
