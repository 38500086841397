<div class="dialog-continaer">
  <p class="dialog-description-text">
    {{ message }}
  </p>
  <mini-map
    [isCustomGpsClickEnabled]="false"
    [isDirectionsClickEnabled]="false"
    class="map"
    [robotCommunication]="data.robotCommunication"
  />
  <div class="buttons">
    <button mat-raised-button (click)="onCancelClick()">Cancel</button>
    <button mat-raised-button (click)="onConfirmClick()">Confirm</button>
  </div>
</div>
