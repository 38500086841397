function getTimezoneShiftMs(timeZone: string | undefined) {
  const now = new Date();
  const shifted = new Date(now.toLocaleString('ISO', { timeZone }));
  return shifted.getTime() - now.getTime();
}

/**
 * Reinterprets the datetime as if it was in a different timezone.
 */
export function locateDateInTimezone(date: Date, timeZone: string | undefined) {
  const shiftMs = getTimezoneShiftMs(timeZone);
  return new Date(date.getTime() - shiftMs);
}
