import { Component } from '@angular/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDialogContent } from '@angular/material/dialog';

type ShortcutTableRow = {
  shortcuts: string[];
  description: string;
};

@Component({
  selector: 'app-shortcut-dialog',
  templateUrl: './shortcut-dialog.component.html',
  styleUrls: ['./shortcut-dialog.component.sass'],
  standalone: true,
  imports: [CdkScrollable, MatDialogContent],
})
export class ShortcutDialogComponent {
  shortcutsTableData: ShortcutTableRow[] = [
    {
      shortcuts: ['1'],
      description: 'Switch to default video channel',
    },
    {
      shortcuts: ['2'],
      description: 'Switch to surround video channel',
    },
    {
      shortcuts: ['3'],
      description: 'Switch to reverse video channel',
    },
    {
      shortcuts: ['A', 'P'],
      description: 'Enable/Disable autonomy',
    },
    {
      shortcuts: ['D'],
      description: 'Trigger data collection',
    },
    {
      shortcuts: ['R'],
      description: 'Enable microphone to speak through robot speaker',
    },
    {
      shortcuts: ['H', 'I'],
      description: 'Toggle hazard light on/off',
    },
    {
      shortcuts: ['O'],
      description: 'Override emergency stop',
    },
    {
      shortcuts: ['S'],
      description: 'Trigger snapshot',
    },
    {
      shortcuts: ['Z'],
      description: 'Zoom video stream at mouse location',
    },
    {
      shortcuts: ['Enter'],
      description: 'Confirm route corridor',
    },
    {
      shortcuts: ['Backspace'],
      description: 'Revoke route corridor confirmation',
    },
    {
      shortcuts: ['Space'],
      description: 'Emergency brake',
    },
    {
      shortcuts: ['V'],
      description: 'Enable microphone to speak through robot speaker',
    },
  ];

  constructor() {}
}
