@if (operationId$ | async) {
  <div class="main-container">
    <mat-toolbar>
      <span>{{ operationDisplayName$ | async }}</span>
      <div>
        @if (canCreateOrders$ | async) {
          <button
            mat-raised-button
            (click)="openCreateOrderDialog()"
            class="header-buttons"
            [disabled]="!readyForOrders"
          >
            @if (readyForOrders) {
              <span>Add Order</span>
            }
            @if (!readyForOrders) {
              <span>No Active Robot</span>
            }
          </button>
        }
      </div>
      <div>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            [routerLink]="'handovers'"
            queryParamsHandling="preserve"
          >
            <mat-icon>access_time</mat-icon>
            <span>Locations</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="grow-container-area">
      <as-split direction="vertical">
        <as-split-area [size]="60" [minSize]="30">
          <div class="horizontal-container">
            <app-robot-order-map
              class="map-container"
              [robots]="robots"
              [orders]="orders"
              [operation]="operation$ | async"
              [focusItemId]="focusItemId"
              [highlightItemId]="highlightItemId"
              (zoomPanChanged)="onZoomPanChange($event)"
            />

            <div class="robot-table">
              <div class="operation-robot-overview">
                {{ robotsReady }} / {{ robotsCount }} available
              </div>
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Filter</mat-label>
                <mat-select
                  [(value)]="robotFilter"
                  (selectionChange)="onRobotFilterChanged()"
                >
                  @if (robotFilter === 'robots-with-order') {
                    <mat-select-trigger>
                      Only <mat-icon>shopping_basket</mat-icon>
                    </mat-select-trigger>
                  }
                  @if (robotFilter === 'robots-without-order') {
                    <mat-select-trigger>
                      Exclude <mat-icon>shopping_basket</mat-icon>
                    </mat-select-trigger>
                  }
                  <mat-option value=""> - </mat-option>

                  <mat-option value="robots-with-order">
                    Only <mat-icon>shopping_basket</mat-icon></mat-option
                  >
                  <mat-option value="robots-without-order"
                    >Exclude <mat-icon>shopping_basket</mat-icon></mat-option
                  >
                </mat-select>
              </mat-form-field>
              <table>
                <tbody>
                  @for (
                    robotInfo of filteredRobotInfos;
                    track robotInfo.robotId
                  ) {
                    <tr
                      (mouseenter)="showRobotRoute(robotInfo.robotId)"
                      (mouseleave)="hideRobotRoute()"
                    >
                      <div
                        class="robot-status-container"
                        (click)="zoomToRobot(robotInfo.robotId)"
                      >
                        <div
                          class="robot-img-name"
                          [class.inactive]="!robotInfo.robotReadyForOrders"
                        >
                          <img
                            src="assets/robot.png"
                            width="30"
                            style="margin-right: 5px"
                          />
                          <span class="robot-name">
                            {{ robotInfo.name }}
                          </span>
                        </div>

                        <div class="battery-status-section">
                          <app-battery-status
                            [batteryPercentage]="robotInfo.batteryPercentage"
                            [charging]="robotInfo.isCharging"
                          />
                        </div>

                        @if (canOpenCloseRobots$ | async) {
                          <button
                            mat-mini-fab
                            (click)="openRobotCompartmentDialog(robotInfo)"
                            [class.inactive]="!robotInfo.isOnline"
                          >
                            <mat-icon>{{
                              robotInfo.lidStatusIconName
                            }}</mat-icon>
                          </button>
                        }
                        <div class="robot-status-section">
                          @if (robotInfo.hasOrders) {
                            <mat-icon>shopping_basket</mat-icon>
                          }
                        </div>
                        @if (
                          robotInfo.isVirtualRobot &&
                          robotInfo.robotReadyForOrders
                        ) {
                          <button
                            mat-mini-fab
                            (click)="
                              completeCurrentHandovers(robotInfo);
                              $event.stopPropagation()
                            "
                            [disabled]="!robotInfo.isWaitingForHandover"
                          >
                            <mat-icon>redo</mat-icon>
                          </button>
                        }
                      </div>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </as-split-area>
        <as-split-area [size]="40" [minSize]="5">
          <div>
            <div class="filter">
              <mat-form-field appearance="fill">
                <mat-label>Text Search</mat-label>
                <input
                  matInput
                  #textSearchInput
                  placeholder='"Some Street" -ExcludeThis'
                  value=""
                />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>Order Status</mat-label>
                <mat-select
                  [(value)]="orderStatusFilter"
                  (selectionChange)="onOrderStatusFilterChanged()"
                >
                  <mat-option value="active">Active</mat-option>
                  <mat-option value="final">Final</mat-option>
                  @for (status of orderStatuses; track status) {
                    <mat-option [value]="status">
                      {{ status }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <table
              mat-table
              matSort
              [dataSource]="orderQueue"
              [trackBy]="trackByRowId"
            >
              <ng-container matColumnDef="robot">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Robot</th>
                <td mat-cell *matCellDef="let row">
                  @if (row) {
                    <div class="robot-img-name">
                      <img
                        src="assets/robot.png"
                        width="30"
                        style="margin-right: 5px"
                      />

                      <span class="robot-name">
                        {{ row.robot }}
                      </span>
                    </div>
                  }
                </td>
              </ng-container>
              <ng-container matColumnDef="created">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Order Created
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.created | prettyTime }}
                </td>
              </ng-container>

              <ng-container matColumnDef="testOrder">
                <th mat-header-cell *matHeaderCellDef>Test Order</th>
                <td mat-cell *matCellDef="let row">
                  @if (row.testOrder) {
                    <mat-icon>build</mat-icon>
                  }
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  ID / External ID
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="compartment">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Compartment
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.order.assignedCompartmentId }}
                </td>
              </ng-container>

              <ng-container matColumnDef="communication">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Communication
                </th>
                <td mat-cell *matCellDef="let row" class="communication-icons">
                  @if (row.order.hasDeliveredMessages) {
                    <mat-icon class="material-icons-outlined">chat</mat-icon>
                  }
                  @if (row.order.hasPendingMessages) {
                    <mat-icon class="material-icons-outlined">sms</mat-icon>
                  }
                  @if (row.order.hasMessageSendFailures) {
                    <mat-icon class="material-icons-outlined"
                      >sms_failed</mat-icon
                    >
                  }
                  @if (row.order.hasInboundMessages) {
                    <mat-icon class="material-icons-outlined">comment</mat-icon>
                  }

                  @if (row.order.hasCompletedPhoneCall) {
                    <mat-icon class="material-icons-outlined">call</mat-icon>
                  }

                  @if (row.order.hasNotCompletedPhoneCall) {
                    <mat-icon class="material-icons-outlined"
                      >call_quality</mat-icon
                    >
                  }

                  @if (row.order.hasCompletedPhoneCall) {
                    <mat-icon class="material-icons-outlined">call</mat-icon>
                  }

                  @if (row.order.hasSentEmail) {
                    <mat-icon class="material-icons-outlined">send</mat-icon>
                  }

                  @if (row.order.hasFailedEmail) {
                    <mat-icon class="material-icons-outlined"
                      >cancel_schedule_send</mat-icon
                    >
                  }
                  @if (row.order.communicationLog.length) {
                    <communication-log-overlay
                      class="communication-overlay"
                      [order]="row.order"
                    >
                    </communication-log-overlay>
                  }
                </td>
              </ng-container>

              <ng-container matColumnDef="displayName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Display Name
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.order.displayName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="pickup">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Pickup
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.pickup }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dropoff">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Dropoff
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.dropoff }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                  Order Status
                </th>
                <td mat-cell *matCellDef="let row">
                  {{ row.status }}
                </td>
              </ng-container>

              <ng-container matColumnDef="icons">
                <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <div class="icons">
                    <button
                      [disabled]="!row.compartmentCanBeOpenend"
                      mat-icon-button
                      matTooltip="Open compartment"
                      (click)="
                        $event.stopPropagation();
                        openCompartmentForOrder(row.id)
                      "
                    >
                      <mat-icon>lock_open</mat-icon>
                    </button>
                    @if (canEditOrderStatus$ | async) {
                      <button
                        mat-icon-button
                        matTooltip="Edit Order State"
                        (click)="$event.stopPropagation(); editOrder(row)"
                      >
                        <mat-icon>edit_note</mat-icon>
                      </button>
                    }
                    <button
                      mat-icon-button
                      (click)="
                        $event.stopPropagation();
                        onOpenTrackingLink(row.order.trackingCode)
                      "
                    >
                      <mat-icon>exit_to_app</mat-icon>
                    </button>
                    <span style="padding-right: 5px"></span>
                    @if (canCancelOrders$ | async) {
                      <button
                        mat-icon-button
                        aria-label="Cancel Order"
                        (click)="$event.stopPropagation(); cancelOrder(row)"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    }
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [class.order-table-grey]="selectedPickupHandoverAlias"
                [class.order-table-red]="row.actionRequired"
                class="element-row"
                (click)="showOrderDetails(row)"
                (mouseenter)="showOrderOnMap(row.order.id)"
                (mouseleave)="hideOrderOnMap()"
              ></tr>
            </table>
            <mat-paginator
              #paginator
              [length]="resultsLength"
              [pageSizeOptions]="pageSizeOptions"
              aria-label="Select page"
            >
            </mat-paginator>
          </div>
        </as-split-area>
      </as-split>
    </div>
  </div>
}
