<div class="outer-container">
  <div class="inner-container">
    <div class="component-header-title">Collections</div>
    <div class="left-side-bar-collections">
      @for (collection of collections; track collection) {
        <div
          (click)="setCollection(collection)"
          class="collection-item"
          [class.selected-collection]="selectedCollection?.id === collection.id"
        >
          {{ collection.name }}
          @if (collection.locked) {
            <mat-icon>lock</mat-icon>
          }
        </div>
      }
      @if (addCollectionModeOn) {
        <app-collection-name-input
          style="display: contents"
          (closeInput)="addCollectionModeOn = false"
          (createCollection)="createCollection($event)"
        />
      } @else {
        <button mat-icon-button (click)="addCollectionModeOn = true">
          <mat-icon>add</mat-icon>
        </button>
      }
    </div>
  </div>
</div>
