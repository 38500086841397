@if (notifications.length > 0) {
  <div class="snoozable-container">
    <div class="snoozable-countdown-overlay">
      <div class="message">
        @if (notifications?.length > 1) {
          <div class="notification-count">
            <mat-icon
              (click)="changeActiveNotificationIndex(-1)"
              class="change-active-index-arrow"
              [class.change-active-index-disabled]="
                activeNotificationIndex === 0
              "
              >chevron_left</mat-icon
            >
            {{ this.activeNotificationIndex + 1 }}
            <mat-icon
              (click)="changeActiveNotificationIndex(1)"
              class="change-active-index-arrow"
              [class.change-active-index-disabled]="
                activeNotificationIndex === notifications.length - 1
              "
              >chevron_right</mat-icon
            >
          </div>
        }

        {{ notifications?.[activeNotificationIndex]?.actionDescription }}
        @if (notifications?.[activeNotificationIndex]?.countdown) {
          <span>
            {{ notifications?.[activeNotificationIndex]?.countdown | duration }}
          </span>
        }
      </div>
      <button mat-button color="primary" (click)="onClick()">
        {{ notifications?.[activeNotificationIndex]?.actionButton }}
      </button>
    </div>
  </div>
}
