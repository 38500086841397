<div class="main-container">
  <div class="controls-container">
    <div
      class="robot-and-overlay"
      (mouseenter)="onMouseIn()"
      (mouseleave)="onMouseOut()"
      [class.full-screen]="showFullScreenPreview$ | async"
      [class.needs-attention]="requireAttention$ | async"
    >
      <robot-control
        [isKeyboardEnabled]="isMouseHovering"
        [robotCommunication]="robotCommunication"
        [activeControl]="!(showFullScreenPreview$ | async)"
        [highResolution]="true"
        (needsAttention)="
          handleAttentionRequest('crossing-confirmation', $event)
        "
      />
    </div>

    <div class="controls">
      <app-robot-control-panel
        class="controls-panel"
        [robotCommunication]="robotCommunication"
        [isClaimControlEnabled]="false"
        [isManualControlEnabled]="false"
        [autonomyControlType]="'PARKING_MODE'"
        [hazardLightControlType]="'HAZARD_LIGHT_TOGGLE'"
        [isNavigationToMaintenanceEnabled]="false"
        [isCurbClimbingControlEnabled]="false"
        [isReadyForOrderControlEnabled]="false"
        [isIssueReportingEnabled]="false"
        [active]="isMouseHovering"
        [isRelocalizeEnabled]="false"
        [isCalibrateEndstopEnabled]="false"
        [isNetworkInterfaceControlEnabled]="true"
        [isBlockageReportEnabled]="false"
        [isHandlingSnapshotHotkeyEnabled]="isMouseHovering"
      >
      </app-robot-control-panel>
      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="emitFocusChange()"
        [matTooltip]="'Focus on the robot'"
      >
        <mat-icon>zoom_in_map</mat-icon>
      </button>

      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="skipRobot()"
        [matTooltip]="'Skip robot'"
      >
        <mat-icon>arrow_forward</mat-icon>
      </button>

      <button
        mat-mini-fab
        color="primary"
        style="margin-left: 16px"
        (click)="clickRemoveRobot()"
        [matTooltip]="'Remove robot slot'"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
