import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Observable, Subscribable } from 'rxjs';

@Pipe({
  name: 'async',
  pure: false,
  standalone: true,
})
export class CAsyncPipe implements OnDestroy, PipeTransform {
  private asyncPipe: AsyncPipe;
  constructor(ref: ChangeDetectorRef) {
    this.asyncPipe = new AsyncPipe(ref);
  }

  transform<T>(obj: null | undefined): undefined;
  transform<T>(
    obj: Observable<T> | Subscribable<T> | Promise<T>,
  ): T | undefined;
  transform<T>(
    obj: Observable<T> | Subscribable<T> | Promise<T> | undefined,
  ): T | undefined;
  transform(obj: any) {
    return this.asyncPipe.transform(obj) ?? undefined;
  }

  ngOnDestroy(): void {
    this.asyncPipe.ngOnDestroy();
  }
}
