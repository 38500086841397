<section class="mini-map-container">
  @if (!hasPrecisePosition && shouldShowPrecisePositionWarning) {
    <section
      class="mini-map-missing-data"
      [class.outdated]="useBackendFallbackPosition"
    >
      <div>
        Use outdated robot position, please report the issue if this persists!
      </div>
      @if (!useBackendFallbackPosition) {
        <button mat-raised-button color="primary" (click)="showLastPosition()">
          <mat-icon>pin_drop</mat-icon>
          <span>Show me the last known location!</span>
        </button>
      }
    </section>
  }
  <leaflet-map
    (onDoubleClick)="resetFollow(); $event.stopPropagation()"
    (onPanning)="onPanning()"
    (onMapReady)="onMapReady($event)"
    [shouldShowMapProviderSelection]="shouldShowMapProviderSelection"
    [shouldShowZoomControl]="shouldShowZoomControl"
    [refreshTrigger]="refreshTrigger"
  />
  <aside class="mini-map-actions">
    <section>
      @if (isDirectionsClickEnabled) {
        <button
          class="mini-map-action"
          matTooltip="Check directions, and update positions"
          matTooltipPosition="before"
          (click)="openDirectionsDialog()"
        >
          <mat-icon>directions</mat-icon>
        </button>
      }
      @if (isCustomGpsClickEnabled) {
        <button
          class="mini-map-action"
          matTooltip="Input custom GPS coordinates"
          matTooltipPosition="before"
          (click)="openCustomGpsDialog()"
        >
          <mat-icon>share_location</mat-icon>
        </button>
      }
    </section>
    <div class="flex-grow"></div>
    @if (shouldUIButtonsBeEnables) {
      <section>
        <button
          #rotationBtn
          class="mini-map-action"
          [matTooltip]="
            shouldRotate
              ? 'Rotate towards north instead of the robot'
              : 'Rotate with the robot'
          "
          matTooltipPosition="before"
          (click)="toggleRotation()"
        >
          @if (shouldRotate) {
            <mat-icon class="mini-map-rotation-icon">navigation</mat-icon>
          } @else {
            <mat-icon>rotate_left</mat-icon>
          }
        </button>
        <button
          #locationBtn
          class="mini-map-action"
          [matTooltip]="
            shouldFollow ? 'Stop following the robot' : 'Follow the robot'
          "
          matTooltipPosition="before"
          (click)="toggleFollow()"
        >
          @if (undefined === shouldFollow) {
            <mat-icon>location_disabled</mat-icon>
          } @else {
            @if (shouldFollow) {
              <mat-icon>my_location</mat-icon>
            } @else {
              <mat-icon>location_searching</mat-icon>
            }
          }
        </button>
      </section>
    }
  </aside>
</section>
