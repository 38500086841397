@if (user.viewName === undefined) {
  <div
    class="mat-elevation-z8 ops-overview-with-bottom-chip round-bottom"
    [class.absent-clocked-in-operator]="!!user.clockedInAt"
  >
    <div class="ops-overview">
      <div class="overview-title">
        @if (user.pictureUrl) {
          <img class="profile-img" src="{{ user.pictureUrl }}" />
        }
        {{ user.displayName }}

        <button
          mat-icon-button
          color="primary"
          aria-label="Access robot blackbox aggregation"
          style="pointer-events: auto"
          (click)="openBlackboxAggregation()"
        >
          <mat-icon>movie</mat-icon>
        </button>
      </div>
      @if (user.latencyMillis) {
        <div class="update-time">
          Since {{ user.since | prettyTime }}
          <hr class="splitter-line" />
        </div>
      }
      @if (!user.latencyMillis && user.since !== undefined) {
        <div class="update-time">
          Last seen {{ user.since | prettyTime }}
          <hr class="splitter-line" />
        </div>
      }
      <div class="robot-grid">
        @for (robot of user.robots; track $index) {
          <div class="robot-item">
            <img class="profile-img" src="{{ robot.pictureUrl }}" />
            Cart {{ robot.displayName }}
          </div>
        }
      </div>
    </div>
  </div>
} @else {
  <div
    class="mat-elevation-z8 round-bottom ops-overview-with-bottom-chip"
    [class.absent-clocked-in-operator]="
      !!user.clockedInAt && (user.viewName === 'Idle' || !user.latencyMillis)
    "
  >
    <div class="ops-overview">
      <div class="user-title-container">
        <div
          class="overview-title"
          [routerLink]="robotUrlString"
          [class.user-with-robots]="robotUrlString !== undefined"
        >
          @if (user.pictureUrl) {
            <img class="profile-img" src="{{ user.pictureUrl }}" />
          }
          {{ user.displayName }}

          <button
            mat-icon-button
            color="primary"
            aria-label="Access robot blackbox aggregation"
            style="pointer-events: auto"
            (click)="openBlackboxAggregation()"
          >
            <mat-icon>movie</mat-icon>
          </button>
        </div>
        <div class="app-version">{{ user.version }}</div>
      </div>
      @if (user.latencyMillis) {
        <div class="update-time">
          Since {{ user.since | prettyTime }}
          <hr class="splitter-line" />
        </div>
      }
      @if (!user.latencyMillis) {
        <div class="update-time">
          Last seen {{ user.since | prettyTime }}

          <hr class="splitter-line" />
        </div>
      }
      <div class="robot-grid">
        @for (robot of user.robots; track $index) {
          <div
            class="robot-item"
            [class.robot-item-selected]="robot.isEnabledSlot"
            [class.robot-id-defined]="robot.robotId !== undefined"
            [routerLink]="'/robots/supervise/' + robot.robotId"
          >
            @if (robot.drivingState) {
              <span
                [class]="
                  'robot-driving-state-icon ' + robot.drivingState.toLowerCase()
                "
              >
                @switch (robot.drivingState) {
                  @case ('PARKING') {
                    <mat-icon matTooltip="Parking and Waiting"
                      >local_parking</mat-icon
                    >
                  }
                  @case ('AUTONOMOUSLY_DRIVING') {
                    <mat-icon matTooltip="Happily driving autonomously ☺️"
                      >navigation</mat-icon
                    >
                  }
                  @case ('MANUALLY_DRIVEN') {
                    <mat-icon matTooltip="Manually driven by an Operator"
                      >sports_esports</mat-icon
                    >
                  }
                  @case ('BLOCKED') {
                    <mat-icon matTooltip="Blocked by someone or something 😟"
                      >pan_tool</mat-icon
                    >
                  }
                  @case ('UNKNOWN') {
                    <mat-icon matTooltip="Something went wrong 😳"
                      >question_mark</mat-icon
                    >
                  }
                }
              </span>
            }
            <span> {{ robot.displayName }} </span>
            @if (user.viewName === 'ManagedSupervision' && robot.robotId) {
              <mat-icon
                class="unassign-robot"
                (click)="
                  unassignRobot(robot.robotId, user.userId);
                  $event.stopPropagation()
                "
                >clear</mat-icon
              >
            }
          </div>
        }
      </div>

      <mat-form-field appearance="fill" class="select-access-groups">
        <mat-label>Select access groups</mat-label>
        <mat-select
          placeholder="Select access groups"
          multiple
          [(ngModel)]="user.selectedAccessGroups"
          (selectionChange)="updatedSelectedAccessGroups()"
        >
          @for (accessGroup of user.accessGroups; track $index) {
            <mat-option [value]="accessGroup">{{ accessGroup }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="user-log-data round-bottom">
      <span class="view-name">
        {{ user.viewName | viewName }}
      </span>

      <span class="indicators">
        @if (user.speedSum !== 0) {
          <span class="indicator-item">
            <mat-icon>speed</mat-icon>
            {{ user.speedSum | number: '1.0-1' }}km/h
          </span>
        }
        @if (user.latencyMillis) {
          <span class="indicator-item">
            <mat-icon>network_ping</mat-icon>
            {{ user.latencyMillis }}ms
          </span>
        }
      </span>
    </div>
  </div>
}
