@if (data.operation && data.operation; as operation) {
  <div penCompartmentOnArrival class="dialog-container">
    <h1>{{ data.operation.id }}</h1>
    <mat-slide-toggle
      [(ngModel)]="operation.deleted"
      (change)="data.updateOperation.deleted = data.operation.deleted"
    >
      Operation Deleted
    </mat-slide-toggle>

    <app-attribute-list-input
      [autocompleteAttributes]="allOperationAccessGroups"
      [(selectedAttributes)]="operation.accessGroups"
      (selectedAttributesChange)="
        data.updateOperation.accessGroups = data.operation.accessGroups
      "
      [label]="'Access Groups'"
      class="access-group-input"
    />

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Display Name</mat-label>
      <input
        matInput
        placeholder="Display Name"
        [(ngModel)]="operation.displayName"
        (change)="data.updateOperation.displayName = operation.displayName"
      />
    </mat-form-field>

    <div class="section">
      <mat-form-field appearance="fill">
        <mat-label>Time Zone</mat-label>
        <mat-select
          [(ngModel)]="operation.timeZone"
          (selectionChange)="data.updateOperation.timeZone = operation.timeZone"
        >
          @for (tz of timeZones; track tz) {
            <mat-option [value]="tz">{{ tz }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Operation Start</mat-label>
        <input
          matInput
          type="time"
          placeholder="Ops start"
          [(ngModel)]="operation.operationStartTime"
          (change)="
            data.updateOperation.operationStartTime =
              operation.operationStartTime
          "
        />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Operation End</mat-label>
        <input
          matInput
          type="time"
          placeholder="Ops end"
          [(ngModel)]="operation.operationEndTime"
          (change)="
            data.updateOperation.operationEndTime = operation.operationEndTime
          "
        />
      </mat-form-field>

      <mat-slide-toggle
        [(ngModel)]="operation.automaticFleetUpdate"
        (change)="
          data.updateOperation.automaticFleetUpdate =
            operation.automaticFleetUpdate
        "
      >
        Automatic Fleet Update
      </mat-slide-toggle>
      <mat-form-field appearance="fill">
        <mat-label>Select Weekdays</mat-label>
        <mat-select
          [(value)]="operation.operationWeekdays"
          multiple
          (selectionChange)="
            data.updateOperation.operationWeekdays = operation.operationWeekdays
          "
        >
          @for (day of availableWeekdays; track day) {
            <mat-option [value]="day">{{ day }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    @if (operation.operationType === 'OrderOperation') {
      <div>
        <ng-template
          *ngTemplateOutlet="
            OrderOperation;
            context: { $implicit: operation.operationData }
          "
        />
      </div>
    }
  </div>
}

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="data.updateOperation">Update</button>
</div>

<ng-template #OrderOperation let-operation>
  <div class="section">
    <mat-form-field appearance="fill">
      <mat-label>Preferred country code shortcut name</mat-label>
      <input
        matInput
        placeholder="e.g. us, gb, de, es etc."
        [(ngModel)]="operation.preferredCountryCodes"
        (change)="updatePreferredCountryCodes(operation.preferredCountryCodes)"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Support Phone Number</mat-label>
      <input
        matInput
        placeholder="Support Phone Number"
        [(ngModel)]="operation.supportPhoneNumber"
        (change)="updateSupportPhoneNumber(operation.supportPhoneNumber)"
      />
    </mat-form-field>
  </div>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Survey Url</mat-label>
    <input
      matInput
      placeholder="Survey Url"
      [(ngModel)]="operation.surveyUrl"
      (change)="updateSurveyUrl(operation.surveyUrl)"
    />
  </mat-form-field>
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Default Webhook Url</mat-label>
    <input
      matInput
      placeholder="Default Webhook Url"
      [(ngModel)]="operation.defaultWebhookUrl"
      (change)="updateDefaultWebhookUrl(operation.defaultWebhookUrl)"
    />
  </mat-form-field>

  <mat-divider style="margin-bottom: 16px" />
  <div>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Assigned Waiting Queues</mat-label>
      <mat-select
        placeholder=" Waiting Queues"
        multiple
        [(ngModel)]="operation.waitingQueues"
        [compareWith]="compareRobotQueueHandovers"
        (selectionChange)="updateQueues()"
      >
        @for (robotQueue of availableRobotQueues; track robotQueue) {
          <mat-option [value]="robotQueue">{{ robotQueue.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Assigned Pickups</mat-label>
      <mat-select
        placeholder=" Pickups"
        multiple
        [(ngModel)]="operation.pickups"
        [compareWith]="compareRobotQueueHandovers"
        (selectionChange)="updateQueues()"
      >
        @for (robotQueue of availableRobotQueues; track robotQueue) {
          <mat-option [value]="robotQueue">
            {{ robotQueue.displayName }} / ({{ robotQueue.name }})
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Assigned Dropoffs</mat-label>
      <mat-select
        placeholder=" Dropoffs"
        multiple
        [(ngModel)]="operation.dropoffs"
        [compareWith]="compareRobotQueueHandovers"
        (selectionChange)="updateQueues()"
      >
        @for (robotQueue of availableRobotQueues; track robotQueue) {
          <mat-option [value]="robotQueue"
            >{{ robotQueue.displayName }} / ({{ robotQueue.name }})
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Storage Location Id</mat-label>
      <mat-select
        placeholder="Storage Location Id"
        [(ngModel)]="operation.storageLocationId"
        (selectionChange)="updateStorageLocationId()"
      >
        <mat-option [value]="''">{{ '-' }}</mat-option>
        @for (robotQueue of availableRobotQueueNames; track robotQueue) {
          <mat-option [value]="robotQueue">{{ robotQueue }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <br />

    <mat-divider style="margin-bottom: 16px" />
    <h1>Waiting Durations Configuration</h1>
    <div class="waiting-durations">
      <div>
        Pickup
        <br />
        <br />
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Maximum waiting duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max Waiting Duration"
            [(ngModel)]="
              operation.defaultPickupWaitingDurations.maxWaitingDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Reminder Duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Reminder Duration"
            [(ngModel)]="
              operation.defaultPickupWaitingDurations.reminderDuration
            "
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Estimated Waiting Duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Estimated Waiting Duration"
            [(ngModel)]="
              operation.defaultPickupWaitingDurations.estimatedWaitingDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Auto Success Duration [s]</mat-label>
          <input
            type="number"
            min="0"
            matInput
            placeholder="Auto Success Duration"
            [(ngModel)]="
              operation.defaultPickupWaitingDurations.autoSuccessDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label> Handover Completion Delay [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Completion Delay"
            [(ngModel)]="operation.pickupHandoverCompletionDelay"
            (change)="
              data.updateOperation.updateOrderOperationData!.pickupHandoverCompletionDelay =
                operation.pickupHandoverCompletionDelay
            "
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label> Max driving seconds to pickups [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max driving seconds"
            [(ngModel)]="operation.maxDrivingSecondsToPickups"
            (change)="
              updateMaxDrivingSecondsToPickups(
                operation.maxDrivingSecondsToPickups
              )
            "
          />
        </mat-form-field>
      </div>
      <div style="margin-left: 30px">
        Dropoff
        <br />
        <br />
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Maximum waiting duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max Waiting Duration"
            [(ngModel)]="
              operation.defaultDropoffWaitingDurations.maxWaitingDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Reminder Duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Reminder Duration"
            [(ngModel)]="
              operation.defaultDropoffWaitingDurations.reminderDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Estimated Waiting Duration [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Estimated Waiting Duration"
            [(ngModel)]="
              operation.defaultDropoffWaitingDurations.estimatedWaitingDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Auto Success Duration [s]</mat-label>
          <input
            type="number"
            min="0"
            matInput
            placeholder="Auto Success Duration"
            [(ngModel)]="
              operation.defaultDropoffWaitingDurations.autoSuccessDuration
            "
            (change)="updateWaitingDurations()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="full-width">
          <mat-label> Handover Completion Delay [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Completion Delay"
            [(ngModel)]="operation.dropoffHandoverCompletionDelay"
            (change)="
              data.updateOperation.updateOrderOperationData!.dropoffHandoverCompletionDelay =
                operation.dropoffHandoverCompletionDelay
            "
          />
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label> Max driving seconds to dropoffs [s]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max driving seconds"
            [(ngModel)]="operation.maxDrivingSecondsToDropoffs"
            (change)="
              updateMaxDrivingSecondsToDropoffs(
                operation.maxDrivingSecondsToDropoffs
              )
            "
          />
        </mat-form-field>
      </div>
    </div>
    <mat-divider style="margin-bottom: 16px" />
    Handover Location Configuration
    <br />
    <br />
    <div class="location-refinement">
      <div>
        Pickup
        <br />
        <br />

        <mat-slide-toggle
          [(ngModel)]="operation.allowCustomPickupLocationsWithinBounds"
          (change)="updateLocationConfiguration()"
        >
          Allow Custom Pickup Locations Within Bounds
        </mat-slide-toggle>
        <br />
        <mat-form-field appearance="fill">
          <mat-label>Maximum refinement distance [m]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max Refinement Distance"
            [(ngModel)]="operation.maxPickupLocationRefinementDistance"
            (change)="updateLocationConfiguration()"
          />
        </mat-form-field>
      </div>
      <div style="margin-left: 30px">
        Dropoff
        <br />
        <br />

        <mat-slide-toggle
          [(ngModel)]="operation.allowCustomDropoffLocationsWithinBounds"
          (change)="updateLocationConfiguration()"
        >
          Allow Custom Dropoff Locations Within Bounds
        </mat-slide-toggle>
        <br />
        <mat-form-field appearance="fill">
          <mat-label>Maximum refinement distance [m]</mat-label>
          <input
            type="number"
            min="1"
            matInput
            placeholder="Max Refinement Distance"
            [(ngModel)]="operation.maxDropoffLocationRefinementDistance"
            (change)="updateLocationConfiguration()"
          />
        </mat-form-field>
      </div>
    </div>
    <br />
    <br />

    <mat-divider style="margin-bottom: 16px" />

    <div class="compartment-strategies">
      <div>
        Pickup Compartment Strategy
        <br />
        <br />
        <ng-template
          *ngTemplateOutlet="
            CompartmentStrategy;
            context: { $implicit: operation.pickupCompartmentStrategy }
          "
        />
      </div>
      <div style="margin-left: 30px">
        Dropoff Compartment Strategy
        <br />
        <br />
        <ng-template
          *ngTemplateOutlet="
            CompartmentStrategy;
            context: { $implicit: operation.dropoffCompartmentStrategy }
          "
        />
      </div>
    </div>
    <br />
  </div>

  <mat-divider style="margin-bottom: 16px" />

  <h1>Text Message Configuration</h1>
  <div class="section">
    Default: <i>is being prepared and will be delivered by robot.</i>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Created text block</mat-label>
      <input
        matInput
        placeholder="Created text block"
        [(ngModel)]="operation.textMessageConfiguration.createdBlock"
        (change)="updateMessageConfiguration()"
      />
    </mat-form-field>
    <br />
    Default:
    <i>Lift the lid, take out your order and enjoy the meal!</i>
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Waiting for dropoff text block</mat-label>
      <input
        matInput
        placeholder="Waiting for dropoff text block"
        [(ngModel)]="operation.textMessageConfiguration.waitingForDropoffBlock"
        (change)="updateMessageConfiguration()"
      />
    </mat-form-field>
  </div>

  <mat-divider style="margin-bottom: 16px" />

  Disposal Options
  <br />
  <br />

  <mat-form-field>
    <mat-label> Dispose Orders</mat-label>
    <mat-select
      [(ngModel)]="operation.disposalOptions.disposalMode"
      (selectionChange)="updateDisposalMode($event.value)"
    >
      @for (disposalMode of disposalModes; track disposalMode) {
        <mat-option [value]="disposalMode">{{ disposalMode }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <br />
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Dispose at location id</mat-label>
    <input
      matInput
      placeholder="empty means at pickup location"
      [ngModel]="operation.disposalOptions.disposeAtLocationId"
      (change)="updateDisposalLocation($event.target.value)"
      [disabled]="operation.disposalOptions.disposalMode === neverDisposeMode"
    />
  </mat-form-field>

  <br />
  <br />

  Order Schedule Mode
  <br />
  <br />

  <mat-form-field>
    <mat-label> Mode</mat-label>
    <mat-select
      [(ngModel)]="operation.orderSchedulerMode"
      (selectionChange)="updateOrderSchedulerMode()"
    >
      @for (
        availableMode of availableOrderSchedulerModes;
        track availableMode
      ) {
        <mat-option [value]="availableMode">{{ availableMode }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-divider style="margin-bottom: 16px" />
  <div>
    Automatically fail order after timeout
    <br />
    <div class="fail-after-timeout">
      <mat-slide-toggle
        [(ngModel)]="operation.pickupAutomaticallyFailOrdersAfterTimeout"
        (change)="
          data.updateOperation.updateOrderOperationData!.pickupAutomaticallyFailOrdersAfterTimeout =
            operation.pickupAutomaticallyFailOrdersAfterTimeout
        "
        >Pickup
      </mat-slide-toggle>

      <mat-slide-toggle
        [(ngModel)]="operation.dropoffAutomaticallyFailOrdersAfterTimeout"
        (change)="
          data.updateOperation.updateOrderOperationData!.dropoffAutomaticallyFailOrdersAfterTimeout =
            operation.dropoffAutomaticallyFailOrdersAfterTimeout
        "
        >Dropoff
      </mat-slide-toggle>
      `
    </div>
  </div>

  <mat-divider style="margin-bottom: 16px" />

  <mat-slide-toggle
    [(ngModel)]="operation.enableAutoScheduler"
    (change)="
      data.updateOperation.updateOrderOperationData!.enableAutoScheduler =
        operation.enableAutoScheduler
    "
  >
    Enable Auto Scheduler
  </mat-slide-toggle>

  <br />
  <br />
  <mat-slide-toggle
    [(ngModel)]="operation.useOrderDetection"
    (change)="
      data.updateOperation.updateOrderOperationData!.useOrderDetection =
        operation.useOrderDetection
    "
  >
    Use Order Detection
  </mat-slide-toggle>

  <br />
  <br />
  <mat-slide-toggle
    [(ngModel)]="operation.reschedulingForbidden"
    (change)="
      data.updateOperation.updateOrderOperationData!.reschedulingForbidden =
        operation.reschedulingForbidden
    "
  >
    Forbid rescheduling of orders
  </mat-slide-toggle>

  <br />
  <br />

  <mat-slide-toggle
    [(ngModel)]="operation.pickupImmediatelyEnabled"
    (change)="
      data.updateOperation.updateOrderOperationData!.pickupImmediatelyEnabled =
        operation.pickupImmediatelyEnabled
    "
  >
    Enable immediate loading
  </mat-slide-toggle>

  <br />
  <br />
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Snap to location in radius [m]</mat-label>
    <input
      type="number"
      min="1"
      matInput
      placeholder="Snapping radius"
      [(ngModel)]="operation.snapToLocationInRadius"
      (change)="
        data.updateOperation.updateOrderOperationData!.snapToLocationInRadius =
          operation.snapToLocationInRadius
      "
    />
  </mat-form-field>
  <br />
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Unsupervised autonomy duration in seconds</mat-label>
    <input
      type="number"
      min="1"
      matInput
      placeholder="Max duration of unsupervised autonomous driving for robot"
      [(ngModel)]="operation.maxUnsupervisedAutonomyDuration"
      (change)="
        data.updateOperation.updateOrderOperationData!.maxUnsupervisedAutonomyDuration =
          operation.maxUnsupervisedAutonomyDuration
      "
    />
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Rejected order warning threshold</mat-label>
    <input
      type="number"
      min="0"
      max="1"
      matInput
      placeholder="Threshold for order rejection warning"
      [(ngModel)]="operation.rejectedOrderWarningThreshold"
      (change)="
        data.updateOperation.updateOrderOperationData!.rejectedOrderWarningThreshold =
          operation.rejectedOrderWarningThreshold
      "
    />
  </mat-form-field>
  <br />
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Deadline for order insertion seconds</mat-label>
    <input
      type="number"
      min="1"
      matInput
      placeholder="Deadline for order insertion seconds"
      [(ngModel)]="operation.freezeInsertionBeforePickup"
      (change)="
        data.updateOperation.updateOrderOperationData!.freezeInsertionBeforePickup =
          operation.freezeInsertionBeforePickup
      "
    />
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label> Autonomy Corridor Alignment Preference</mat-label>
    <mat-select
      [(ngModel)]="operation.corridorAlignmentPreference"
      (selectionChange)="updateCorridorAlignmentPreference()"
    >
      @for (
        availablePreference of availableCorridorAlignmentPreferences;
        track availablePreference
      ) {
        <mat-option [value]="availablePreference">{{
          availablePreference
        }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Return to handover timeout minutes</mat-label>
    <input
      type="number"
      min="0"
      max="60"
      matInput
      placeholder="Timeout to allow return to handover"
      [(ngModel)]="operation.returnToHandoverTimeoutMins"
      (change)="
        data.updateOperation.updateOrderOperationData!.returnToHandoverTimeoutMins =
          operation.returnToHandoverTimeoutMins
      "
    />
  </mat-form-field>
</ng-template>

<ng-template #CompartmentStrategy let-strategy>
  <div style="margin-left: 10px">
    <mat-form-field>
      <mat-label> Pin Code Strategy</mat-label>
      <mat-select
        [(ngModel)]="strategy.pinCodeStrategy"
        (selectionChange)="updateCompartmentStrategies()"
      >
        @for (
          availableStrategy of availablePinCodeStrategies;
          track availableStrategy
        ) {
          <mat-option [value]="availableStrategy">{{
            availableStrategy
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Pin Code</mat-label>
      <input
        matInput
        placeholder="Pin Code"
        [(ngModel)]="strategy.pinCode"
        (change)="updateCompartmentStrategies()"
        [disabled]="strategy.pinCodeStrategy !== 'Fixed'"
      />
    </mat-form-field>
    <mat-slide-toggle
      [(ngModel)]="strategy.openCompartmentOnArrival"
      (change)="updateCompartmentStrategies()"
    >
      Open compartment at handover
    </mat-slide-toggle>
  </div>
</ng-template>
