<div
  class="mat-elevation-z8 robot-card"
  [class.round-bottom]="robot.isBlocked"
  [class.alert-border]="startArrivingAlert()"
>
  <div class="robot-card-body">
    <div class="robot-eta-line">
      <div class="robot-name-box">
        <img
          [src]="robot.pictureUrl || 'assets/model-e-shelf.png'"
          height="40px"
        />

        <span class="name-text">
          {{ robot.displayName }}
        </span>
      </div>
      <div class="eta-text">
        {{ arrivalText }}
      </div>
    </div>
    <div class="orders-and-actions">
      <div class="order-rows">
        @for (order of robot.orders; track order) {
          @if (
            order.assignedCompartmentId || order.compartmentLocked !== undefined
          ) {
            <div class="compartment-box">
              @if (order.assignedCompartmentId) {
                <span class="compartment-text">{{
                  order.assignedCompartmentId
                }}</span>
              }

              @if (order.compartmentLocked === true) {
                <mat-icon class="compartment-icon">lock</mat-icon>
              }

              @if (order.compartmentLocked === false) {
                <mat-icon class="compartment-icon">lock_open</mat-icon>
              }
            </div>
          }

          @if (
            !(
              order.assignedCompartmentId ||
              order.compartmentLocked !== undefined
            )
          ) {
            <div></div>
          }

          <div class="order-status">
            @if (!robot.hasArrived || order.status !== 'WaitingForHandover') {
              <span> Order {{ order.externalId }} is in progress </span>
            }

            @if (robot.hasArrived && order.status === 'WaitingForHandover') {
              <span>
                {{ getHandoverAction(order) }} order {{ order.externalId }}
              </span>
            }
          </div>

          <ul class="handovers-container">
            @for (handover of order.handovers; track handover) {
              @if (handover.locationId !== locationId) {
                <li class="handover-text">
                  {{ handover.displayName }}
                </li>
              }
              @if (handover.locationId === locationId) {
                <li class="handover-text">Here</li>
              }
            }
          </ul>

          <div class="order-actions">
            @if (
              robot.hasArrived &&
              order.compartmentLocked === true &&
              robot.orders.length > 1
            ) {
              <button
                class="action-button"
                mat-button
                aria-label="Unlock/Open"
                [disabled]="order.status !== 'WaitingForHandover'"
                (click)="openCompartment(order.id)"
              >
                <mat-icon>lock_open</mat-icon>
                Unlock
              </button>
            }

            @if (
              robot.hasArrived &&
              order.compartmentLocked === false &&
              robot.orders.length > 1
            ) {
              <button
                class="action-button"
                mat-button
                aria-label="Lock/Close"
                [disabled]="order.status !== 'WaitingForHandover'"
                (click)="closeCompartment(order.id)"
              >
                <mat-icon>lock</mat-icon>
                Lock
              </button>
            }

            @if (robot.hasArrived && robot.orders.length > 1) {
              <button
                class="action-button"
                mat-button
                aria-label="Complete handover"
                [disabled]="
                  order.status !== 'WaitingForHandover' ||
                  !order.compartmentClosed
                "
                (click)="completeHandover(order.id)"
              >
                <mat-icon>done</mat-icon>
                Done
              </button>
            }
            <button
              class="action-button"
              mat-button
              aria-label="Complete all handovers"
              [disabled]="!completeAllEnabled()"
              (click)="cancelOrder(order.id)"
            >
              <mat-icon>delete</mat-icon>
              Cancel
            </button>
          </div>
        }
      </div>
      @if (robot.hasArrived) {
        <button
          class="action-button"
          mat-button
          aria-label="Unlock all compartments"
          (click)="unlockAllCompartments()"
        >
          <mat-icon>lock_open</mat-icon>
          Unlock
        </button>
      }
      @if (robot.hasArrived) {
        <button
          class="action-button"
          mat-button
          aria-label="Complete all handovers"
          [disabled]="!completeAllEnabled()"
          (click)="completeAllHandovers()"
        >
          @if (robot.orders.length > 1) {
            <mat-icon>done_all</mat-icon>
          }
          @if (robot.orders.length <= 1) {
            <mat-icon>done</mat-icon>
          }
          Done
        </button>
      }
    </div>
  </div>

  @if (robot.isBlocked) {
    <div class="blocked-message round-bottom">Blocked</div>
  }
</div>
