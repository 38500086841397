import { Component, Input } from '@angular/core';
import { RobotCommunication } from '../../core/robots-service/robot-communication';

import { PlaceholderComponent } from '../../core/placeholder/placeholder.component';
import { StatusTreeComponent } from '../../robots/robot-operation/status-tree/status-tree.component';

@Component({
  selector: 'optional-status-tree',
  templateUrl: './optional-status-tree.component.html',
  styleUrls: ['./optional-status-tree.component.sass'],
  standalone: true,
  imports: [PlaceholderComponent, StatusTreeComponent],
})
export class OptionalStatusTreeComponent {
  @Input()
  robotCommunication!: RobotCommunication;

  isOpen = false;

  openRobotStatus() {
    this.isOpen = true;
  }
}
