import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RobotCommunication } from '../robots-service/robot-communication';

import { DirectionsDialogComponent } from '../../robots/robot-operation/directions/directions-dialog.component';
import { CustomGpsDialogComponent } from './custom-gps-dialog.component';
import { BackendService } from '../backend.service';

import { FeatureCollection, LineString } from 'geojson';
import { GlobalPose } from '../robots-service/webrtc/types';
import { RouteDto } from '../robots-service/backend/types';
import Leaflet from 'leaflet';
import 'leaflet-providers';
import 'leaflet-rotate';
import { MiniMapStateService } from './mini-map-state.service';
import { RobotState } from '../robots-service/backend/robot.dto';

import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LeafletMapComponent } from '../leaflet-map/leaflet-map.component';
import { MatTooltip } from '@angular/material/tooltip';

const FLAG_ICON = Leaflet.icon({
  iconUrl: 'assets/flag.svg',
  iconSize: [40, 40],
  iconAnchor: [9, 36],
});

const POSITION_ICON = Leaflet.icon({
  iconUrl: 'assets/arrow.svg',
  iconSize: [24, 24],
});

@Component({
  selector: 'mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.sass'],
  standalone: true,
  imports: [MatButton, MatIcon, LeafletMapComponent, MatTooltip],
})
export class MiniMapComponent implements OnChanges, OnDestroy {
  private readonly miniMapStateService = inject(MiniMapStateService);

  @Input()
  robotCommunication?: RobotCommunication;

  @Input()
  shouldRotate = this.miniMapStateService.getShouldRotate();

  @Input()
  shouldFollow: boolean = this.miniMapStateService.getShouldFollow();

  @Input()
  isCustomGpsClickEnabled = true;

  @Input()
  shouldUIButtonsBeEnables = true;

  @Input()
  shouldShowPrecisePositionWarning = true;

  @Output() customGpsDialogOpen: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input()
  isDirectionsClickEnabled = true;

  @Input()
  shouldShowMapProviderSelection = true;

  @Input()
  shouldShowMiniMapActions = true;

  @Input()
  shouldShowZoomControl = true;

  @Input()
  refreshTrigger?: unknown;

  @Output() directionsDialogOpen: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  private readonly onDestroy$ = new Subject<boolean>();
  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly api: BackendService,
  ) {}

  private mapCanvas?: Leaflet.Map;
  private panningDetected = false;

  private robotMarker?: Leaflet.Marker;
  private destinationMarker?: Leaflet.Marker;
  private routeLayer?: Leaflet.GeoJSON;

  private currentPosition?: GlobalPose;
  private currentRoute?: RouteDto;

  public useBackendFallbackPosition = false;
  public hasPrecisePosition = false;

  onMapReady(map: Leaflet.Map) {
    this.mapCanvas = map;
    if (this.robotCommunication) {
      this.updatePositionSubscription();
      this.updateRouteSubscription();
    }
  }

  onPanning() {
    this.panningDetected = true;
  }

  resetFollow() {
    this.panningDetected = false;
    this.currentPosition && this.updatePosition(this.currentPosition);
  }

  addRobotMarker(position: Leaflet.LatLngExpression) {
    this.removeRobotMarker();

    const mapCanvas = this.mapCanvas;

    if (!mapCanvas) {
      return;
    }

    this.robotMarker = Leaflet.marker(position, {
      icon: POSITION_ICON,
      zIndexOffset: 50,
      rotation: 0,
    });

    mapCanvas.addLayer(this.robotMarker);
  }

  removeRobotMarker() {
    this.robotMarker?.remove();
    this.robotMarker = undefined;
  }

  private addDestinationMarker() {
    const mapCanvas = this.mapCanvas;

    if (!mapCanvas) {
      return;
    }

    this.destinationMarker = Leaflet.marker([0, 0], {
      icon: FLAG_ICON,
      zIndexOffset: 25,
    });

    mapCanvas.addLayer(this.destinationMarker);
  }

  removeDestinationMarker() {
    this.destinationMarker?.remove();
    this.destinationMarker = undefined;
  }

  ngOnChanges(): void {
    this._unsubscribe$.next(undefined);

    this.panningDetected = false;
    this.hasPrecisePosition = false;
    this.useBackendFallbackPosition = false;
    this.currentPosition = undefined;
    this.currentRoute = undefined;

    this.removeRobotMarker();
    this.removeDestinationMarker();

    this.updatePositionSubscription();
    this.updateRouteSubscription();
  }

  private updatePositionSubscription() {
    this.robotCommunication?.globalPose$
      .pipe(takeUntil(this.onDestroy$))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((pose) => {
        this.currentPosition = pose;
        this.useBackendFallbackPosition = false;
        this.hasPrecisePosition = true;
        this.updatePosition(pose);
      });
  }

  private updateRouteSubscription() {
    this.robotCommunication?.robotState$
      .pipe(takeUntil(this._unsubscribe$))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((robotState) => {
        this.drawDestination(robotState);
      });

    this.robotCommunication?.robotRoute$
      .pipe(takeUntil(this._unsubscribe$))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((route) => {
        this.currentRoute = route;
        this.drawRoute(route);
      });
  }

  private updatePosition(geo: GlobalPose): void {
    const position = [geo.latitude, geo.longitude] as Leaflet.LatLngExpression;

    this.addRobotMarker(position);

    // We fit the bounds our follow the robot depending on the user's preference,
    // but only if we had not moved the map manually
    if (!this.panningDetected) {
      if (this.shouldFollow) {
        this.mapCanvas?.setView(position);
      } else if (this.routeLayer) {
        const bounds = this.routeLayer.getBounds();
        bounds?.isValid() && this.mapCanvas?.fitBounds(bounds);
      }

      // We rotate the map to match the robot's heading depending on the user's preference
      this.shouldRotate
        ? this.mapCanvas?.setBearing((geo.heading ?? 0) * -1)
        : this.mapCanvas?.setBearing(0);
    }

    this.robotMarker?.setLatLng([geo.latitude, geo.longitude]);
    const newHeading = (geo.heading ?? 0) + (this.mapCanvas?.getBearing() ?? 0);
    const newRotation = newHeading * (Math.PI / 180);

    this.robotMarker?.setRotation(0);
    this.robotMarker?.setRotation(newRotation);
  }

  private drawDestination(robot: RobotState): void {
    const destination = robot.scheduledStops?.[0];
    this.removeDestinationMarker();

    if (!destination) {
      return;
    }

    this.addDestinationMarker();
    this.destinationMarker?.setLatLng([
      destination.latitude,
      destination.longitude,
    ]);
  }

  private drawRoute(route: RouteDto): void {
    if (this.routeLayer) {
      this.mapCanvas?.removeLayer(this.routeLayer);
      this.routeLayer = undefined;
    }

    const mapCanvas = this.mapCanvas;
    if (!mapCanvas) {
      return;
    }

    const geoRoute: FeatureCollection<LineString> = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: { isRoute: true },
          geometry: {
            type: 'LineString',
            coordinates:
              route.geometry?.map((point) => {
                return [point.longitude, point.latitude];
              }) ?? [],
          },
        },
      ],
    };

    this.routeLayer = Leaflet.geoJSON(geoRoute, {
      style: () => ({
        // Use this for the primary color:
        // color: '#51429f',
        color: '#39FF14',
      }),
    });

    mapCanvas.addLayer(this.routeLayer);
  }

  openDirectionsDialog(): void {
    const popup = this.dialog.open(DirectionsDialogComponent, {
      width: '90%',
      data: {
        currentRoute: this.currentRoute,
        currentLocation: this.currentPosition,
      },
    });

    popup.afterOpened().subscribe(() => {
      this.directionsDialogOpen.emit(true);
    });

    popup.afterClosed().subscribe((newRoute?: RouteDto) => {
      this.directionsDialogOpen.emit(false);
      const robotId = this.robotCommunication?.robotId;

      if (!newRoute || !robotId) {
        return;
      }

      this.api
        .post(`/robots/${robotId}/set-current-route`, { route: newRoute })
        .subscribe(() => {
          console.log('route stored to database');
        });
    });
  }

  openCustomGpsDialog(): void {
    const popup = this.dialog.open(CustomGpsDialogComponent);

    popup.afterOpened().subscribe(() => {
      this.customGpsDialogOpen.emit(true);
    });

    popup.afterClosed().subscribe((newLocation) => {
      this.customGpsDialogOpen.emit(false);
      if (!newLocation) {
        return;
      }

      this.robotCommunication?.sendCustomGpsLocation(newLocation);
    });
  }

  toggleFollow(): void {
    this.panningDetected = false;
    this.shouldFollow = !this.shouldFollow;
    this.miniMapStateService.setShouldFollow(this.shouldFollow);
    if (this.currentPosition) {
      this.updatePosition(this.currentPosition);
    }
  }

  toggleRotation(): void {
    this.panningDetected = false;
    this.shouldRotate = !this.shouldRotate;
    this.miniMapStateService.setShouldRotate(this.shouldRotate);
    if (this.currentPosition) {
      this.updatePosition(this.currentPosition);
    }
  }

  async showLastPosition() {
    this.useBackendFallbackPosition = true;
    const robotCommunication = this.robotCommunication;
    if (!robotCommunication) {
      return;
    }

    robotCommunication.robotState$
      .pipe(
        takeUntil(robotCommunication.globalPose$),
        takeUntil(this.onDestroy$),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((robot) => {
        this.currentPosition = {
          ...robot.location,
          heading: robot?.heading ?? 0,
          altitude: 0,
        };

        this.updatePosition(this.currentPosition);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
  }
}
