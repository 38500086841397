import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import Plotly from 'plotly.js-basic-dist-min';
import { RobotCommunication } from '@/app/core/robots-service/robot-communication';

@Component({
  selector: 'app-audio-level-overlay',
  templateUrl: './audio-level-overlay.component.html',
  styleUrls: ['./audio-level-overlay.component.sass'],
  standalone: true,
})
export class AudioLevelOverlayComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('audioLevelChart') audioLevelChart: any;

  @Input()
  robotCommunication?: RobotCommunication;

  private readonly onDestroy$ = new Subject<boolean>();
  private readonly _unsubscribe$ = new Subject<void>();

  private plot?: Plotly.PlotlyHTMLElement;
  trace1: Plotly.Data = {};
  private x: number[] = [];
  private y: number[] = [];

  async ngAfterViewInit() {
    this.updateAudioLevelSubscription();
  }

  ngOnChanges(): void {
    this._unsubscribe$.next(undefined);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
  }

  private async updatePlot(audioData: Float32Array) {
    const lastX = this.x.at(-1) ?? 0;
    this.x.push((lastX as number) + 1);

    const audioEnergy =
      audioData.reduce((acc, current) => acc + current * current, 0) /
      audioData.length;
    this.y.push(
      audioEnergy * 100, // tuning factor
    );
    if (this.x.length > 50) {
      this.x.shift();
      this.y.shift();
    }
    this.trace1 = {
      x: this.x,
      y: this.y,
      type: 'scatter',
      mode: 'none',
      line: { shape: 'spline' },
      fill: 'tozeroy',
      fillcolor: '#aee0d4aa',
    };

    const data: Plotly.Data[] = [this.trace1];
    this.plot = await Plotly.newPlot(
      this.audioLevelChart.nativeElement,
      data,
      {
        showlegend: false,
        xaxis: {
          autorange: true,
          showgrid: false,
          zeroline: false,
          showline: false,
          autotick: true,
          ticks: '',
          showticklabels: false,
        },
        yaxis: {
          range: [0, 1],
          showgrid: false,
          zeroline: false,
          showline: false,
          autotick: true,
          ticks: '',
          showticklabels: false,
        },
        margin: {
          t: 0,
          b: 0,
          l: 0,
          r: 0,
        },
        plot_bgcolor: 'rgba(0,0,0,0)',
        paper_bgcolor: 'rgba(0,0,0,0)',
      },
      { responsive: true, staticPlot: true },
    );
  }

  private updateAudioLevelSubscription() {
    this.robotCommunication?.robotAudioData$
      .pipe(takeUntil(this.onDestroy$))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((audioData: Float32Array) => {
        this.updatePlot(audioData);
      });
  }
}
