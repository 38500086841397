@if (!isStopped && (showCountdown$ | async); as showCountdown) {
  <div class="deadman-overlay-container">
    <div
      [ngStyle]="{ top: positionX + '%', left: positionY + '%' }"
      class="deadman-switch-button"
    >
      @if (smileIcon) {
        <div>{{ smileIcon }}</div>
      }
      Reaction time check {{ showCountdown }}
      <button mat-raised-button color="primary" (click)="stopDeadmanSwitch()">
        Click me
      </button>
    </div>
  </div>
}
