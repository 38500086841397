import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';
import { Operation } from '../../operations/operation';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';

import { MatChip, MatChipListbox } from '@angular/material/chips';

export interface LoadOperationRegionDialogData {
  selectedOperationId?: string;
  operations: Operation[];
  dialogTitle?: string;
}

@Component({
  selector: 'select-operation-dialog',
  styleUrls: ['select-operation-dialog.component.sass'],
  templateUrl: 'select-operation-dialog.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatChip,
    MatChipListbox,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
  ],
})
export class SelectOperationDialogComponent {
  textFilter = '';
  filteredOperations: Operation[] = [];
  columnsToDisplay = ['displayName', 'id'];

  constructor(
    public dialogRef: MatDialogRef<SelectOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadOperationRegionDialogData,
  ) {
    this.applyFilter();
  }

  applyFilter() {
    const filter = this.textFilter.toLowerCase();
    this.filteredOperations = this.data.operations
      .filter(
        (operation) =>
          operation.id.toLowerCase().includes(filter) ||
          operation.displayName?.toLowerCase().includes(filter) ||
          operation.accessGroups?.includes(filter),
      )
      .sort((a, b) => a.id.localeCompare(b.id));
  }

  selectOperation(operation: Operation) {
    this.dialogRef.close(operation);
  }

  selectFirstOperation() {
    const [firstOperation] = this.filteredOperations;
    if (this.textFilter !== '' && firstOperation) {
      this.selectOperation(firstOperation);
    }
  }
}
