<div class="robots-report-dialog">
  @for (
    operationToRobotReport of operationsToRobotReport;
    track operationToRobotReport
  ) {
    <div class="opreation-report mat-elevation-z8">
      Operation id "{{ operationToRobotReport.operationId }}"
      <table>
        <tr>
          <th>Operational</th>
          <th>{{ operationToRobotReport.operational.length }}</th>
        </tr>
        <tr>
          <th>Degraded</th>
          <th>{{ operationToRobotReport.degraded.length }}</th>
        </tr>
        <tr>
          <th>Broken</th>
          <th>{{ operationToRobotReport.broken.length }}</th>
        </tr>
      </table>
    </div>
  }
</div>
