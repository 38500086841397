import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DataItem } from '../data-viewer/data-viewer.service';

import { GridImgItemComponent } from './grid-img-item.component';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.sass'],
  standalone: true,
  imports: [GridImgItemComponent],
})
export class DataGridComponent {
  @Input()
  items: DataItem[] = [];

  @Input()
  selectedItemId?: number;

  @Input()
  canFetchMore: boolean | undefined = true;

  @Output()
  itemClick = new EventEmitter<DataItem>();

  @Output()
  pickItem = new EventEmitter<DataItem>();

  @Output()
  nextPage = new EventEmitter<void>();

  constructor() {}
}
