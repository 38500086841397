import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Operation } from '../../operations/operation';

import { MatIcon } from '@angular/material/icon';

const NO_ITEM_PLACEHOLDER = '-';

export interface LoadOperationRegionDialogData {
  selectedOperationId?: string;
  operations: Operation[];
  dialogTitle?: string;
}

@Component({
  selector: 'selection-dropbox',
  styleUrls: ['selection-dropbox.component.sass'],
  templateUrl: 'selection-dropbox.component.html',
  standalone: true,
  imports: [MatIcon],
})
export class SelectionDropboxComponent {
  @Input()
  label!: string;

  @Input()
  customInput: boolean = false;

  @Input() selected?: string;
  @Output() selectedChange = new EventEmitter<string>();

  private _options: string[] = [];
  filteredOptions: string[] = [];

  searchString = '';

  @Input()
  set options(options: string[] | undefined) {
    this._options = options ?? [];
    this.filterOptions();
  }

  active = false;

  @ViewChild('selectionInput') inputElement?: ElementRef<HTMLInputElement>;

  browseOptions = false;
  constructor() {}

  filterOptions(value?: string) {
    this.searchString = value || '';
    const customOption = this.customInput ? [this.searchString] : [];
    this.filteredOptions = [
      NO_ITEM_PLACEHOLDER,
      ...this._options.filter((option) => {
        return (
          this.searchString.length === 0 ||
          option.toLowerCase().includes(this.searchString.toLowerCase())
        );
      }),
      ...customOption,
    ];
  }

  selectOption(option: string) {
    this.selected = option === NO_ITEM_PLACEHOLDER ? undefined : option;
    this.selectedChange.emit(this.selected);
    this.active = false;
  }

  setActive(active: boolean) {
    this.active = active;
    if (this.active) {
      setTimeout(() => {
        this.inputElement!.nativeElement.focus();
      }, 0);
    }
  }
}
