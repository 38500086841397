<div class="video-container">
  <video
    #videoContainer
    autoplay
    muted
    loop
    (loadeddata)="videoInit()"
    controls="false"
    webkit-playsinline
    playsinline
  >
    <source [src]="videoUrlMp4" type="video/mp4" />
    <source [src]="videoUrlWebm" type="video/webm" />
    {{ fallbackMsg }}
  </video>
  <div class="video-event-catche" (click)="$event.stopPropagation()"></div>
  @if (isPaused) {
    <div class="pause-overlay" (click)="playVideo()">
      <mat-icon class="play-icon">play_circle</mat-icon>
    </div>
  }
</div>
