<app-toolbar>
  <button mat-menu-item [routerLink]="'/robots'">Back to Robots</button>
</app-toolbar>

<br />
<br />

<div class="container">
  <h1>Blackbox of Cart {{ robotSerialNumber }}</h1>
  <h2>Request Recording</h2>
  <div class="container">
    <mat-form-field appearance="fill">
      <mat-label>Time Zone</mat-label>
      <mat-select [(value)]="selectedTimeZone">
        <mat-option />
        @for (timeZone of supportedTimeZones; track timeZone) {
          <mat-option [value]="timeZone">
            {{ timeZone }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field style="margin-left: 10px">
      <mat-label>Start Time</mat-label>
      <input
        matInput
        type="datetime-local"
        step="1"
        [(ngModel)]="blackboxRequestStartTime"
      />
    </mat-form-field>

    <mat-form-field appearance="fill" style="margin-left: 10px">
      <mat-label>Duration Minutes</mat-label>
      <input
        matInput
        [value]="blackboxRequestDurationMinutes"
        (input)="updateDuration($any($event.target).value)"
        type="number"
      />
    </mat-form-field>

    <button mat-button (click)="requestRecording()" style="margin-left: 10px">
      Request recording
    </button>
  </div>

  <br />
  <br />
  <h2 style="display: inline">Recordings</h2>
  <button mat-icon-button (click)="onUpdate()" style="margin-left: 10px">
    <mat-icon>refresh</mat-icon>
  </button>

  <br />
  <br />
  <table border="1" frame="void" rules="rows">
    @for (record of records; track record.recordId) {
      <tr>
        <td style="padding: 10px">
          @if (record.link !== undefined) {
            <video style="max-width: 500px" controls>
              <source
                src="{{ record.link }}"
                type="video/mp4"
                style="display: inline"
              />
              Your browser does not support the video tag.
            </video>
          }
        </td>
        <td style="padding-left: 25px">
          <p class="info-font">
            Start date:
            <app-print-datetime [value]="record.startTime" />
          </p>
          <p class="info-font">
            End date:
            <app-print-datetime [value]="record.endTime" />
          </p>
          <p class="info-font">
            Status:
            {{
              record.status == 'REQUEST_SENT'
                ? 'REQUEST_SENT (please check that the robot is online, if it is, retry after a few minutes.)'
                : record.status
            }}
          </p>
          @if (record.statusDescription) {
            <p class="info-font">Description: {{ record.statusDescription }}</p>
          }
        </td>
      </tr>
    }
  </table>
</div>
