@for (orderDate of orderOverviews; track orderDate) {
  <div class="order-overlay-info">
    <div>
      {{ orderDate.id }} {{ orderDate.created | prettyTime }} => Compartment:
      {{ orderDate.compartmentId }}
    </div>
    <div class="order-overlay-status">
      {{ orderDate.status }}
    </div>
    <div class="order-overlay-handovers">
      <span class="order-overlay-handover-item">
        <div>
          {{ orderDate.pickupName }}
        </div>
        <div>
          {{ orderDate.pickupEta | prettyTime }}
        </div>

        @if (orderDate.pickupRequestedAt) {
          <div>Requested {{ orderDate.pickupRequestedAt | prettyTime }}</div>
        }
      </span>
      ->
      <span class="order-overlay-handover-item">
        <div>
          {{ orderDate.dropoffName }}
        </div>
        <div>
          {{ orderDate.dropoffEta | prettyTime }}
        </div>

        @if (orderDate.dropoffRequestedAt) {
          <div>Requested {{ orderDate.dropoffRequestedAt | prettyTime }}</div>
        }
      </span>
    </div>
  </div>
}
