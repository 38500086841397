@if (previewVideo) {
  <div class="data-viewer-selection-title">Snipper Info</div>
} @else {
  <div class="data-viewer-selection-title">Frame Info</div>
}
<mat-divider />
<app-selected-item
  class="data-viewer-selection-container"
  [selectedItem]="selectedItem"
  [previewImage]="previewImage"
  [previewVideo]="previewVideo"
  [metadataItem]="metadataItem"
  [snippetFrames]="snippetFrames"
  (selectItem)="selectItem($event)"
>
  <button mat-icon-button>
    <mat-icon>lock</mat-icon>
  </button>
  <button mat-icon-button>
    <mat-icon>settings</mat-icon>
  </button>
</app-selected-item>
