/**
 * This function is used to convert Date objects into a
 * seconds-precise string value which works with
 *
 * ```html
 * <input type="datetime-local" step="1" />
 * ```
 *
 * `step="1"` is important. Without it, the input will
 * not show seconds if seconds in the string are `:00`.
 */
export function dateToLocalISOStringWithSeconds(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -5); // removes milliseconds
}

/**
 * This function is used to convert Date objects into a
 * minutes-precise string value which works with
 *
 * ```html
 * <input type="datetime-local" />
 * ```
 */
export function dateToLocalISOStringWithMinutes(date: Date) {
  return dateToLocalISOStringWithSeconds(date).slice(0, -3); // remove seconds;
}
