@if (updateState !== 'updated' || isMinUpdateDuration()) {
  <div class="refresh-overlay">
    Getting latest state...
    <mat-icon class="refresh-icon">refresh</mat-icon>
  </div>
}
<div class="page-grid">
  <mat-toolbar>
    <div class="handover-title">
      {{ handoverDisplayName }}
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="back()">
          <mat-icon>arrow_back</mat-icon>
          <span>Select pickup</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>

  <div class="eta-list">
    <div class="eta-grid">
      @if (waitingForRobotOrderCount > 1) {
        <div class="waiting-for-robot-order-count">
          <strong> {{ waitingForRobotOrderCount }} </strong>orders are waiting
          for robots
        </div>
      }

      @if (waitingForRobotOrderCount === 1) {
        <div class="waiting-for-robot-order-count">
          <strong>1</strong> order is waiting for a robot
        </div>
      }

      <app-handover-location-title-splitter
        class="grid-title"
        title="Arriving Soon"
      />

      @for (robot of arrivingRobots; track robot.displayName) {
        <app-robot-card
          style="display: contents"
          [robot]="robot"
          (triggerRefresh)="this.triggerRefresh()"
          [locationId]="locationId"
        />
      }

      <app-handover-location-title-splitter
        class="grid-title"
        title="Arrived"
      />
      @for (robot of arrivedRobots; track robot.displayName) {
        <app-robot-card
          style="display: contents"
          [robot]="robot"
          (triggerRefresh)="this.triggerRefresh()"
          [locationId]="locationId"
        />
      }

      <app-handover-location-title-splitter
        class="grid-title"
        title="In Progress"
      />
      @for (robot of otherRobots; track robot.displayName) {
        <app-robot-card
          style="display: contents"
          [robot]="robot"
          (triggerRefresh)="this.triggerRefresh()"
          [locationId]="locationId"
        />
      }
    </div>
  </div>

  @if (dropoffs.length > 0 && hasActiveRobot) {
    <div class="big-card">
      <div class="send-robot-text">Send a robot to</div>
      <div class="dropoff-buttons-grid">
        @for (dropoff of dropoffs; track dropoff) {
          <button
            mat-raised-button
            (click)="sendRobot(dropoff)"
            class="dropoff-button"
          >
            {{ dropoff.displayName || dropoff.name }}
          </button>
        }
      </div>
    </div>
  }

  @if (pickups.length > 0 && hasActiveRobot) {
    <div class="big-card">
      <div class="send-robot-text">Request a robot from</div>
      <div class="dropoff-buttons-grid">
        @for (pickup of pickups; track pickup) {
          <button
            mat-raised-button
            (click)="requestRobot(pickup)"
            class="dropoff-button"
          >
            {{ pickup.displayName || pickup.name }}
          </button>
        }
      </div>
    </div>
  }

  @if (!hasActiveRobot) {
    <div class="big-card">
      <div class="send-robot-text">
        There are no active robots in the operation
      </div>
    </div>
  }
</div>
