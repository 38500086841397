@if (isOpen) {
  <status-tree [robotCommunication]="robotCommunication">
    Loading...
  </status-tree>
} @else {
  <placeholder
    class="optional-status-tree-placeholder"
    iconName="troubleshoot"
    text="Click to open the robot status"
    (click)="openRobotStatus()"
  />
}
