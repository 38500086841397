<div>
  <map [mapOptions]="mapOptions" (mapReady)="onMap($event)"></map>
  <div>
    <mat-form-field>
      <input #fromLocation matInput placeholder="From" />
    </mat-form-field>

    <mat-form-field>
      <input #toLocation matInput placeholder="To" />
    </mat-form-field>
  </div>
  <div style="display: inline">
    <div style="display: inline-block; margin-right: 10px">
      @if (this.currentLocation) {
        <button mat-raised-button (click)="onGlobalPoseAsStart()">
          Start Route At Current Position
        </button>
      }
    </div>
    <div style="display: inline-block">
      @if (routeInfo) {
        <span>{{ routeInfo }}</span>
      }
    </div>
  </div>
</div>
