import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.sass'],
  standalone: true,
  imports: [MatIcon],
})
export class PlaceholderComponent {
  @Input()
  iconName!: string;

  @Input()
  text!: string;
}
