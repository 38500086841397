import {
  type UpdateParameters,
  type DefaultProps,
  type Unit,
  type LayerContext,
  WebMercatorViewport,
} from '@deck.gl/core';
import {
  GeoJsonLayer,
  ScatterplotLayer,
  IconLayer,
  TextLayer,
  PathLayer,
} from '@deck.gl/layers';

import { InteractiveLayer, InteractiveLayerProps } from './interactive-layer';
import { InteractiveMode } from './interactive-mode';
import {
  ClickEvent,
  DraggingEvent,
  EditAction,
  ModeProps,
  PointerMoveEvent,
  StartDraggingEvent,
  StopDraggingEvent,
  Color,
  NonInteractiveFeature,
  BasePointerEvent,
} from './types';
import { GeoPoint } from '@cartken/map-types';
import { Matrix4 } from '@math.gl/core';
import { Feature, FeatureCollection } from './geojson-types';
import { SelectAndEditModeConfig } from '../modes/select-and-edit-mode';
import { assumeFeatureAltitudes } from './assume-altitude';

const DEFAULT_LINE_COLOR: Color = [0x0, 0x0, 0x0, 0x99];
const DEFAULT_FILL_COLOR: Color = [0x0, 0x0, 0x0, 0x90];
const DEFAULT_HIGHLIGHT_COLOR: Color = [0x0, 0xff, 0xff, 0xff];
const DEFAULT_SELECTED_LINE_COLOR: Color = [0x0, 0x0, 0x0, 0xff];
const DEFAULT_SELECTED_FILL_COLOR: Color = [0x0, 0x0, 0x90, 0x90];
const DEFAULT_TENTATIVE_LINE_COLOR: Color = [0x90, 0x90, 0x90, 0xff];
const DEFAULT_TENTATIVE_FILL_COLOR: Color = [0x90, 0x90, 0x90, 0x90];
const DEFAULT_EDITING_EXISTING_POINT_COLOR: Color = [0xc0, 0x0, 0x0, 0xff];
const DEFAULT_EDITING_INTERMEDIATE_POINT_COLOR: Color = [0x0, 0x0, 0x0, 0x80];
const DEFAULT_EDITING_SNAP_POINT_COLOR: Color = [0x7c, 0x00, 0xc0, 0xff];
const DEFAULT_EDITING_POINT_OUTLINE_COLOR: Color = [0xff, 0xff, 0xff, 0xff];
const DEFAULT_EDITING_EXISTING_POINT_RADIUS = 5;
const DEFAULT_EDITING_INTERMEDIATE_POINT_RADIUS = 3;
const DEFAULT_EDITING_SNAP_POINT_RADIUS = 7;
const DEFAULT_TOOLTIP_FONT_SIZE = 32;

function guideAccessor(accessor: any) {
  if (!accessor || typeof accessor !== 'function') {
    return accessor;
  }
  return (guideMaybeWrapped: any) => accessor(unwrapGuide(guideMaybeWrapped));
}

// The object handed to us from deck.gl is different depending on the version of deck.gl used, unwrap as necessary
function unwrapGuide(guideMaybeWrapped: any) {
  if (guideMaybeWrapped.__source) {
    return guideMaybeWrapped.__source.object;
  } else if (guideMaybeWrapped.sourceFeature) {
    return guideMaybeWrapped.sourceFeature.feature;
  }
  // It is not wrapped, return as is
  return guideMaybeWrapped;
}

function getEditHandleColor(handle: any) {
  switch (handle.properties.editHandleType) {
    case 'existing':
      return DEFAULT_EDITING_EXISTING_POINT_COLOR;
    case 'snap-source':
      return DEFAULT_EDITING_SNAP_POINT_COLOR;
    case 'intermediate':
    default:
      return DEFAULT_EDITING_INTERMEDIATE_POINT_COLOR;
  }
}

function getEditHandleOutlineColor(handle: any) {
  return DEFAULT_EDITING_POINT_OUTLINE_COLOR;
}

function getEditHandleRadius(handle: any) {
  switch (handle.properties.editHandleType) {
    case 'existing':
      return DEFAULT_EDITING_EXISTING_POINT_RADIUS;
    case 'snap':
      return DEFAULT_EDITING_SNAP_POINT_RADIUS;
    case 'intermediate':
    default:
      return DEFAULT_EDITING_INTERMEDIATE_POINT_RADIUS;
  }
}

export type InteractiveGeojsonLayerProps = InteractiveLayerProps & {
  data: FeatureCollection;
  mode?: InteractiveMode;
  modeConfig?: Record<string, unknown>;
  selectedFeatureIndexes?: number[];
  onEdit?: (editData: EditAction) => void;
  assumeAltitude: (point: GeoPoint) => [number, number, number];

  pickable?: boolean;
  pickingDepth?: number;
  fp64?: boolean;
  filled?: boolean;
  stroked?: boolean;
  lineWidthScale?: number;
  lineWidthMinPixels?: number;
  lineWidthMaxPixels?: number;
  lineWidthUnits?: string;
  lineJointRounded?: boolean;
  lineCapRounded?: boolean;
  lineMiterLimit?: number;
  pointRadiusScale?: number;
  pointRadiusMinPixels?: number;
  pointRadiusMaxPixels?: number;
  pointRadiusUnits?: Unit;
  pointType?: string;
  mapCoordGetter: (
    screenCoords: [number, number],
    context: LayerContext,
  ) => GeoPoint;

  getLineColor?:
    | Color
    | ((feature: any, isSelected: boolean, mode: InteractiveMode) => Color);
  getFillColor?:
    | Color
    | ((feature: any, isSelected: boolean, mode: InteractiveMode) => Color);
  getPointRadius?: number | ((f: any) => number);
  getLineWidth?: number | ((f: any) => number);

  getTentativeLineColor?:
    | Color
    | ((feature: any, isSelected: boolean, mode: InteractiveMode) => Color);
  getTentativeFillColor?:
    | Color
    | ((feature: any, isSelected: boolean, mode: InteractiveMode) => Color);
  getTentativeLineWidth?: number | ((f: any) => number);
  getHighlightColor?: Color | ((feature: any, mode: InteractiveMode) => Color);
  getIcon?: (f: any) => any;
  getIconSize?: number | ((f: any) => number);

  editHandleType?: string;

  editHandlePointRadiusScale?: number;
  editHandlePointOutline?: boolean;
  editHandlePointStrokeWidth?: number;
  editHandlePointRadiusUnits?: string;
  editHandlePointRadiusMinPixels?: number;
  editHandlePointRadiusMaxPixels?: number;
  getEditHandlePointColor?: Color | ((handle: any) => Color);
  getEditHandlePointOutlineColor?: Color | ((handle: any) => Color);
  getEditHandlePointRadius?: number | ((handle: any) => number);

  // icon handles
  editHandleIconAtlas?: any;
  editHandleIconMapping?: any;
  editHandleIconSizeScale?: number;
  editHandleIconSizeUnits?: string;
  getEditHandleIcon?: (handle: any) => string;
  getEditHandleIconSize?: number;
  getEditHandleIconColor?: Color | ((handle: any) => Color);
  getEditHandleIconAngle?: number | ((handle: any) => number);

  // misc
  billboard?: boolean;
};

const defaultProps: DefaultProps<InteractiveGeojsonLayerProps> = {
  // Edit and interaction events
  onEdit: () => {},

  pickable: true,
  pickingDepth: 5,
  fp64: false,
  filled: true,
  stroked: true,
  lineWidthScale: 1,
  lineWidthMinPixels: 1,
  lineWidthMaxPixels: Number.MAX_SAFE_INTEGER,
  lineWidthUnits: 'pixels',
  lineJointRounded: false,
  lineCapRounded: false,
  lineMiterLimit: 4,
  pointRadiusScale: 1,
  pointRadiusMinPixels: 2,
  pointRadiusMaxPixels: Number.MAX_SAFE_INTEGER,
  pointRadiusUnits: 'pixels',
  pointType: 'circle',
  getLineColor: (feature, isSelected, mode) =>
    isSelected ? DEFAULT_SELECTED_LINE_COLOR : DEFAULT_LINE_COLOR,
  getFillColor: (feature, isSelected, mode) =>
    isSelected ? DEFAULT_SELECTED_FILL_COLOR : DEFAULT_FILL_COLOR,
  getPointRadius: (f) =>
    (f && f.properties && f.properties.radius) ||
    (f && f.properties && f.properties.size) ||
    8,
  getLineWidth: (f) => (f && f.properties && f.properties.lineWidth) || 3,
  getIcon: (f) => f.properties?.icon,
  getIconSize: 20,
  getHighlightColor: DEFAULT_HIGHLIGHT_COLOR,
  autoHighlight: true,

  // Tentative feature rendering
  getTentativeLineColor: (f) => DEFAULT_TENTATIVE_LINE_COLOR,
  getTentativeFillColor: (f) => DEFAULT_TENTATIVE_FILL_COLOR,
  getTentativeLineWidth: (f) =>
    (f && f.properties && f.properties.lineWidth) || 3,

  editHandleType: 'point',

  // point handles
  editHandlePointRadiusScale: 1,
  editHandlePointOutline: true,
  editHandlePointStrokeWidth: 2,
  editHandlePointRadiusUnits: 'pixels',
  editHandlePointRadiusMinPixels: 4,
  editHandlePointRadiusMaxPixels: 8,
  getEditHandlePointColor: getEditHandleColor,
  getEditHandlePointOutlineColor: getEditHandleOutlineColor,
  getEditHandlePointRadius: getEditHandleRadius,

  // icon handles
  editHandleIconAtlas: null,
  editHandleIconMapping: null,
  editHandleIconSizeScale: 1,
  editHandleIconSizeUnits: 'pixels',
  getEditHandleIcon: (handle) => handle.properties.editHandleType,
  getEditHandleIconSize: 10,
  getEditHandleIconColor: getEditHandleColor,
  getEditHandleIconAngle: 0,

  // misc
  billboard: true,
};

type InteractiveGeoJsonLayerState = {
  lastHoverEvent: BasePointerEvent;
  mode: InteractiveMode;
};

export class InteractiveGeoJsonLayer extends InteractiveLayer<
  InteractiveGeojsonLayerProps,
  InteractiveGeoJsonLayerState
> {
  static override layerName = 'InteractiveGeoJsonLayer';
  static override defaultProps = defaultProps;

  get dataTransform() {
    return (data: Feature[]) =>
      data.map((f: Feature) =>
        assumeFeatureAltitudes(f, this.props.assumeAltitude),
      );
  }

  // setState: ($Shape<State>) => void;
  renderLayers() {
    const subLayerProps = this.getSubLayerProps({
      id: 'geojson',

      // Proxy most GeoJsonLayer props as-is
      data: this.props.data.features,
      dataTransform: this.dataTransform,
      fp64: this.props.fp64,
      filled: this.props.filled,
      stroked: this.props.stroked,
      lineWidthScale: this.props.lineWidthScale,
      lineWidthMinPixels: this.props.lineWidthMinPixels,
      lineWidthMaxPixels: this.props.lineWidthMaxPixels,
      lineWidthUnits: this.props.lineWidthUnits,
      lineJointRounded: this.props.lineJointRounded,
      lineCapRounded: this.props.lineCapRounded,
      lineMiterLimit: this.props.lineMiterLimit,
      pointRadiusScale: this.props.pointRadiusScale,
      pointRadiusMinPixels: this.props.pointRadiusMinPixels,
      pointRadiusMaxPixels: this.props.pointRadiusMaxPixels,
      pointRadiusUnits: this.props.pointRadiusUnits,
      getLineColor: this.selectionAwareAccessor(this.props.getLineColor),
      getFillColor: this.selectionAwareAccessor(this.props.getFillColor),
      getPointRadius: this.selectionAwareAccessor(this.props.getPointRadius),
      getLineWidth: this.selectionAwareAccessor(this.props.getLineWidth),
      highlightColor: this.modeAwareAccessor(this.props.getHighlightColor),
      pointType: this.props.pointType,
      getIcon: this.props.getIcon,
      getIconSize: this.props.getIconSize,

      _subLayerProps: {
        linestrings: {
          billboard: this.props.billboard,
          updateTriggers: {
            // required to update dashed array attribute
            all: [this.props.selectedFeatureIndexes, this.props.mode],
          },
        },
        'polygons-stroke': {
          billboard: this.props.billboard,
          type: PathLayer,
          updateTriggers: {
            // required to update dashed array attribute
            all: [this.props.selectedFeatureIndexes, this.props.mode],
          },
        },
      },

      updateTriggers: {
        getLineColor: [this.props.selectedFeatureIndexes, this.props.mode],
        getFillColor: [this.props.selectedFeatureIndexes, this.props.mode],
        getPointRadius: [this.props.selectedFeatureIndexes, this.props.mode],
        getLineWidth: [this.props.selectedFeatureIndexes, this.props.mode],
      },
    });

    let layers = [
      new GeoJsonLayer(subLayerProps),
      this.createStaticFeatureLayers(),
      this.createGuidesLayers(),
      this.createTooltipsLayers(),
    ];

    return layers;
  }

  // TODO: is this the best way to properly update state from an outside event handler?
  override shouldUpdateState(opts: any) {
    return super.shouldUpdateState(opts) || opts.changeFlags.stateChanged;
  }

  override updateState({
    props,
    oldProps,
    changeFlags,
    context,
  }: UpdateParameters<this>) {
    super.updateState({ oldProps, props, changeFlags, context });

    if (changeFlags.propsOrDataChanged) {
      const modePropChanged =
        Object.keys(oldProps).length === 0 || props.mode !== oldProps.mode;
      if (modePropChanged) {
        let mode = props.mode;
        if (!mode) {
          mode = new InteractiveMode();
        }
        if (mode !== this.state['mode']) {
          this.setState({ mode });
        }
      }
    }
  }

  getModeProps(
    props: InteractiveGeojsonLayerProps,
  ): ModeProps<SelectAndEditModeConfig> {
    const { viewport } = this.context;
    if (!(viewport instanceof WebMercatorViewport)) {
      // prettier-ignore
      throw new Error(`InteractiveGeoJsonLayer expects WebMercatorViewport, got ${Object.getPrototypeOf(viewport).constructor.name}`)
    }
    return {
      modeConfig: {
        ...props.modeConfig,
        viewport,
        modelMatrix: new Matrix4(this.props.modelMatrix ?? undefined),
      },
      data: props.data as FeatureCollection,
      selectedIndexes: props.selectedFeatureIndexes ?? [],
      lastHoverEvent: this.state['lastHoverEvent'] as any,
      onEdit: (editAction: EditAction) => {
        // Force a re-render
        // This supports double-click where we need to ensure that there's a re-render between the two clicks
        // even though the data wasn't changed, just the internal tentative feature.
        this.setNeedsUpdate();
        props.onEdit?.(editAction);
      },
    };
  }

  selectionAwareAccessor(accessor: any) {
    if (typeof accessor !== 'function') {
      return accessor;
    }
    return (feature: Record<string, any>) =>
      accessor(feature, this.isFeatureSelected(feature), this.props.mode);
  }

  modeAwareAccessor(accessor: any) {
    if (typeof accessor !== 'function') {
      return accessor;
    }
    return (feature: Record<string, any>) => accessor(feature, this.props.mode);
  }

  isFeatureSelected(feature: Record<string, any>) {
    return Boolean(
      this.props.selectedFeatureIndexes?.some(
        (featureIndex) =>
          feature['id'] === this.props.data.features[featureIndex].id,
      ),
    );
  }

  override getPickingInfo({ info, sourceLayer }: Record<string, any>) {
    if (sourceLayer.id.endsWith('guides')) {
      // If user is picking an editing handle, add additional data to the info
      info.isGuide = true;
    } else if (info.object?.id) {
      // Replace the display feature with the actual feature.
      const features = (this.props.data as any).features;
      const feature = features.find((f: any) => f.id === info.object?.id);
      if (feature) {
        info.object = feature;
      }
    }

    return info;
  }

  createStaticFeatureLayers() {
    const mode = this.getActiveMode();
    const staticFeatures = mode.getNonInteractiveFeatures(
      this.getModeProps(this.props),
    ).features;

    if (!staticFeatures?.length) {
      return [];
    }

    const pixelLayer = new GeoJsonLayer(
      this.getSubLayerProps({
        id: `staticPixelFeatures`,
        data: staticFeatures.filter((f) => !f.properties?.meterUnits),
        dataTransform: this.dataTransform,
        fp64: this.props.fp64,
        lineWidthScale: this.props.lineWidthScale,
        lineWidthMinPixels: this.props.lineWidthMinPixels,
        lineWidthMaxPixels: this.props.lineWidthMaxPixels,
        lineWidthUnits: 'pixels',
        pointRadiusUnits: 'pixels',
        lineJointRounded: this.props.lineJointRounded,
        lineCapRounded: this.props.lineCapRounded,
        lineMiterLimit: this.props.lineMiterLimit,
        getLineWidth: (f: NonInteractiveFeature) => f.properties.lineWidth ?? 3,
        getLineColor: (f: NonInteractiveFeature) =>
          f.properties.lineColor ?? [0, 0, 0, 255],
        getFillColor: (f: NonInteractiveFeature) =>
          f.properties.fillColor ?? [0, 0, 0, 255],
        getPointRadius: (f: NonInteractiveFeature) => f.properties.radius ?? 10,
        getIcon: this.props.getIcon,
        pointType: this.props.pointType,
        pickable: false,
        autoHighlight: false,
        opacity: 1,
      }),
    );

    const meterLayer = new GeoJsonLayer(
      this.getSubLayerProps({
        id: `staticMeterFeatures`,
        data: staticFeatures.filter((f) => f.properties?.meterUnits),
        dataTransform: this.dataTransform,
        fp64: this.props.fp64,
        lineWidthMinPixels: this.props.lineWidthMinPixels,
        lineWidthMaxPixels: this.props.lineWidthMaxPixels,
        lineWidthUnits: 'meter',
        pointRadiusUnits: 'meter',
        lineJointRounded: this.props.lineJointRounded,
        lineCapRounded: this.props.lineCapRounded,
        lineMiterLimit: this.props.lineMiterLimit,
        getLineWidth: (f: any) => f.properties?.lineWidth ?? 1,
        getLineColor: (f: any) => f.properties?.lineColor ?? [0, 0, 0, 255],
        getFillColor: (f: any) => f.properties?.fillColor ?? [0, 0, 0, 255],
        getPointRadius: (f: any) => f.properties?.radius ?? 1,
        pickable: false,
        autoHighlight: false,
        opacity: 1,
      }),
    );

    return [pixelLayer, meterLayer];
  }

  createGuidesLayers() {
    const mode = this.getActiveMode();
    const guides = mode.getGuides(this.getModeProps(this.props));

    if (!guides || !guides.features.length) {
      return [];
    }

    const subLayerProps: any = {
      linestrings: {
        billboard: this.props.billboard,
        autoHighlight: false,
        pickable: false,
      },
      'polygons-fill': {
        autoHighlight: false,
        pickable: false,
      },
      'polygons-stroke': {
        billboard: this.props.billboard,
        pickable: false,
      },
    };

    if (this.props.editHandleType === 'icon') {
      subLayerProps['points-icon'] = {
        type: IconLayer,
        iconAtlas: this.props.editHandleIconAtlas,
        iconMapping: this.props.editHandleIconMapping,
        sizeUnits: this.props.editHandleIconSizeUnits,
        sizeScale: this.props.editHandleIconSizeScale,
        getIcon: guideAccessor(this.props.getEditHandleIcon),
        getSize: guideAccessor(this.props.getEditHandleIconSize),
        getColor: guideAccessor(this.props.getEditHandleIconColor),
        getAngle: guideAccessor(this.props.getEditHandleIconAngle),
        billboard: this.props.billboard,
      };
    } else {
      subLayerProps['points-circle'] = {
        type: ScatterplotLayer,
        radiusScale: this.props.editHandlePointRadiusScale,
        stroked: this.props.editHandlePointOutline,
        getLineWidth: this.props.editHandlePointStrokeWidth,
        radiusUnits: this.props.editHandlePointRadiusUnits,
        radiusMinPixels: this.props.editHandlePointRadiusMinPixels,
        radiusMaxPixels: this.props.editHandlePointRadiusMaxPixels,
        getRadius: guideAccessor(this.props.getEditHandlePointRadius),
        getFillColor: guideAccessor(this.props.getEditHandlePointColor),
        getLineColor: guideAccessor(this.props.getEditHandlePointOutlineColor),
        billboard: this.props.billboard,
      };
    }

    const layer = new GeoJsonLayer(
      this.getSubLayerProps({
        id: `guides`,
        data: guides.features,
        dataTransform: this.dataTransform,
        fp64: this.props.fp64,
        _subLayerProps: subLayerProps,
        lineWidthScale: this.props.lineWidthScale,
        lineWidthMinPixels: this.props.lineWidthMinPixels,
        lineWidthMaxPixels: this.props.lineWidthMaxPixels,
        lineWidthUnits: this.props.lineWidthUnits,
        lineJointRounded: this.props.lineJointRounded,
        lineCapRounded: this.props.lineCapRounded,
        lineMiterLimit: this.props.lineMiterLimit,
        getLineColor: guideAccessor(this.props.getTentativeLineColor),
        getLineWidth: guideAccessor(this.props.getTentativeLineWidth),
        getFillColor: guideAccessor(this.props.getTentativeFillColor),
        pointType: this.props.editHandleType === 'icon' ? 'icon' : 'circle',
        iconAtlas: this.props.editHandleIconAtlas,
      }),
    );

    return [layer];
  }

  createTooltipsLayers() {
    const mode = this.getActiveMode();
    const tooltips = mode.getTooltips(this.getModeProps(this.props));

    const layer = new TextLayer({
      getSize: DEFAULT_TOOLTIP_FONT_SIZE,
      ...this.getSubLayerProps({
        id: `tooltips`,
        data: tooltips,
      }),
    });

    return [layer];
  }

  override onLeftClick(event: ClickEvent) {
    this.getActiveMode().onLeftClick(event, this.getModeProps(this.props));
  }

  override onRightClick(event: ClickEvent) {
    this.getActiveMode().onRightClick(event, this.getModeProps(this.props));
  }

  override onLayerKeyUp(event: KeyboardEvent) {
    this.getActiveMode().onKeyUp(event, this.getModeProps(this.props));
  }

  override onStartDragging(event: StartDraggingEvent) {
    this.getActiveMode().onDragStart(event, this.getModeProps(this.props));
  }

  override onDragging(event: DraggingEvent) {
    this.getActiveMode().onDrag(event, this.getModeProps(this.props));
  }

  override onStopDragging(event: StopDraggingEvent) {
    this.getActiveMode().onDragEnd(event, this.getModeProps(this.props));
  }

  override onPointerMove(event: PointerMoveEvent) {
    const pickingInfo = event.picks[0] ?? { picked: false };
    // Slight hack to get the larger picking radius from the InteractiveLayer
    // for highlighting. This currently has one side effect, that sometimes
    // the last highlighted object stays highlighted.
    this.updateAutoHighlight(pickingInfo);
    this.setState({ lastHoverEvent: event });
    this.getActiveMode().onHover(event, this.getModeProps(this.props));
  }

  getActiveMode() {
    return this.state['mode'] as InteractiveMode;
  }

  protected override getMapCoords(screenCoords: [number, number]): GeoPoint {
    return this.props.mapCoordGetter(screenCoords, this.context);
  }
}
