import { Pipe, PipeTransform } from '@angular/core';

type Countable = {
  length: number;
};

@Pipe({
  name: 'length',
  standalone: true,
})
export class LengthPipe implements PipeTransform {
  transform(value: Countable): number {
    return value.length;
  }
}
