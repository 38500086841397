<div class="robot-issues-popover {{ position }}">
  <div class="robot-issues-popover-arrow"></div>

  <div class="robot-issues-popover-box">
    {{ '' // TODO: Get the Organization ID from the Backend }}
    @for (issue of issues; track $index) {
      <a
        href="https://app.clickup.com/t/9004035169/{{
          issue.customId | default: issue.id
        }}"
        target="_blank"
        class="robot-issue {{ extractIssueStatus(issue) }}"
      >
        <span class="robot-issue-status-effect">&#11044;</span>
        <b>[{{ issue.customId | default: issue.id }}]</b>
        {{ formatIssueName(issue.name) }}
      </a>
    }
  </div>
</div>
