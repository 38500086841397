import { Component } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import {
  Operation,
  OperationType,
  OrderOperation,
  RobotQueueEdgeHandover,
} from '../operation';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { sortHandovers } from '../utils';
import { MatToolbar } from '@angular/material/toolbar';
import { HandoverLocationTitleSplitterComponent } from '../handover-location-view/handover-location-title-splitter.component';

import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-handover-location-selection-view',
  templateUrl: './handover-location-selection-view.component.html',
  styleUrls: ['./handover-location-selection-view.component.sass'],
  standalone: true,
  imports: [
    MatToolbar,
    HandoverLocationTitleSplitterComponent,
    MatButton,
    RouterLink,
  ],
})
export class HandoverControlSelectionViewComponent {
  operationDisplayName?: string;
  operationId?: string;

  pickups: RobotQueueEdgeHandover[] = [];

  dropoffs: RobotQueueEdgeHandover[] = [];

  constructor(
    private backendService: BackendService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.refreshOperation(params.get('operation-id')!);
    });
  }

  private refreshOperation(operationId: string) {
    this.operationId = operationId;
    this.backendService.get(`/operations/${operationId}`).subscribe(
      (operation: Operation) => {
        if (operation.operationType !== OperationType.OrderOperation) {
          console.warn(
            `Operation with name '${operationId}' is not an order operation, redirecting.`,
          );
          this.router.navigate(['/operations/']);
          return;
        }
        this.operationDisplayName = operation.displayName ?? operation.id;
        this.pickups = (operation.operationData?.pickups || [])
          .sort(sortHandovers)
          .filter((p) => p.displayName ?? p.name);
        this.dropoffs = (operation.operationData?.dropoffs || [])
          .sort(sortHandovers)
          .filter((p) => p.displayName ?? p.name);
      },
      () => {
        console.warn(
          `Operation with name '${operationId}' could not be retrieved, redirecting.`,
        );
        this.router.navigate(['']);
      },
    );
  }
}
