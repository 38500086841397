import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import {
  exhaustMap,
  map,
  filter,
  shareReplay,
  flatMap,
  switchMapTo,
  take,
  switchMap,
} from 'rxjs/operators';
import { visiblePageTimer } from '../../../utils/page-visibility';
import { RobotDto } from '../../core/robots-service/backend/robot.dto';

import { AuthService } from '../../core/auth.service';
import { BackendService } from '../../core/backend.service';
import { ConfirmationDialog } from '../../core/confirmation-dialog/confirmation-dialog.component';
import { ErrorService } from '../../core/error-system/error.service';
import { Role, User } from '../../core/user';
import { OrderSchedulerMode } from '../operation';
import {
  INITIAL_ORDER_STATUSES,
  FINAL_ORDER_STATUSES,
  Order,
  OrderStatus,
} from '../../core/order/order';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { AsyncPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';

const ROLES_WITH_ACCESS: Set<string> = new Set([
  Role.ADMIN,
  Role.OPERATIONS_MANAGER,
]);

export function hasOrderManagementAccess(user?: User): boolean {
  return !!user?.roles.some((role) => ROLES_WITH_ACCESS.has(role));
}

export interface OrderManagementDialogData {
  order: Order;
}

const ORDER_POLLING_INTERVAL = 1000;

type RobotUIItem = {
  displayName: string;
  id?: string;
};

const bestRobotUIItem: RobotUIItem = {
  displayName: 'Best available',
};

@Component({
  selector: 'app-order-management-dialog',
  templateUrl: './order-management-dialog.component.html',
  styleUrls: ['./order-management-dialog.component.sass'],
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatSlideToggle,
    FormsModule,
    MatButton,
    MatInput,
    AsyncPipe,
  ],
})
export class OrderManagementDialog implements OnInit {
  private receivedOrder$: BehaviorSubject<Order>;

  order$!: Observable<Order>;
  isWaitingForHandover$!: Observable<boolean>;
  isDrivingToHandover$!: Observable<boolean>;
  isFinished$!: Observable<boolean>;
  isNotYetStarted$!: Observable<boolean>;
  robotUIItems$!: Observable<RobotUIItem[]>;

  orderId: string;
  isAsapSchedulerMode = false;
  selectedRobot?: RobotUIItem;
  reason = '';

  constructor(
    public dialogRef: MatDialogRef<OrderManagementDialog, void>,
    private backendService: BackendService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private auth: AuthService,
    private snackBar: MatSnackBar,

    @Inject(MAT_DIALOG_DATA) public data: OrderManagementDialogData,
  ) {
    this.orderId = data.order.id;
    this.receivedOrder$ = new BehaviorSubject(data.order);
  }

  ngOnInit(): void {
    this.auth.user$.subscribe((user) => {
      if (!hasOrderManagementAccess(user)) {
        this.dialogRef.close();
        this.errorService.reportError(
          'Your roles do not have enough permissions to edit an order status',
        );
      }
    });

    const polledOrder$ = visiblePageTimer(0, ORDER_POLLING_INTERVAL).pipe(
      exhaustMap(() => this.backendService.get(`/orders/${this.orderId}`)),
      this.errorService.handleStreamErrors(
        'Attempt to get up to date order status failed',
      ),
    );
    this.order$ = merge(polledOrder$, this.receivedOrder$).pipe(shareReplay(1));

    this.robotUIItems$ = this.order$.pipe(
      flatMap((order) => (order?.operationId ? [order.operationId] : [])),
      exhaustMap((operationId) =>
        this.backendService
          .get<RobotDto[]>(`/robots?assigned_operation_id=${operationId}`)
          .pipe(
            this.errorService.handleStreamErrors(
              'Can not get updated robots status',
            ),
            map((robotList) =>
              robotList.filter((robot) => robot.readyForOrders),
            ),
            map((robotList) => [
              bestRobotUIItem,
              ...robotList.map((robot) => ({
                id: robot.id,
                displayName: `Cart ${robot.serialNumber}`,
              })),
            ]),
          ),
      ),
    );

    this.isWaitingForHandover$ = this.order$.pipe(
      map((order) => order?.status === OrderStatus.WAITING_FOR_HANDOVER),
    );

    this.isDrivingToHandover$ = this.order$.pipe(
      map((order) => order?.status === OrderStatus.DRIVING_TO_HANDOVER),
    );

    this.isFinished$ = this.order$.pipe(
      map((order) => FINAL_ORDER_STATUSES.includes(order?.status)),
    );

    this.isNotYetStarted$ = this.order$.pipe(
      map((order) => INITIAL_ORDER_STATUSES.includes(order?.status)),
    );
  }

  setAssignedRobot(robot: RobotDto) {
    this.selectedRobot = {
      id: robot.id,
      displayName: `Cart ${robot.serialNumber}`,
    };
  }
  isAssignedRobot(robot: RobotDto, selectedRobot?: RobotDto) {
    return robot.id === selectedRobot?.id;
  }

  reschedule() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really reschedule order to "${
            this.selectedRobot?.displayName
          }" ${this.isAsapSchedulerMode ? 'as soon as possible' : ''}?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        exhaustMap(() =>
          this.backendService.post(`/orders/${this.orderId}/reschedule`, {
            schedulerMode: this.isAsapSchedulerMode
              ? OrderSchedulerMode.AS_SOON_AS_POSSIBLE
              : undefined,
            assignedRobotId: this.selectedRobot?.id,
            ignoreConstraints: true,
          }),
        ),
        this.errorService.handleStreamErrors('Order rescheduling failed'),
      )
      .subscribe((order) => {
        this.receivedOrder$.next(order);
        this.selectedRobot = bestRobotUIItem;
      });
  }

  abort() {
    if (this.reason) {
      this.dialog
        .open(ConfirmationDialog, {
          data: {
            message: `Really abort this order?`,
          },
        })
        .afterClosed()
        .pipe(
          filter((isConfirmed: boolean) => isConfirmed),
          exhaustMap(() =>
            this.backendService.post(`/orders/${this.orderId}/abort`, {
              abortReason: 'Other',
              abortDescription: this.reason,
            }),
          ),
          this.errorService.handleStreamErrors('Order aborting error'),
        )
        .subscribe((order) => {
          this.receivedOrder$.next(order);
          this.reason = '';
        });
    } else {
      this.snackBar.open(
        'Please, enter the reason why the order is aborted',
        'close',
      );
    }
  }

  rollbackCurrentState(handoverIndex: number) {
    if (this.reason) {
      this.dialog
        .open(ConfirmationDialog, {
          data: {
            message: `Really rollback order to ${
              handoverIndex === 0 ? 'pickup' : 'dropoff'
            }?`,
          },
        })
        .afterClosed()
        .pipe(
          filter((isConfirmed: boolean) => isConfirmed),
          exhaustMap(() =>
            this.backendService.post(`/orders/${this.orderId}/rollback`, {
              reason: this.reason,
              handoverIndex,
            }),
          ),
          this.errorService.handleStreamErrors('Order rollback failed'),
        )
        .subscribe((order) => {
          this.receivedOrder$.next(order);
          this.reason = '';
        });
    } else {
      this.snackBar.open(
        'Please, enter the reason for order rollback',
        'close',
      );
    }
  }

  arrivedAtHandover() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really set arrived at handover?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        switchMapTo(this.order$),
        take(1),
        filter((order) => !!order.assignedRobotId),
        exhaustMap((order) =>
          this.backendService.post(
            `/robots/${order.assignedRobotId}/arrived-at-stop`,
            {},
          ),
        ),
        this.errorService.handleStreamErrors('Set arrived at handover failed'),
        switchMap(() => this.backendService.get(`/orders/${this.orderId}`)),
      )
      .subscribe((order) => this.receivedOrder$.next(order));
  }

  completeCurrentHandover() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really complete handover?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        exhaustMap(() =>
          this.backendService.post(
            `/orders/${this.orderId}/complete-current-handover`,
            {},
          ),
        ),
        this.errorService.handleStreamErrors(
          'Order handover completion failed',
        ),
      )
      .subscribe((order) => this.receivedOrder$.next(order));
  }

  unassign() {
    this.dialog
      .open(ConfirmationDialog, {
        data: {
          message: `Really unassign robot from order?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        exhaustMap(() =>
          this.backendService.post(
            `/orders/${this.orderId}/unassign-robot`,
            {},
          ),
        ),
        this.errorService.handleStreamErrors('Cannot unassign due to an error'),
      )
      .subscribe((order) => this.receivedOrder$.next(order));
  }
}
