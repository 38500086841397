<div mat-dialog-content class="edit-user-container">
  <h1>{{ data.user.displayName }}</h1>

  <app-attribute-list-input
    [autocompleteAttributes]="allRoles"
    [(selectedAttributes)]="data.user.roles"
    (selectedAttributesChange)="data.userUpdate.roles = data.user.roles"
    [label]="'Roles'"
  />

  <app-attribute-list-input
    [autocompleteAttributes]="allOperationAccessGroups"
    [(selectedAttributes)]="data.user.accessGroups"
    (selectedAttributesChange)="
      data.userUpdate.accessGroups = data.user.accessGroups
    "
    [label]="'Access Groups'"
  />

  @if (!data.user.roles.includes('robot')) {
    <mat-form-field appearance="fill">
      <mat-label>Display Name</mat-label>
      <input
        matInput
        placeholder="Display Name"
        [(ngModel)]="data.user.displayName"
        (change)="data.userUpdate.displayName = data.user.displayName"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Picture Url</mat-label>
      <input
        matInput
        placeholder="Picture Url"
        [(ngModel)]="data.user.pictureUrl"
        (change)="data.userUpdate.pictureUrl = data.user.pictureUrl"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Landing Page</mat-label>
      <input
        matInput
        placeholder="Landing Page"
        [(ngModel)]="data.user.landingPage"
        (change)="data.userUpdate.landingPage = data.user.landingPage"
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Refresh token expires in [min]</mat-label>
      <input
        matInput
        type="number"
        placeholder="Expires in minutes"
        [(ngModel)]="data.user.refreshDurationMinutes"
        (change)="
          data.userUpdate.refreshDurationMinutes =
            data.user.refreshDurationMinutes
        "
      />
    </mat-form-field>
  }

  @if (isAdmin$ | async) {
    <mat-form-field appearance="fill">
      <mat-label>API Key</mat-label>
      <input
        matInput
        placeholder="API Key"
        [(ngModel)]="data.user.apiKey"
        (change)="data.userUpdate.apiKey = data.user.apiKey"
      />
    </mat-form-field>
  }
  <button mat-button (click)="generateKey()">Generate Key</button>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="data.userUpdate">Update</button>
</div>
