@if (displayNewTriggerConfigAvailable) {
  <div style="margin-top: 16px" class="triggerServiceConfigSuccessBox">
    <p style="margin: 16px">A new configuration is available.</p>
    <button
      mat-raised-button
      (click)="onLoadNewTriggerConfig()"
      style="margin: 16px; margin-top: 0"
    >
      Load
    </button>
  </div>
}

@if (displayRobotTriggerConfigMatchesCurrentConfig) {
  <div style="margin-top: 16px" class="triggerServiceConfigSuccessBox">
    <p style="margin: 16px">
      Robot configuration updated to current configuration.
    </p>
  </div>
}

@if (lastRobotTriggerConfigUpdate) {
  <p>
    Received last update at:
    {{ lastRobotTriggerConfigUpdate?.receiveTime | date: 'shortTime' }}
  </p>
}
<trigger-service-config-tree #triggerConfigTree />

@if (changedTriggerConfigComponents.length > 0) {
  <div style="margin-top: 16px" class="triggerServiceConfigAttentionBox">
    <p style="margin-left: 16px; margin-top: 16px">
      The following components will be updated:
    </p>
    <ul>
      @for (component of changedTriggerConfigComponents; track component) {
        <li style="margin-left: 16px">
          {{ component }}
        </li>
      }
    </ul>
  </div>
}
<button
  mat-raised-button
  (click)="onApply()"
  [disabled]="changedTriggerConfigComponents.length == 0"
  style="margin-left: 16px; margin-top: 16px"
>
  Apply
</button>

<button
  mat-raised-button
  (click)="onReset()"
  [disabled]="changedTriggerConfigComponents.length == 0"
  style="margin-left: 16px; margin-top: 16px"
>
  Reset
</button>
