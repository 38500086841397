import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { BackendService } from '../../core/backend.service';
import { firstValueFrom } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export interface CreateRobotData {
  type: 'cart' | 'virtual';
}

export interface CreateRobotPayload {
  password: string;
  serialNumber: number;
  isVirtualRobot?: boolean;
}

@Component({
  selector: 'app-create-robot-dialog',
  templateUrl: './create-robot-dialog.component.html',
  styleUrls: ['./create-robot-dialog.component.sass'],
  standalone: true,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatProgressSpinner,
    AsyncPipe,
  ],
})
export class CreateRobotDialogComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly api = inject(BackendService);
  public readonly data: CreateRobotData = inject(MAT_DIALOG_DATA);

  template$ = this.api.get<CreateRobotPayload>(
    `/robot-templates/${this.data.type}`,
  );

  form = this.formBuilder.group({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    serialNumber: ['', [Validators.required]],
    // eslint-disable-next-line @typescript-eslint/unbound-method
    password: ['', [Validators.required]],
  });

  constructor(
    private readonly dialogRef: MatDialogRef<CreateRobotDialogComponent>,
  ) {
    this.template$.subscribe((template) => {
      this.form.patchValue(template as unknown as Record<string, unknown>);
    });
  }

  async onSubmit() {
    const data = this.form.value as unknown as CreateRobotPayload;
    data.isVirtualRobot = this.data.type === 'virtual';
    console.debug('Creating robot with data:', data);
    const result = await firstValueFrom(this.api.post(`/robots`, data));
    this.dialogRef.close(result);
  }
}
