import { Pipe, PipeTransform } from '@angular/core';

export function hoursSince(value?: Date | string | number): string {
  if (value === undefined) {
    return 'UNKNOWN';
  }
  const date = new Date(value);
  const now = new Date();
  const totalMinutesAgo = Math.round(
    (now.getTime() - date.getTime()) / 1000 / 60,
  );
  const hoursAgo = Math.floor(totalMinutesAgo / 60);
  const minutesAgo = totalMinutesAgo - 60 * hoursAgo;
  return `${hoursAgo}:${String(minutesAgo).padStart(2, '0')}`;
}

@Pipe({
  name: 'hoursSince',
  standalone: true,
})
export class HoursSincePipe implements PipeTransform {
  transform(...args: any[]): string {
    return hoursSince(...args);
  }
}
