import { Component } from '@angular/core';
import { CollectionDto } from './ml-data-types';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';

import { DataViewerComponent } from './data-viewer/data-viewer.component';
import { CollectionComponent } from './collection-viewer/collection.component';

@Component({
  selector: 'app-ml-platform',
  templateUrl: './ml-platform.component.html',
  styleUrls: ['./ml-platform.component.sass'],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    LeftSidebarComponent,
    DataViewerComponent,
    CollectionComponent,
  ],
})
export class MlPlatformComponent {
  selectedCollection: CollectionDto | null = null;

  setCollection(collection: CollectionDto | null) {
    this.selectedCollection = collection;
  }
}
