import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../core/backend.service';
import { AuthService } from '../../core/auth.service';
import {
  Operation,
  OperationType,
  OrderOperation,
  PinCodeStrategy,
} from '../operation';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateOperationDialogComponent,
  CreateOperationDialogInput,
  CreateOperationData,
} from '../create-operation-dialog/create-operation-dialog.component';

import { Role } from '../../core/user';
import { UpdateOperation } from '../update-operation';
import { EditOperationDialogComponent } from '../edit-operation-dialog/edit-operation-dialog.component';
import { ToolbarComponent } from '../../core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';

import { MatChip } from '@angular/material/chips';
import { MatMiniFabButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-operations-view',
  templateUrl: './operations-view.component.html',
  styleUrls: ['./operations-view.component.sass'],
  animations: [],
  standalone: true,
  imports: [
    ToolbarComponent,
    MatMenuItem,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatChip,
    MatMiniFabButton,
    RouterLink,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
  ],
})
export class OperationsViewComponent implements OnInit {
  isAdmin = false;
  operations: Operation[] = [];

  availablePinCodeStrategies = Object.values(PinCodeStrategy);

  readonly columnsToDisplay = ['id', 'accessGroups', 'action-buttons'];

  constructor(
    private backendService: BackendService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.isAdmin = !!user?.roles?.includes(Role.ADMIN);
      if (user) {
        this.pullData();
      }
    });
  }

  pullData() {
    this.backendService
      .get(`/operations/`)
      .subscribe((operations: Operation[]) => {
        for (const operation of operations) {
          if (
            operation.operationType === OperationType.OrderOperation &&
            operation.operationData
          ) {
            this.populateUndefinedOrderOperationFields(operation.operationData);
          }
        }
        operations.sort(({ id: id1 }, { id: id2 }) => id1.localeCompare(id2));
        this.operations = operations;
      });
  }

  private populateUndefinedOrderOperationFields(operationData: OrderOperation) {
    operationData.waitingQueues = operationData.waitingQueues || [];
    operationData.pickups = operationData.pickups || [];
    operationData.dropoffs = operationData.dropoffs || [];
    operationData.defaultPickupWaitingDurations =
      operationData.defaultPickupWaitingDurations || {
        estimatedWaitingDuration: undefined,
        maxWaitingDuration: undefined,
        reminderDuration: undefined,
        autoSuccessDuration: undefined,
      };
    operationData.defaultDropoffWaitingDurations =
      operationData.defaultDropoffWaitingDurations || {
        estimatedWaitingDuration: undefined,
        maxWaitingDuration: undefined,
        reminderDuration: undefined,
        autoSuccessDuration: undefined,
      };
    operationData.textMessageConfiguration =
      operationData.textMessageConfiguration || {};
    operationData.textMessageConfiguration =
      operationData.textMessageConfiguration || {};
    operationData.pickupCompartmentStrategy =
      operationData.pickupCompartmentStrategy || {
        pinCodeStrategy: PinCodeStrategy.NONE,
      };
    operationData.dropoffCompartmentStrategy =
      operationData.dropoffCompartmentStrategy || {
        pinCodeStrategy: PinCodeStrategy.NONE,
      };
  }

  private updateOperation(updatedOperation: Operation) {
    let operation = this.operations.find((op) => op.id === updatedOperation.id);
    if (!operation) {
      this.operations.push(updatedOperation);
    }
    operation = updatedOperation;
  }

  pushOperationChanges(id: string, updateOperation: UpdateOperation) {
    this.backendService
      .patch(`/operations/${id}`, updateOperation)
      .subscribe((operation: Operation) => this.updateOperation(operation));
  }

  createOperation() {
    const createOperationDialogRef = this.dialog.open(
      CreateOperationDialogComponent,
      {
        width: '230px',
        data: {} as CreateOperationDialogInput,
      },
    );
    createOperationDialogRef
      .afterClosed()
      .subscribe((operationData?: CreateOperationData) => {
        if (!operationData) {
          return;
        }

        const operationDataDetails = {};
        this.backendService
          .put(`/operations/${operationData.id}`, {
            operationType: operationData.operationType,
            accessGroups: [],
            operationData: operationDataDetails,
          })
          .subscribe((operation: Operation) => this.updateOperation(operation));
      });
  }

  editOperation(operation: Operation) {
    const editRobotDialogRef = this.dialog.open(EditOperationDialogComponent, {
      width: 'auto',
      data: { operation },
      minWidth: '80vh',
      autoFocus: false,
      maxHeight: '90vh',
    });
    editRobotDialogRef
      .afterClosed()
      .subscribe((updateOperation?: UpdateOperation) => {
        if (!updateOperation) {
          this.pullData();
          return;
        }
        this.pushOperationChanges(operation.id, updateOperation);
      });
  }
}
