import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { BackendService } from '../backend.service';
import { ErrorService } from '../error-system/error.service';
import { MetricsService } from '../metrics/metrics.service';
import { RobotLastStateService } from './robot-last-state.service';
import { RobotCommunication } from './robot-communication';
import { SignalingService } from './webrtc/signaling.service';
import { UserSessionService } from '../user-session/user-session.service';
import { UserSessionEventTrackingService } from '../user-session/user-session-event-tracking.service';

const ROBOT_CHECK_IN_INTERVAL = 1000 * 30;

@Injectable()
export class RobotsService implements OnDestroy {
  private robots = new Map<string, RobotCommunication>();
  private _destroy = new Subject();

  constructor(
    private readonly signalingService: SignalingService,
    private readonly backendService: BackendService,
    private readonly errorService: ErrorService,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly metricsService: MetricsService,
    private readonly robotLastStateService: RobotLastStateService,
    private readonly userSessionService: UserSessionService,
    private readonly userSessionEventTrackingService: UserSessionEventTrackingService,
  ) {
    timer(0, ROBOT_CHECK_IN_INTERVAL)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.logRobots();
      });
  }

  async ngOnDestroy(): Promise<void> {
    this._destroy.next(undefined);
    await this.finalizeRobots();
  }

  async getRobotCommunications(
    robotIds: [string, ...string[]],
  ): Promise<[RobotCommunication, ...RobotCommunication[]]> {
    return robotIds.map((robotId) => {
      const cachedRobot = this.robots.get(robotId);
      if (cachedRobot && !cachedRobot.isFinalized()) {
        return cachedRobot;
      } else {
        const newRobot = new RobotCommunication(
          robotId,
          this.signalingService,
          this.userSessionEventTrackingService,
          this.backendService,
          this.errorService,
          this.snackBar,
          this.authService,
          this.userSessionService,
        );
        this.robotLastStateService.addNewRobot(newRobot);
        this.trackRobotTraveledDistance(newRobot);
        this.robots.set(robotId, newRobot);
        newRobot.finalized$.subscribe(() => {
          this.robots.delete(robotId);
          this.metricsService.deleteTravelDistanceDelta(robotId);
        });
        this.logRobots();
        return newRobot;
      }
    }) as [RobotCommunication, ...RobotCommunication[]];
  }

  private logRobots() {
    console.debug('Robot Connections', this.robots.keys());
  }

  async finalizeRobots(...keepRobotCommunications: RobotCommunication[]) {
    const robotCommunications = this.robots.values();
    const keepRobotIds = keepRobotCommunications.map(
      (robotCommunication) => robotCommunication.robotId,
    );

    const finalizedRobotIds = [];
    for (const robotCommunication of robotCommunications) {
      if (!keepRobotIds.includes(robotCommunication.robotId)) {
        await robotCommunication.finalize();
        finalizedRobotIds.push(robotCommunication.robotId);
      }
    }

    return finalizedRobotIds;
  }

  private trackRobotTraveledDistance(robotCommunication: RobotCommunication) {
    combineLatest([
      robotCommunication.isInControl$,
      robotCommunication.robotState$,
    ])
      .pipe(takeUntil(this._destroy), takeUntil(robotCommunication.finalized$))
      .subscribe(([isInControl, robotState]) => {
        const robotTraveledDistance = robotState.absoluteTraveledDistance;

        if (isInControl !== true) {
          this.metricsService.deleteTravelDistanceDelta(robotState.id);
        } else if (robotTraveledDistance !== undefined) {
          this.metricsService.putTraveledDistance(
            robotState.id,
            robotTraveledDistance,
          );
        }
      });

    combineLatest([
      robotCommunication.isInControl$,
      robotCommunication.latency$,
    ])
      .pipe(takeUntil(this._destroy), takeUntil(robotCommunication.finalized$))
      .subscribe(([isInControl, latency]) => {
        if (isInControl === true) {
          this.metricsService.putControllingLatency(latency);
        }
      });
  }
}
