import { Component, Input, OnChanges } from '@angular/core';
import { RobotIssue, RobotIssueSeverity } from '../robot-issue.types';

import { MatIcon } from '@angular/material/icon';
import { DefaultPipe } from '../../pipes/default.pipe';

@Component({
  selector: 'app-robot-issues-widget',
  styleUrls: ['./robot-issues-widget.component.sass'],
  templateUrl: './robot-issues-widget.component.html',
  standalone: true,
  imports: [MatIcon, DefaultPipe],
})
export class RobotIssuesWidgetComponent implements OnChanges {
  @Input() issues!: RobotIssue[];

  ngOnChanges(): void {
    // Filter to only display BREAKING issues
    this.issues = this.issues.filter(
      (issue) => issue.severity === RobotIssueSeverity.BREAKING,
    );
  }
}
